import React, { useState, useEffect } from 'react';
import Model from '../../Components/Model/HomeModel';
import { languageChange } from '../../helper/TranslateMethod.Helper';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Popover,
  PopoverTrigger,
  Button,
  useDisclosure,
  Image
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  HamburgerIcon,
  CloseIcon,
  // ChevronDownIcon,
  // ChevronRightIcon,
} from '@chakra-ui/icons';

import AYM from '../../assets/images/images/AYM.png';
import { isMobile } from 'react-device-detect';
let token = localStorage.getItem('token') 
const parentTranslationPath = 'Headers';
const translationPath = '';

export const Header = ({
  HomeRef,
  WhatIsAYMBotRef,
  WhyAYMBotRef,
  GetStartedRef,
  PricingRef,
  AYMBotClientsRef,
  FAQRef,
  // DownloadReportRef,
  WhowItWorkRef,
  ContactUsRef,
  AffiliateRef,
  executeScroll,
  executeMobScroll,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [navColor, setNavbarColor] = useState(false);

  let lang = JSON.parse(localStorage.getItem('localization'));
  const handleClose = () => {
    onClose();
  };
  const changeColor = () => {
    if (window.scrollY >= window.innerHeight) {
      setNavbarColor(true);
    } else {
      setNavbarColor(false);
    }
  };

  useEffect(() => {
    changeColor();
    window.addEventListener('scroll', changeColor);
  });

  return (
    <div className="header">
      <Box>
        <Flex
          bgGradient={
            navColor
              ? 'linear(to-r, #192238, #000000)'
              : 
              'linear(to-r, #4FD1C500, #4FD1C500)'
          }
          // color={'white'}
          // minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          // borderBottom={1}
          borderStyle={'none'}
          borderColor={'none'}
          align={'center'}
        >
          <Box
            flex={{ base: 1, md: 'auto' }}
            ml={{ base: -2 }}
            display={{ base: 'flex', xl: 'none' }}
          >
            <IconButton
              _focus={{
                boxShadow: 'none',
              }}
              _active={{
                bg: '#151C2F',
                transform: 'scale(0.98)',
              }}
              onClick={isOpen ? onClose : onOpen}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
          </Box>
         

         
         
          <Box flex={{ base: 1 }} w="150px">
          {/* (isMobile?:) */}
          {isMobile? 
          ''
          :
          <Box
              className="logo-header"
              w={{ base: 'auto', ms: '150px' }}
              display="flex"
              justify={{ base: 'start', md: 'start' }}
            >
              <Image
              objectFit="contain"
              backgroundSize="contain"
              h="10"
              w={'10'}
              // m="auto"
              // mr={'5px'}
              // ml={'5px'}
              // mt={'12px'}

              src={AYM}
              alt={'AYM'}
            />
              <Button
                _focus={{
                  boxShadow: 'none',
                }}
                _active={{
                  bg: '#151C2F',
                  transform: 'scale(0.98)',
                }}
                position="static"
                whiteSpace="normal"
                _hover="none"
                fontSize="15px"
                variant="link"
                color="white"
                size="3xl"
                w="80px"
                mx="2"
                onClick={() => executeScroll(HomeRef)}
                textAlign={({
                  base: 'start',
                  sm: 'right',
                  md: 'right',
                  lg: 'right',
                  xl: 'center',
                })}
                fontFamily={'heading'}
              >
                {t(`${translationPath}AYMBot®`)}
              </Button>
            </Box> 
          }
           
        
          </Box>

          <Box
            display={{ base: 'none', md: 'none', lg: 'none', xl: 'flex' }}
           
          >
            <DesktopNav
            token={token}
              Language={lang}
              languageChange={languageChange}
              HomeRef={HomeRef}
              WhowItWorkRef={WhowItWorkRef}
              WhatIsAYMBotRef={WhatIsAYMBotRef}
              WhyAYMBotRef={WhyAYMBotRef}
              GetStartedRef={GetStartedRef}
              PricingRef={PricingRef}
              AYMBotClientsRef={AYMBotClientsRef}
              FAQRef={FAQRef}
              // DownloadReportRef={DownloadReportRef}
              ContactUsRef={ContactUsRef}
              AffiliateRef={AffiliateRef}
              executeScroll={executeScroll}
            />
          </Box>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav
          token={token}
            handleClose={handleClose}
            Language={lang}
            languageChange={languageChange}
            HomeRef={HomeRef}
            WhowItWorkRef={WhowItWorkRef}
            WhatIsAYMBotRef={WhatIsAYMBotRef}
            WhyAYMBotRef={WhyAYMBotRef}
            GetStartedRef={GetStartedRef}
            PricingRef={PricingRef}
            AYMBotClientsRef={AYMBotClientsRef}
            FAQRef={FAQRef}
            // DownloadReportRef={DownloadReportRef}
            ContactUsRef={ContactUsRef}
            AffiliateRef={AffiliateRef}
            executeScroll={executeMobScroll}
          />
        </Collapse>
      </Box>
    </div>
  );
};

const DesktopNav = ({
  token,
  Language,
  languageChange,
  WhowItWorkRef,
  WhatIsAYMBotRef,
  WhyAYMBotRef,
  GetStartedRef,
  PricingRef,
  ContactUsRef,
  FAQRef,
  // DownloadReportRef,
  AffiliateRef,
  executeScroll,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleClick = label => {
    if (label === 'ln') {
      if (Language.currentLanguage === 'ar') {
        languageChange('en');
      window.location.reload(false);

      } else {
        languageChange('ar');
      window.location.reload(false);

      }
    } else if (label === 'Blogs') {
      window.open('http://blog.aymbot.com/', '_blank');
    } else {
      if ( !token || token ==='null') {
      
        onOpen();
      } else {
        window.location.href = '/admin/profile';
      }
    }
  };

  const NAV_ITEMS = [
    {
      label: 'What-AYMBot',
      href: 'What is AYMBot',
      toRef: WhatIsAYMBotRef,
    },
    {
      label: 'How-works',
      href: 'How it works',
      toRef: WhowItWorkRef,
    },
    {
      label: 'Why-AYMBot',
      href: 'Why AYMBot',
      toRef: WhyAYMBotRef,
    },

    {
      label: 'Get-started',
      href: 'Get started',
      toRef: GetStartedRef,
    },
    {
      label: 'Pricing-Results',
      href: 'Pricing',
      toRef: PricingRef,
    },
 
    {
      label: 'FAQs',
      href: 'FAQs',
      toRef: FAQRef,
    },
    // {
    //   label: 'DownloadReport',
    //   href: 'DownloadReport',
    //   toRef: DownloadReportRef,
    // },
    {
      label: 'Contact-Us',
      href: 'Contact Us',
      toRef: ContactUsRef,
    },
    {
      label: 'Referrals',
      href: 'Referrals',
      toRef: AffiliateRef,
    },
    {
      label: 'Blogs',
      href: 'Blogs',
      onClick: AffiliateRef,
    },
    {
      label:!token || token ==='null'? 'Log-in' : 'Dashboard',
      href: 'Log in',
      onClick: ` ${'test'}`,
    },
    {
      label: 'ln',
      href: '#',
      onClick: 'none',
    },
  ];
  return (
    <Stack direction={'row'} spacing={1}>
      <Model isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {NAV_ITEMS.map(navItem => (
        <Box key={navItem.label}>
          <Popover placement={'bottom-start'}>
            <PopoverTrigger>
              <Button
                variant="ghost"
                p={2}
                _focus={{
                  boxShadow: 'none',
                }}
                _hover={{ fontWeight: 'semibold' }}
                _active={{
                  bg: '#E5E5E5',
                  transform: 'scale(0.98)',
                  borderRadius: '3px',
                }}
                onClick={
                  navItem.onClick
                    ? () => handleClick(navItem.label)
                    : () => executeScroll(navItem.toRef)
                }
                whiteSpace={'nowrap'}
                fontSize={'12px'}
                fontWeight={500}
                color={'white'}
              >
               <Text>{t(`${translationPath}${navItem.label}`)}</Text> 
              </Button>
            </PopoverTrigger>
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = ({
  token,
  handleClose,
  WhatIsAYMBotRef,
  WhyAYMBotRef,
  GetStartedRef,
  PricingRef,
  WhowItWorkRef,
  Language,
  languageChange,
  ContactUsRef,
  FAQRef,
  // DownloadReportRef,
  AffiliateRef,
  executeScroll,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(parentTranslationPath);
  const handleClick = label => {
    if (label === 'ln') {
      if (Language.currentLanguage === 'ar') {
        
        languageChange('en');
      window.location.reload(false);

      } else {
        languageChange('ar');
      window.location.reload(false);

      }
    } else if (label === 'Blogs') {
      window.open('http://blog.aymbot.com/', '_blank');
    } else {
      if (!token || token ==='null' ) {
     
        onOpen();
      } else {
        window.location.href = '/admin/profile';
      }
    }
  };
  const NAV_ITEMS = [
    {
      label: 'What-AYMBot',
      href: 'What is AYMBot',
      toRef: WhatIsAYMBotRef,
    },
    {
      label: 'How-works',
      href: 'How it works',
      toRef: WhowItWorkRef,
    },
    {
      label: 'Why-AYMBot',
      href: 'Why AYMBot',
      toRef: WhyAYMBotRef,
    },

    {
      label: 'Get-started',
      href: 'Get started',
      toRef: GetStartedRef,
    },
    {
      label: 'Pricing-Results',
      href: 'Pricing',
      toRef: PricingRef,
    },


    {
      label: 'FAQs',
      href: 'FAQs',
      toRef: FAQRef,
    },
    // {
    //   label: 'FAQs',
    //   href: 'FAQs',
    //   toRef: DownloadReportRef,
    // },
    
    {
      label: 'Contact-Us',
      href: 'Contact Us',
      toRef: ContactUsRef,
    },

    {
      label: 'Referrals',
      href: 'Referrals',
      toRef: AffiliateRef,
    },
    {
      label: 'Blogs',
      href: 'Blogs',
      onClick: AffiliateRef,
    },
    {
      label:  !token || token ==='null'? 'Log-in' : 'Dashboard',
      href: 'Log in',
      onClick: ` ${'test'}`,
    },
    {
      label: 'ln',
      href: '#',
      onClick: 'none',
    },
  ];
  return (
    <Stack
      bgGradient={'linear(to-r, #192238, #000000)'}
      p={4}
      display={{ xl: 'none' }}
    >
     <Model isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {NAV_ITEMS.map((navItem,i) => (
        <Stack spacing={4} key={i}>
         
          <Flex
            py={2}
            _focus={{
              boxShadow: 'none',
            }}
            _active={{
              bg: '#E5E5E5',
              transform: 'scale(0.98)',
              borderRadius: '3px',
            }}
            onClick={
              navItem.onClick
                ? () => {
                    handleClick(navItem.label);
                  }
                : () => {
                    executeScroll(navItem.toRef);
                    handleClose();
                  }
            }
            justify={'space-between'}
            align={'center'}
            _hover={{
              textDecoration: 'none',
              boxShadow: 'none',
              color: '#4FD1C5',
            }}
          >
            <Text fontWeight={600} color="white">
              {t(`${translationPath}${navItem.label}`)}
            </Text>
          </Flex>
        </Stack>
      ))}
    </Stack>
  );
};
