import { HttpServices } from '../../helper/HttpMethod.Helper';
import  config  from '../../assets/Configration/Configration.json'
const token = localStorage.getItem('token');

export const getMonthlyReport = async()=> {
    const result =await HttpServices.get(`${config.serverAddress}/core/aymtrack/rest/getMonthlyReport `,
    {headers:{
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        token:token,
      }} )
    return result;
};