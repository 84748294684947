import React from 'react';
import { Box, Heading, Text, Button, useDisclosure } from '@chakra-ui/react';
import ContactUsForm from '../../ReusableComponent/ContactUsForm/ContactUsForm';
import { Footer } from '../../Layout/Footer/Footer';
import { useTranslation } from 'react-i18next';
import { FaTelegramPlane, FaPhoneAlt } from 'react-icons/fa';



const parentTranslationPath = 'Contact';
const translationPath = '';

export const ContactUs = () => {
  const { t } = useTranslation(parentTranslationPath);
  let lang = JSON.parse(localStorage.getItem('localization'));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleFAQ = () => {

    if (lang.currentLanguage === 'en') {
      window.open('https://blog.aymbot.com/en/category/faqs/', '_blank');
    } else {
      window.open(
        'https://blog.aymbot.com/ar/category/%d8%a3%d8%b3%d8%a6%d9%84%d8%a9-%d8%b4%d8%a7%d8%a6%d8%b9%d8%a9/',
        '_blank'
      );
    }
  };
  const handleOpen = () => {
    onOpen();
  };
  return (
    <div>
      <ContactUsForm
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        sixe="xl"
      />
      <Box h="auto" bgColor="#E5E5E5">
        <Box
          width="80%"
          m="auto"
          height="100%"
          display="flex"
          alignItems="center"
        >
          <Box display="block">
            <Box
              textAlign={lang.currentLanguage === 'en' ? 'left' : 'right'}
              my="10"
            >
              <Heading
                as="h1"
                lineHeight="2"
                size="2xl"
                color="#000000"
                isTruncated
              >
                {t(`${translationPath}Contact-us`)}
              </Heading>
            </Box>
            <Box textAlign={lang.currentLanguage === 'en' ? 'left' : 'right'}>
              <Text
                textAlign={lang.currentLanguage === 'en' ? 'left' : 'right'}
                color="#757575"
              >
                {t(`${translationPath}IMMEDIATE-ANSWERS`)}
              </Text>
            </Box>
            <Box textAlign={lang.currentLanguage === 'en' ? 'left' : 'right'}>
              <Text
                textAlign={lang.currentLanguage === 'en' ? 'left' : 'right'}
                color="#757575"
              >
                {t(`${translationPath}Get-quick`)}
              </Text>
            </Box>
            <Box my="10">
              <Button
                _focus={{
                  boxShadow: 'none',
                }}
                _active={{
                  bg: '#151C2F',
                  transform: 'scale(0.98)',
                }}
                position="static"
                _hover="none"
                bg="#4FD1C5"
                color="white"
                size="lg"
                w="170px"
                onClick={handleFAQ}
              >
                {t(`${translationPath}FAQs`)}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          width="80%"
          m="auto"
          height="100%"
          display="flex"
          alignItems="center"
        >
          <Box display="block">
            <Box
              textAlign={lang.currentLanguage === 'en' ? 'left' : 'right'}
              my="4"
              width="80%"
            >
              <Text
                textAlign={lang.currentLanguage === 'en' ? 'left' : 'right'}
                color="#757575"
              >
                {t(`${translationPath}Drop-us`)}
              </Text>
            </Box>
            <Box
              my="4"
              textAlign={lang.currentLanguage === 'en' ? 'left' : 'right'}
              width="80%"
            >
              <Text
                textAlign={lang.currentLanguage === 'en' ? 'left' : 'right'}
                color="#757575"
              >
                {t(`${translationPath}Feel-free`)}
              </Text>
            </Box>
            <Box my="10">
              <Button
                _focus={{
                  boxShadow: 'none',
                }}
                _active={{
                  bg: '#151C2F',
                  transform: 'scale(0.98)',
                }}
                position="static"
                _hover="none"
                bg="#4FD1C5"
                color="white"
                size="lg"
                w="170px"
                onClick={handleOpen}
              >
                {t(`${translationPath}Submit`)}
              </Button>
            </Box>
            <Box width="100%">
              <Box display="flex" my="5">
                <span className="icon">
                  <FaPhoneAlt color="black" marginTop="5px" />
                </span>
                <Text direction={lang.currentLanguage==='en'?'ltr':'rtl'} color="#757575">
                  {t(
                    `${translationPath}phone`
                  )}
                </Text>
              </Box>
              <Box display="flex" my="5">
                <span className="icon">
                  <FaTelegramPlane color="black" marginTop="5px" />
                </span>
                <Text color="#757575">
                  {' '}
                  <a href={"https://t.me/AYMSupport/"} target="_blank" rel="noreferrer"  >
                    {t(
                      `${translationPath}telegram`
                    )}
                  </a>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};
