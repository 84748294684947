import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Text,
  SimpleGrid,
  GridItem,
  Grid,
  Flex,
  Link,
} from '@chakra-ui/react';
import { HttpServices } from '../../helper/HttpMethod.Helper';
import config from '../../assets/Configration/Configration.json';
// import {GetAYMBotResult} from '../AYMBotResults'
const parentTranslationPath = 'Result';

const translationPath = '';
export const AYMBotResults = ({ AYMBotResultsRef }) => {
  let lang = JSON.parse(localStorage.getItem('localization'));
  const { t } = useTranslation(parentTranslationPath);

  const [Aymresult, setAYMbotResult] = useState(0);

  const handleOpen = () => {
    if (lang.currentLanguage === 'en') {
      window.open(
        'https://blog.aymbot.com/en/category/aymbot-results/',
        '_blank'
      );
    } else {
      window.open(
        'https://blog.aymbot.com/ar/category/%d9%86%d8%aa%d8%a7%d8%a6%d8%ac-aymbot/',
        '_blank'
      );
    }
  };
  const handleOpenRelease = () => {
    if (lang.currentLanguage === 'en') {
      window.open(
        'https://blog.aymbot.com/en/category/release-notes/',
        '_blank'
      );
    } else {
      window.open(
        'https://blog.aymbot.com/ar/category/%d9%85%d9%84%d8%a7%d8%ad%d8%b8%d8%a7%d8%aa-%d8%a7%d9%84%d8%a5%d8%b5%d8%af%d8%a7%d8%b1%d8%a7%d8%aa/',
        '_blank'
      );
    }
  };

  useEffect(() => {
    getAymbotRR();
  }, []);

  const getAymbotRR = async () => {
    const result = await HttpServices.get(
      `${config.serverAddress}/core/user/rest/AYMBot/aymbotResults`,
      {
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );

    setAYMbotResult(result.data.result);

    // var msg = JSON.parse(JSON.stringify(result.data.result));
  };

  const num = [
    { percentage: Aymresult?.countOfUsers, title: 'Happy-Users' },
    { percentage: '52%', title: 'Last-results' },
    { percentage: '20M+', title: 'under-management' },
    { percentage: Aymresult?.totalTrades, title: 'Fill-accuracy' },
  ];

  return (
    <div ref={AYMBotResultsRef} id="AYMBotresults">
      <Grid
        bgGradient={'linear(to-r, #192238, #000000)'}
        h={{ base: 'auto' }}
        templateColumns={{ xl: 'repeat(2, 2fr)', base: 'repeat(1, 2fr)' }}
      >
        <GridItem columns={3}>
          <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }}>
            <Flex alignItems="center" mt={10}>
              <Box w="100%" textAlign="-webkit-center" my="10">
                <Box
                  w={{ base: '100%', lg: '60%' }}
                  textAlign={{
                    base: 'center',
                    lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                  }}
                >
                  <Text
                    color="white"
                    fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                    fontWeight="bold"
                    my="2"
                  >
                    {t(`${translationPath}aym-result`)}
                  </Text>
                </Box>
                <Box
                  w={{ base: '75%', lg: '60%' }}
                  textAlign={{
                    base: 'center',
                    lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                  }}
                >
                  <Text
                    py="5"
                    textAlign={{ base: 'center', lg: 'justify' }}
                    fontSize="md"
                    color="#757575"
                    w="100%"
                    m="auto"
                  >
                    {t(`${translationPath}pragh-1`)}

                    <Text display="initial">
                      <Link
                        mx="1"
                        href={
                          lang.currentLanguage === 'en'
                            ? 'https://blog.aymbot.com/en/category/aymbot-results/'
                            : 'https://blog.aymbot.com/ar/category/%d9%86%d8%aa%d8%a7%d8%a6%d8%ac-aymbot/'
                        }
                        target="_blank"
                        color="teal.500"
                      >
                        {t(`${translationPath}results-section`)}
                      </Link>
                      {t(`${translationPath}pragh-1-1`)}
                    </Text>
                  </Text>
                  
                  <Text
                    textAlign={{ base: 'center', lg: 'justify' }}
                    fontSize="md"
                    color="#757575"
                    w="100%"
                    m="auto"
                  >
                    {t(`${translationPath}pragh-2`)}
                  </Text>
                </Box>
                <Box
                  w={{ base: '75%', lg: '60%' }}
                  textAlign={{
                    base: 'center',
                    lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                  }}
                >
                  <Text
                    py="5"
                    textAlign={{ base: 'center', lg: 'justify' }}
                    fontSize="md"
                    color="#757575"
                    w="100%"
                    m="auto"
                  >
                    {t(`${translationPath}pragh-3`)}

                  
                  </Text>
                  
                </Box>
                <Box
                  w={{ base: '100%', lg: '60%' }}
                  textAlign={{
                    base: 'center',
                    lg: lang.currentLanguage === 'en' ? 'left' : 'right',
                  }}
                >
                  <Button
                    _focus={{
                      boxShadow: 'none',
                    }}
                    _active={{
                      bg: '#151C2F',
                      transform: 'scale(0.98)',
                    }}
                    position="static"
                    whiteSpace="normal"
                    _hover="none"
                    my="10"
                    fontSize={{ base: '12px', lg: '15px' }}
                    bg="#4FD1C5"
                    color="white"
                    size="md"
                    w="140px"
                    minH="40px"
                    h={{ lg: 'auto' }}
                    onClick={handleOpen}
                  >
                    {t(`${translationPath}tell-me`)}
                  </Button>
                </Box>
              </Box>
            </Flex>
          </SimpleGrid>
        </GridItem>
        <GridItem display="flex" alignItems="center" m="auto">
          <SimpleGrid>
            <Box
              w="100%"
              textAlign="center"
              display="flex"
              justifyContent="center"
            >
              <Grid
                templateColumns={{
                  base: '1fr',
                  lg: '1fr 1fr',
                  xl: '0.8fr  1fr',
                }}
                templateRows={{ md: '1fr auto', lg: '1fr' }}
                textAlign="center"
                mb={{ base: '20', xl: '0' }}
              >
                {Aymresult.lastMonthResult === undefined
                  ? num.map((item, i) => (
                      <GridItem columns={1} key={i}>
                        <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }}>
                          <Box
                            w={{ base: '160px', sm: '250px' }}
                            bgColor="#27314C"
                            borderRadius="15px"
                            margin="20px"
                          >
                            <Text
                              color="white"
                              fontSize={{ base: '4xl', sm: '6xl' }}
                              fontWeight="bold"
                            >
                              {0}
                            </Text>
                            <Text color="white" fontSize="lg" m="4px">
                              {t(`${translationPath}${item.title}`)}
                            </Text>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    ))
                  : num.map((item, i) => (
                      <GridItem columns={1} key={i}>
                        <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }}>
                          <Box
                            w={{ base: '160px', sm: '250px' }}
                            bgColor="#27314C"
                            borderRadius="15px"
                            margin="20px"
                          >
                            <Text
                              color="white"
                              fontSize={{ base: '4xl', sm: '6xl' }}
                              fontWeight="bold"
                            >
                              {item.percentage === undefined
                                ? 0
                                : item.percentage}
                            </Text>
                            <Text color="white" fontSize="lg" m="4px">
                              {t(`${translationPath}${item.title}`)}
                            </Text>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    ))}
              </Grid>
            </Box>
          </SimpleGrid>
        </GridItem>
      </Grid>
      <Grid
        h={{ base: 'auto' }}
        bgColor="#E5E5E5"
        templateColumns={{ base: 'repeat(1, 2fr)' }}
      >
        <GridItem columns={3}>
          <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }}>
            <Box w="100%" textAlign="center" my="0">
              <Text
                color="#4FD1C5"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                fontWeight="bold"
                mt="6"
              >
                {t(`${translationPath}history`)}
              </Text>

              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                color="#757575"
                py="4"
                w={{ base: '70%', md: '50%', lg: '40%' }}
                m="auto"
              >
                {t(`${translationPath}p-1`)}
              </Text>
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                color="#757575"
                w={{ base: '70%', md: '50%', lg: '40%' }}
                m="auto"
                pb="5"
              >
                {t(`${translationPath}p-2`)}
              </Text>
            </Box>
            <Box w="100%" display="flex" justifyContent="center" mb="6">
              <Button
                _focus={{
                  boxShadow: 'none',
                }}
                _active={{
                  bg: '#151C2F',
                  transform: 'scale(0.98)',
                }}
                position="static"
                whiteSpace="normal"
                _hover="none"
                fontSize="15px"
                bg="#27314C"
                color="white"
                size="md"
                w="140px"
                m={'auto'}
                onClick={handleOpenRelease}
              >
                {t(`${translationPath}release`)}
              </Button>
            </Box>
          </SimpleGrid>
        </GridItem>
      </Grid>
    </div>
  );
};
