import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
// import 'swiper/pagination/pagination.min.css';
// import 'swiper/navigation/navigation.min.css';
import black from '../../assets/images/images/Black.gif';
import './StyleSwiper.scss';
import { isMobile } from 'react-device-detect';

import { PriceCard } from '../../ReusableComponent/PriceCard/PriceCard';
import { useMediaQuery } from '@chakra-ui/media-query';

// SwiperCore.use([EffectCoverflow, Pagination, Navigation]);
import {
  Navigation,
  EffectCoverflow,
} from 'swiper';

export const App = () => {
  const [isLargerThan280] = useMediaQuery('(min-width: 300px)');
  const [isLargerThan299] = useMediaQuery('(max-width: 345px)');

  return (
    <div className="">
      <Swiper
        className={
          !isMobile?'swiperBorder3':
          isLargerThan280 && isLargerThan299?
          'swiperBorder4':
          'swiperBorder2'
        }
        freeMode={true}
        navigation={true}
        effect={'coverflow'}
        centeredSlides={true}
        slidesPerView={'auto'}
        loop={true}
        coverflowEffect={{
          rotate: 15,
          stretch: 10,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow, Navigation]}
      >
        <SwiperSlide>
          <PriceCard
            most={'Most Popular'}
            planName={'Gold'}
            start={'Starting'}
            deposit={'10,001-50,000'}
            price={'7.5% of initial balance / year'}
            option1={'Pro Plan Benefits, and:'}
            option2={'Quarterly Update and Check-in Outreach Call'}
            option3={'Dedicated account manager'}
            option4={'AYMGold Card'}
            comingSoon={'COMING SOON'}
            option5={'Binance Subaccount Feature'}
            color="#D7B562"
            VIP={'A VIP representative will contact you within the hour'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            planName={'Platinum'}
            deposit={'50,001-100,000'}
            start={'Starting'}
            price={'6 % of initial balance / year'}
            option1={'Gold Plan Benefits, and:'}
            option2={
              'Exclusive access to AYM fortnightlystate of the market report'
            }
            option3={'Live account management and support, available 24/7'}
            option4={'AYMPlatinum Card'}
            option5={'Access to the exclusive AYMAltSelection Program'}
            comingSoon={'COMING SOON'}
            color="#8D9EAA"
            VIP={'A VIP representative will contact you within the hour'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            planName={'Black'}
            deposit={'100,001 and above'}
            pic={black}
            start={'Starting'}
            price={'Pricing on Request'}
            option1={'Platinum Plan Benefits, and:'}
            option2={'Unlock the maximum power of AYMBot® with 5min trading'}
            option3={
              'Dedicated private co-located server for split second order execution'
            }
            option4={'AYMBlack Card'}
            option5={'Monthly Outreach Calls'}
            comingSoon={'COMING SOON'}
            color="#231F20"
            VIP={'A VIP representative will contact you within the hour'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            planName={'Free'}
            deposit={'100-250'}
            start={'Starting'}
            price={'free'}
            option1={'-'}
            option2={'Capped at $250'}
            option3={'Open-duration limited AYMBot® subscription'}
            option4={'-'}
            option5={'-'}
            color="#27AAE1"
            VIP={'COMING SOON'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            planName={'Basic'}
            deposit={'500-999'}
            start={'Starting'}
            price={'$199/ year'}
            option1={'-'}
            option2={'Full Access to All AYMBot Features'}
            option3={'-'}
            option4={'-'}
            option5={'-'}
            color="#F7941D"
            VIP={''}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            planName={'Standard'}
            start={'Starting'}
            deposit={'1,000-2,500'}
            price={'$399/ year'}
            option1={'Basic Plan Benefits, and:'}
            option2={
              'Access to the power of AYMTrackTM embedded directly into your account'
            }
            option3={'Downloadable Custom Account Performance Results'}
            option4={'-'}
            option5={'-'}
            color="#88D347"
            VIP={''}
          />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            planName={'Pro'}
            start={'Starting'}
            deposit={'2,501-10,000'}
            price={'$799/ year'}
            option1={'Standard Plan Benefits, and:'}
            option2={'AYM Butler Calls'}
            option3={'Hands on initiation'}
            option4={'AYMBot® Hedging™'}
            option5={'-'}
            color="#AC48E7"
            VIP={''}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
