import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {Activation} from './../Activation/Activation'
// import { NotFoundLayout } from '../../Layout/NotFoundLayout/NotFoundLayout';
import Main from './../../View/Main';
import { ContactUs } from './../ContactUs/ContactUs';
import{DownloadReport}from '../DownloadReport'
import{StoryMode}from '../StoryMode'

const SwitchRoute = () => {

    


  return (
    <Switch>
      <Route path={`/`} component={Main} exact />
      <Route path={`/ContactUs`} component={ContactUs} exact />
      <Route path={`/DownloadReport`} component={DownloadReport} exact />
      <Route path={`/Activation`} component={Activation} exact />
      <Route path={`/StoryMode`} component={StoryMode} exact />
    </Switch>
);
};


export default (SwitchRoute)
