/* eslint-disable max-len */
import i18next from 'i18next';
import HomeAr from '../Components/Home/I18n/ar.json';
import HomeEn from '../Components/Home/I18n/en.json';
import WhatIsAYMBotAr from '../Components/WhatIsAYMBot/I18n/ar.json';
import WhatIsAYMBotEn from '../Components/WhatIsAYMBot/I18n/en.json';
import GetStartedAr from '../Components/GetStarted/I18n/ar.json'
import GetStartedEn from '../Components/GetStarted/I18n/en.json'
import ReasultAr from '../Components/AYMBotResults/I18n/ar.json'
import ResultEn from '../Components/AYMBotResults/I18n/en.json'
import FAQAr from '../Components/FAQ/I18n/ar.json'
import FAQEn from '../Components/FAQ/I18n/en.json'
import AffiliateAr from '../Components/Affiliate/I18n/ar.json'
import AffiliateEn from '../Components/Affiliate/I18n/en.json'
import HowItWorkAr from '../Components/HowItWork/I18n/ar.json'
import HowItWorkEn from '../Components/HowItWork/I18n/en.json'
import WhyAYMAr from '../Components/WhyAYMBot/I18n/ar.json'
import WhyAYMEn from '../Components/WhyAYMBot/I18n/en.json'
import PricingAr from '../Components/Pricing/I18n/ar.json'
import PricingEn from '../Components/Pricing/I18n/en.json'
import HeadersAr from '../Layout/Header/I18n/ar.json'
import HeadersEn from '../Layout/Header/I18n/en.json'
import footerAr from '../Layout/Footer/I18n/ar.json'
import footerEn from '../Layout/Footer/I18n/en.json'
import WelcomeEn from '../Layout/HomeLogin/I18n/en.json';
import WelcomeAr from '../Layout/HomeLogin/I18n/ar.json';
import ContactusAr from '../Components/ContactUs/I18n/ar.json';
import ContactusEn from '../Components/ContactUs/I18n/en.json';
import ClientsAr from '../Components/AYMBotClients/I18n/ar.json'
import ClientsEn from '../Components/AYMBotClients/I18n/en.json'
import ContactFormAr from '../ReusableComponent/ContactUsForm/I18n/ar.json'
import ContactFormEn from '../ReusableComponent/ContactUsForm/I18n/en.json'
import { GlobalRerender } from './Middleware.Helper';



export function localizationInit() {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: 'en', // language to use
    resources: {
      en: {
        Home: HomeEn,
        WhatIsAYM: WhatIsAYMBotEn,
        GetStarted:GetStartedEn,
        Result:ResultEn,
        FAQ:FAQEn,
        Affiliate:AffiliateEn,
        HowItWork:HowItWorkEn,
        WhyAYM:WhyAYMEn,
        Pricing:PricingEn,
        Headers:HeadersEn,
        Footer:footerEn,
        Welcome:WelcomeEn,
        Contact:ContactusEn,
        Clients:ClientsEn,
        ContactForm:ContactFormEn
      },
      ar: {
        Home: HomeAr,
        WhatIsAYM: WhatIsAYMBotAr,
        GetStarted:GetStartedAr,
        Result:ReasultAr,
        FAQ:FAQAr,
        Affiliate:AffiliateAr,
        HowItWork:HowItWorkAr,
        WhyAYM:WhyAYMAr,
        Pricing:PricingAr,
        Headers:HeadersAr,
        Footer:footerAr,
        Welcome:WelcomeAr,
        Contact:ContactusAr,
        Clients:ClientsAr,
        ContactForm:ContactFormAr
      },
    },
  });

  if (localStorage.getItem('localization')) {
    i18next.changeLanguage(
      JSON.parse(localStorage.getItem('localization')).currentLanguage
    );
    const isRtl =
      JSON.parse(localStorage.getItem('localization')).currentLanguage === 'ar';
    if (isRtl) {
      const direction =
        JSON.parse(localStorage.getItem('localization')).currentLanguage ===
        'ar'
          ? 'rtl'
          : '';
      document.body.setAttribute('class', direction);
      document.body.setAttribute('dir', direction);
      document.documentElement.lang = JSON.parse(
        localStorage.getItem('localization')
      ).currentLanguage;
    }
  } else {
    localStorage.setItem(
      'localization',
      JSON.stringify({ currentLanguage: 'en', isRtl: false })
    );
    i18next.changeLanguage('en');
  }
}

export const languageChange = currentLanguage => {
  const isRtl = currentLanguage === 'ar';
  const direction = currentLanguage === 'ar' ? 'rtl' : '';
  localStorage.setItem(
    'localization',
    JSON.stringify({ currentLanguage, isRtl })
  );
  document.body.setAttribute('class', direction);
  document.body.setAttribute('dir', direction);
  document.documentElement.lang = currentLanguage;
  i18next.changeLanguage(currentLanguage);
  GlobalRerender();
};
