import { HttpServices } from '../../helper/HttpMethod.Helper';
import config from '../../assets/Configration/Configration.json';

export const ResetPassword = async ({ username ,password,vCode}) => {
  const result = await HttpServices.post(
    `${config.serverAddress}/core/user/rest/auth/confirmforgetpassword`,{},
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'username': `${username}`,
        'new_password' :`${password}`,
        'code':`${vCode}`
      },
    }
  ,);
  return result;
};
