import React, { useState } from 'react';
import {
  Box,
  Text,
  Stack,
  SimpleGrid,
  GridItem,
  Grid,
  Avatar,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Cards } from './../Cards/Cards';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
const parentTranslationPath = 'Clients';
const translationPath = '';

export const AYMBotClients = ({ AYMBotClientsRef }) => {
  const { t } = useTranslation(parentTranslationPath);
  let lang= JSON.parse(localStorage.getItem('localization'))
  const [main, setMain] = useState({
    color: '#151C2F',
    name: 'm-h',
    shadow:'5px 2px 12px #27314C',
    text: 'm-h-t',
  });
  const comments = [
  
   
    {
      name: 'm-h',
      color: '#27314C',
      shadow:"5px 2px 12px #757575",
      text: 'm-h-t',
    },
    {
      name: 'm-d',
      color: '#151C2F',
      shadow:'5px 2px 12px #27314C',
      text: 'm-d-t'
    },
    {
      name: 'b-s',
      color: '#757575',
      shadow:"5px 2px 12px #27314C",
      text: 'b-s-t',
    },
    {
      name: 'k-a',
      color: '#27314C',
      shadow:"5px 2px 12px #757575",
      text: 'k-a-t',
    },
    
    {
      name: 'i-d',
      color: '#4FD1C5',
      shadow:"5px 2px 12px #757575",
      text: "i-d-t",
    },
    {
      name: 'a-a',
      color: '#151C2F',
      shadow:"5px 2px 12px #27314C",
      text: 'a-a-t',
    },
  ];

  const [pageIndex, setPageIndex] = useState(0);

  const handleNext = () => {
    if (pageIndex < comments.length - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  const handlePrev = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };
  return (
    <div ref={AYMBotClientsRef}>
      <Grid display="block" bgColor="white">
        <Box m='auto' display="flex" justifyContent="center">
          <Text 
            pt="6"
            fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
            color="#4FD1C5"
            display="flex"
            fontWeight="bold"
            textAlign="center"
          >
           {t(`${translationPath}MOVEMENT`)}
          </Text>
        </Box>
        <Grid
          templateColumns={{ sm: 'repeat(1, 1fr)', xl: 'repeat(3, 1fr)' }}
          templateRows={{ md: '1fr auto', lg: '1fr' }}
          gap={6}
          h={'auto'}
          textAlign="center"
        >
          <GridItem
            columns={1}
            display={{ base: 'none', sm: 'none', md: 'none', xl: 'flex' }}
          >
            <SimpleGrid width="100%" textAlign="center">
              <Stack mt={20} mb={10} alignItems="center">
                <Box w="40%">
                    <Avatar
                  boxShadow="5px 2px 12px #757575"
                  color="white"
                    bg="#27314C"
                    className="Avatar"
                    size="2xl"
                    onClick={() =>
                      setMain(() => ({
                        color: '#27314C',
                        name: 'm-h',
                        shadow:"5px 2px 12px #757575",
                        text: 'm-h-t',
                      }))
                    }
                    name="m-h"
                  />
                </Box>
              </Stack>
          
              <Stack mt={10} mb={5} ml={10} alignItems="left">
                <Box w="100%" display="flex" justifyContent="space-around">
                <Avatar
                  boxShadow="5px 2px 12px #27314C"
                    className="Avatar"
                    size="2xl"
                    bg="#151C2F"
                    color="white"
                    onClick={() =>
                      setMain(() => ({
                        color: '#151C2F',
                        name: 'm-d',
                        shadow:"5px 2px 12px #27314C",
                        text: 'm-d-t',
                      }))
                    }
                    name="m-d"
                  />
                  <Avatar
                  boxShadow="5px 2px 12px #27314C"
                  color="white"
                    bg="#757575"
                    className="Avatar"
                    size="xl"
                    onClick={() =>
                      setMain(() => ({
                        color: '#757575',
                        name: 'b-s',
                        shadow:"5px 2px 12px #27314C",
                        text: 'b-s-t',
                      }))
                    }
                    name="b-s"
                  />
                </Box>
              </Stack>
            </SimpleGrid>
          </GridItem>
          <GridItem
            columns={1}
            my="8"
            display={{ base: 'none', sm: 'none', md: 'none', xl: 'block' }}
          >
            <SimpleGrid width="100%" textAlign="center">
              <Box>
                <Stack spacing={6} alignItems="center">
                  <Box  w="60%" h="150px">
                    <Avatar boxShadow={main.shadow} color="white" bg={main.color} size="2xl" name={t(`${translationPath}${main.name}`)} />
                  </Box>
                </Stack>

                <Stack spacing={6} alignItems="center">
                  <Box mt='10' w="90%" textAlign="center">
                    <Text
                      fontSize="xl"
                      color="#151C2F"
                      w={{ base: '60%', md: '70%%', xl: '100%' }}
                      m="auto"
                    >
                      {t(`${translationPath}${main.name}`)}
                    </Text>
                    <Text
                      fontSize="sm"
                      color="#757575"
                      w={{ base: '60%', md: '70%%', xl: '100%' }}
                      m="auto"
                    >
                      {t(`${translationPath}${main.text}`)}
                    </Text>
                  </Box>
                </Stack>
              </Box>
            </SimpleGrid>
          </GridItem>
          <GridItem display={{ base: 'flex', xl: 'none' }} alignItems="center">
          {lang.currentLanguage==='en' ? <ChevronLeftIcon color="black" w={10} h={10} onClick={handlePrev} />: <ChevronRightIcon color="black" w={10} h={10} onClick={handlePrev} />}
            
            <Cards comments={comments[pageIndex]} />
            {lang.currentLanguage==='en' ? <ChevronRightIcon color="black" w={10} h={10} onClick={handleNext} />: <ChevronLeftIcon color="black" w={10} h={10} onClick={handleNext} />}
          </GridItem>
          <GridItem
            columns={1}
            display={{ base: 'none', sm: 'none', md: 'none', xl: 'flex' }}
          >
            <SimpleGrid width="100%" textAlign="center">
              <Stack mt={20} mb={10} alignItems="center">
                <Box w="40%">
                  <Avatar
                  boxShadow="5px 2px 12px #757575"
                  color="white"
                    bg="#27314C"
                    className="Avatar"
                    size="2xl"
                    onClick={() =>
                      setMain(() => ({
                        color: '#27314C',
                        name: 'k-a',
                        shadow:"5px 2px 12px #757575",
                        text: 'k-a-t',
                      }))
                    }
                    name="k-a"
                  />
                </Box>
              </Stack>
         
              <Stack mt={10} mb={5} ml={10} alignItems="left">
                <Box w="100%" display="flex" justifyContent="space-around">
                  <Avatar
                  boxShadow="5px 2px 12px #757575"
                  color="white"
                    bg="#4FD1C5"
                    className="Avatar"
                    size="lg"
                    onClick={() =>
                      setMain(() => ({
                        color: '#4FD1C5',
                        name: 'i-d',
                        shadow:"5px 2px 12px #757575",
                        text: "i-d-t",
                      }))
                    }
                    name="i-d"
                  />
                  <Avatar
                  boxShadow="5px 2px 12px #27314C"
                  color="white"
                    bg="#151C2F"
                    className="Avatar"
                    size="2xl"
                    onClick={() =>
                      setMain(() => ({
                        name: 'a-a',
                        color: '#151C2F',
                        shadow:"5px 2px 12px #27314C",
                        text: 'a-a-t',
                      }))
                    }
                    name="a-a"
                  />
                </Box>
              </Stack>
            </SimpleGrid>
          </GridItem>
        </Grid>
      </Grid>
    </div>
  );
};
