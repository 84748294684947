import React from 'react';
import {
  Box,
  Button,
  Text,
  Stack,
  SimpleGrid,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Treading from '../../Layout/Treading/Treading';

const parentTranslationPath = 'WhatIsAYM';
const translationPath = '';

export const WhatIsAYMBot = ({ WhatIsAYMBotRef }) => {
  let lang= JSON.parse(localStorage.getItem('localization'))
  const { t } = useTranslation(parentTranslationPath);

  const handleOpen=()=>{
    if(lang.currentLanguage==='en'){
       window.open('http://blog.aymbot.com/en/all-about-aymbot/' , '_blank')
    }
    else{
      window.open('https://blog.aymbot.com/ar/category/%d8%ad%d9%88%d9%84-aymbot/' , '_blank')
    }

  }
  return (
    <div ref={WhatIsAYMBotRef} id='WhatisAYMBot'>
      <Grid
        templateColumns={{ md: '1fr', lg: '1.5fr 1.5fr' }}
        templateRows={{ md: '1fr auto', lg: '1fr' }}
        bgColor="white"
      >
        <GridItem margin="auto">
          <SimpleGrid
            spacing={10}
            my="8"
            display={{ sm: 'block', md: 'block', lg: 'block', xl: 'flex' }}
          >
            <Stack spacing={6} alignItems="center" >
            <Box w={{base:'280px',sm:'350px' ,md:'450px',lg:"500px",xl:'600px', '2xl':'700px'}}  h='auto' p='15px' bg="#1e222d" borderRadius='20px' >
              <Treading />
              </Box>
            </Stack>

          </SimpleGrid>
        </GridItem>

        <GridItem margin="auto">
          <SimpleGrid
            display={'center'}
            columns={{ sm: 1, md: 1, xl: 1 }}
            spacing={10}
            my="26px"
          >
            <Stack spacing={6} alignItems="center">
            <Box w="100%" textAlign="-webkit-center" my="15">
            <Box w={{ base: '100%', lg: '60%' }} textAlign={{base:"center",lg: lang.currentLanguage==='en'?'left':'right' }}>
                <Text
                fontWeight='bold'
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                  color="#4FD1C5"
                  my="3"
                >
                  {t(`${translationPath}what-AYM`)}
                </Text>
                </Box>
                <Box w={{ base: '75%', lg: '60%' }} textAlign={{base:"center",lg: lang.currentLanguage==='en'?'left':'right' }} mb='4'>
                  <Text
                  className={{base:"rtl",lg: lang.currentLanguage==='en'?'':'rtl' }}
                  fontSize="md"
                    textAlign={{ base: 'center', lg: 'justify' }}
                    color="#757575"
                    w='100%'
                    m="auto"
                  >
                    {t(`${translationPath}pragh-1`)}
                  </Text>
                </Box>
                { lang.currentLanguage === 'en' && 
                  <Box w={{ base: '75%', lg: '60%' }} textAlign={{base:"center",lg: lang.currentLanguage==='en'?'left':'right' }} mb='4'>
                    <Text
                    className={{base:"rtl",lg: lang.currentLanguage==='en'?'':'rtl' }}
                    fontSize="md"
                      textAlign={{ base: 'center', lg: 'justify' }}
                      color="#757575"
                      w='100%'
                      m="auto"
                    >
                    
                      {t(`${translationPath}pragh-1-1`)}
                    
                    </Text>
                  </Box>
                  }
                <Box w={{ base: '75%', lg: '60%' }} textAlign={{base:"center",lg: lang.currentLanguage==='en'?'left':'right' }} mb='4'>
                <Text
                className={{base:"rtl",lg: lang.currentLanguage==='en'?'':'rtl' }}
                fontSize="md"
                  textAlign={{ base: 'center', lg: 'justify' }}
                  color="#757575"
                  w='100%'
                  m="auto"

                >
                  {t(`${translationPath}pragh-new`)}
                </Text>
              </Box>
                <Box w={{ base: '75%', lg: '60%' }} textAlign={{base:"center",lg: lang.currentLanguage==='en'?'left':'right' }} mb='4'>
                <Text
                className={{base:"rtl",lg: lang.currentLanguage==='en'?'':'rtl' }}
                fontSize="md"
                  textAlign={{ base: 'center', lg: 'justify' }}
                  color="#757575"
                  w='100%'
                  m="auto"

                >
                  {t(`${translationPath}pragh-2`)}
                </Text>
              </Box>
              <Box w={{ base: '75%', lg: '60%' }} textAlign={{base:"center",lg: lang.currentLanguage==='en'?'left':'right' }} mb='4'>
              <Text
              className={{base:"rtl",lg: lang.currentLanguage==='en'?'':'rtl' }}
              fontSize="md"
                textAlign={{ base: 'center', lg: 'justify' }}
                color="#757575"
                w='100%'
                m="auto"
              >
                {t(`${translationPath}pragh-3`)}
              </Text>
            </Box>
            <Box w={{ base: '75%', lg: '60%' }} textAlign={{base:"center",lg: lang.currentLanguage==='en'?'left':'right' }} mb='4'>
              <Text
              className={{base:"rtl",lg: lang.currentLanguage==='en'?'':'rtl' }}
              fontSize="md"
                textAlign={{ base: 'center', lg: 'justify' }}
                color="#757575"
                w='100%'
                m="auto"
              >
                {t(`${translationPath}pragh-4`)}
              </Text>
            </Box>
          <Box w={{ base: '75%', lg: '60%' }} textAlign={{base:"center",lg: lang.currentLanguage==='en'?'left':'right' }}  mb='4'>
            <Text
            className={{base:"rtl",lg: lang.currentLanguage==='en'?'':'rtl' }}
            fontSize="md"
              textAlign={{ base: 'center', lg: 'justify' }}
              color="#757575"
              w='100%'
              m="auto"
            >
              {t(`${translationPath}pragh-5`)}
            </Text>
          </Box>



              </Box>
              <Box w={{ base: '100%', lg: '60%' }} textAlign={{base:"center",lg: lang.currentLanguage==='en'?'left':'right' }}>
                <Button
                  _focus={{
                    boxShadow: 'none',
                  }}
                  _active={{
                    bg: '#151C2F',
                    transform: 'scale(0.98)',
                  }}
                  position="static"
                  _hover="none"
                  m="10px"
                  bg="#4FD1C5"
                  color="white"
                  size="md"
                  w="160px"
                  onClick={handleOpen}
                >
                  {t(`${translationPath}learn-more`)}
                </Button>
              </Box>
            </Stack>
          </SimpleGrid>
        </GridItem>
      </Grid>
    </div>
  );
};
