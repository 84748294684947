import React, { useEffect, useRef } from 'react';
import {
  Box,
  Text,
  SimpleGrid,
  GridItem,
  Grid,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import lottie from 'lottie-web';
const parentTranslationPath = 'HowItWork';
const translationPath = '';
export const HowItWork = ({ WhowItWorkRef }) => {
  const { t } = useTranslation(parentTranslationPath);
  let lang = JSON.parse(localStorage.getItem('localization'));
  const radarContainer = useRef();
  const riskContainer = useRef();
  const risksContainer = useRef();

  const handleOpen = () => {
    if (lang.currentLanguage === 'en') {
      window.open('http://blog.aymbot.com/en/how-it-works/', '_blank');
    } else {
      window.open(
        'https://blog.aymbot.com/ar/%D8%9Faymbot-%D9%83%D9%8A%D9%81-%D9%8A%D8%B9%D9%85%D9%84',
        '_blank'
      );
    }
  };
  useEffect(() => {
    lottie.loadAnimation({
      container: radarContainer.current,
      renderer: 'svg',
      loop: true,
      autoPlay: true,
      animationData: require('../../assets/images/images/radar.json'),
    });
  }, []);
  useEffect(() => {
    lottie.loadAnimation({
      container: riskContainer.current,
      renderer: 'svg',
      loop: true,
      autoPlay: true,
      animationData: require('../../assets/images/images/risk.json'),
    });
  }, []);
  useEffect(() => {
    lottie.loadAnimation({
      container: risksContainer.current,
      renderer: 'svg',
      loop: true,
      autoPlay: true,
      animationData: require('../../assets/images/images/risks.json'),
    });
  }, []);
  const content = [
    {
      img: radarContainer,
      className: 'how-its-Work-radar',
      name: 'first-name',
      title: 'first-description',
    },
    {
      img: risksContainer,
      className: 'how-its-Work',
      name: 'second-name',
      title: 'second-description',
    },
    {
      img: riskContainer,
      className: 'how-its-Work',
      name: 'third-name',
      title: 'third-description',
    },
  ];
  return (
    <div>
      <Grid
        ref={WhowItWorkRef}
        id="Howitsworks"
        gap={1}
        m="auto"
        bgGradient={'linear(to-r, #192238, #000000)'}
      >
        <Text
          w={{ base: '100%' }}
          mt="8"
          fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
          color="white"
          display="flex"
          textAlign="center"
          justifyContent="center"
          fontWeight="bold"
        >
          {t(`${translationPath}how-it-work`)}
        </Text>
        <Grid
          ref={WhowItWorkRef}
          position="relative"
          templateColumns={{
            sm: 'repeat(1, 1fr)',
            xl: 'repeat(3, 1fr)',
          }}
          gap={1}
          m="auto"
        >
          {content.map((item, i) => (
            <GridItem columns={1} position="relative" py="5" key={i}>
              <SimpleGrid width="100%" m="auto">
                <Box
                  m={{ base: 'auto', md: 'none' }}
                  w={{ base: '70%', md: '90%' }}
                  h="auto"
                  borderRadius="5px"
                >
                  <Box h="60px">
                    <Text
                      my="4"
                      fontSize="lg"
                      color="#4FD1C5"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      {t(`${translationPath}${item.name}`)}
                    </Text>
                  </Box>

                  <Box w="100%" h="320px" display="flex" alignItems="center">
                    {' '}
                    <div className={item.className} ref={item.img}>
                      {' '}
                    </div>
                  </Box>

                  <Box
                    textAlign="center"
                    width="100%"
                    color="#757575"
                    my="10"
                    fontSize="18px"
                  >
                    <div
                      style={{
                        width: '90%',
                        display: 'inline-flex',
                        justifyContent: '-center',
                      }}
                    >
                      <Text
                        fontSize="md"
                        m={{ base: 'auto' }}
                        textAlign={{ base: 'center' }}
                        w={{ base: '80%', xl: '90%' }}
                        color="#757575"
                      >
                        {t(`${translationPath}${item.title}`)}
                      </Text>
                    </div>
                  </Box>
                </Box>
              </SimpleGrid>
            </GridItem>
          ))}
        </Grid>
        <Box mb="6" w={{ base: '100%' }} textAlign={{ base: 'center' }}>
          <Button
            _focus={{
              boxShadow: 'none',
            }}
            _active={{
              bg: '#151C2F',
              transform: 'scale(0.98)',
            }}
            position="static"
            _hover="none"
            m="10px"
            bg="#4FD1C5"
            color="white"
            size="md"
            w="160px"
            onClick={handleOpen}
          >
            {t(`${translationPath}learn-more`)}
          </Button>
        </Box>
      </Grid>
    </div>
  );
};
