import React, { useState, useCallback } from 'react';
import {
  Input,
  Button,
  Box,
  Grid,
  Textarea,
  Text,
  FormControl,
  FormHelperText,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalContent } from '@chakra-ui/react';
import Joi from 'joi-browser';
import { ContactUs } from '../../services/ContactUs/ContactUs';
import GA from 'react-ga';
import ReactGA from "react-ga4";

const parentTranslationPath = 'ContactForm';
const translationPath = '';

const ContactUsForm = ({ onOpen, onClose, isOpen }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [state, setState] = useState({
    message: {
      name: '',
      email: '',
      subject: '',
      phone_number: '',
      message: '',
    },
    error: {},
  });
  const schema = Joi.object({
    name: Joi.string().required().label('Name'),
    email: Joi.string().required().email().label('email'),
    phone_number: Joi.number().required().label('Mobile Number'),
    subject: Joi.string().required().label('subject'),
    message: Joi.string().required().label('Message'),
  });

  const validate = () => {
    const result = Joi.validate(state.message, schema, {
      abortEarly: false,
      allowUnknown: false,
    });
    if (!result.error) return null;
    const errors = {};

    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleContactUs = useCallback(
    async e => {
      e.preventDefault();
      const errors = validate();
      setState(prev => ({
        ...prev,
        error: errors,
      }));
      if (errors && errors) {
        toast({
          title: t(`${translationPath}fix-error`),
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      } else {
        setLoading(true);
        const response = await ContactUs(state.message);
        if (response && response.data && response.data.accessToken !== null) {
          GA.event({
            category: 'User',
            action: 'Contact us-UA'
          });
          ReactGA.event({
            category: 'User',
            action: 'Contact us'
          });
          toast({
            title: t(`${translationPath}sent-successfully`),
            position: 'top-right',
            isClosable: true,
            status: 'success',
          });
          setState(prev => ({
            ...prev,
            message: {
              ...prev.message,
              name: '',
              email: '',
              subject: '',
              phone_number: '',
              message: '',
            },
          }));
          onClose();
        } else {
          toast({
            title: t(`${translationPath}somthing-wrong`),
            position: 'top-right',
            isClosable: true,
            status: 'error',
          });
        }
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    <>
      <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <Grid
            //  bgColor="#27314C"
            // backgroundImage={img}
            className={'colorContact'}

            backgroundPosition="inherit"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundAttachmentun="cover"
          >
            <Box>
              <Text
                textAlign="center"
                fontSize="4xl"
                color="#4FD1C5"
                fontWeight="bold"
                mt="5"
                mb="3"
              >
                {t(`${translationPath}get-in-touch`)}
              </Text>
              <Text
                textAlign="center"
                fontSize="xl"
                color="#757575"
                fontWeight="bold"
                my="3"
              >
                {t(`${translationPath}Feel-free`)}
              </Text>
            </Box>

            <Box
              w={{ base: '90%', lg: '91.5%' }}
              m="auto"
              display={{ base: 'block', lg: 'flex' }}
            >
              <FormControl mx={{ base: '0', lg: '2' }} my="4">
                <Input
                  bgColor="#ffffff"
                  _placeholder={{ color: '#151C2F' }}
                  borderColor="black"
                  color="black"
                  placeholder={t(`${translationPath}name`)}
                  id="Name"
                  type="Text"
                  value={state.message.name}
                  onChange={event => {
                    setState(prev => ({
                      ...prev,
                      message: {
                        ...prev.message,
                        name: event.target.value,
                      },
                    }));
                  }}
                />
                {state.error && state.error.name && (
                  <FormHelperText color="red">
                    {state.error.name}{' '}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl mx={{ base: '0', lg: '2' }} my="4">
                <Input
                  bgColor="#ffffff"
                  color="black"
                  value={state.message.email}
                  _placeholder={{ color: '#151C2F' }}
                  borderColor="black"
                  placeholder={t(`${translationPath}email`)}
                  id="email"
                  type="email"
                  onChange={event => {
                    setState(prev => ({
                      ...prev,
                      message: {
                        ...prev.message,
                        email: event.target.value,
                      },
                    }));
                  }}
                />
                {state.error && state.error.email && (
                  <FormHelperText color="red">
                    {state.error.email}{' '}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl mx={{ base: '0', lg: '2' }} mt="4">
                <Input
                value={state.message.phone_number}
                  bgColor="#ffffff"
                  color="black"
                  _placeholder={{ color: '#151C2F' }}
                  borderColor="black"
                  placeholder={t(`${translationPath}mobile`)}
                  id="PhoneNumber"
                  type="number"
                  onChange={event => {
                    setState(prev => ({
                      ...prev,
                      message: {
                        ...prev.message,
                        phone_number: event.target.value,
                      },
                    }));
                  }}
                />
                {state.error && state.error.phone_number && (
                  <FormHelperText color="red">
                    {state.error.phone_number}{' '}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box w="90%" m="auto">
              {' '}
              <Box my="4">
                <FormControl>
                  <Input
                    color="black"
                    bgColor="#ffffff"
                    value={state.message.subject}
                    _placeholder={{ color: '#151C2F' }}
                    borderColor="black"
                    placeholder={t(`${translationPath}Subject`)}
                    id="Subject"
                    type="Text"
                    onChange={event => {
                      setState(prev => ({
                        ...prev,
                        message: {
                          ...prev.message,
                          subject: event.target.value,
                        },
                      }));
                    }}
                  />
                  {state.error && state.error.subject && (
                    <FormHelperText color="red">
                      {state.error.subject}{' '}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box my="10">
                <FormControl>
                  <Textarea
                value={state.message.message}
                    color="black"
                    bgColor="#ffffff"
                    borderColor="black"
                    borderRadius="8px"
                    _placeholder={{ color: '#151C2F' }}
                    placeholder={t(`${translationPath}Whats-up-human`)}
                    size="xl"
                    onChange={event => {
                      setState(prev => ({
                        ...prev,
                        message: {
                          ...prev.message,
                          message: event.target.value,
                        },
                      }));
                    }}
                  />
                  {state.error && state.error.message && (
                    <FormHelperText color="red">
                      {state.error.message}{' '}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Box>
            <Box
              width="90%"
              m="auto"
              display="flex"
              justifyContent={{ base: 'center', lg: 'left' }}
            >
              <Button
                my="4"
                isLoading={loading}
                _focus={{
                  boxShadow: 'none',
                }}
                _active={{
                  bg: '#151C2F',
                  transform: 'scale(0.98)',
                }}
                position="static"
                _hover="none"
                bg="#4FD1C5"
                color="white"
                size="lg"
                w="170px"
                onClick={handleContactUs}
              >
                {t(`${translationPath}Submit`)}
              </Button>
            </Box>
          </Grid>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ContactUsForm;
