import { localizationInit } from './TranslateMethod.Helper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export let GlobalTranslate = null;
export let GlobalHistory = null;
let renderVar = false;
let setRenderVar = null;
export const ResetActiveItem = null;

export const SetGlobalRerender = (setRender, render) => {
  renderVar = render;
  setRenderVar = setRender;
};

function MiddlewareHelper() {
  GlobalTranslate = useTranslation();
  GlobalHistory = useHistory();
  return null;
}
localizationInit();


export const GlobalRerender = () => {
  setRenderVar(!renderVar);
};

export const getErrorByName = (schemaObject, fieldName, type) => {
  if ((schemaObject && !schemaObject.error) || (schemaObject &&!schemaObject.error.details)) {
    return {
      message: undefined,
      error: undefined,
      type: undefined,
    };
  }
  const item = schemaObject.error.details.find(
    (element) =>
      (!Number.isNaN(fieldName) && element.path.includes(fieldName)) ||
      (Number.isNaN(fieldName) && !fieldName.includes('.') && element.path.includes(fieldName)) ||
      (Number.isNaN(fieldName) &&
        fieldName.includes('.') &&
        element.path.length >= fieldName.split('.').length &&
        element.path.slice(0, fieldName.split('.').length).join('.') === fieldName)
  );
  if (!item || (type && item.type !== type)) {
    return {
      message: undefined,
      error: undefined,
      type: undefined,
    };
  }
  return {
    message: item.message,
    error: true,
  };
};


export { MiddlewareHelper as Middleware };
