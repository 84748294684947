import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { lineChartOptions } from '../../variables/charts';
import { useTranslation } from 'react-i18next';

const parentTranslationPath = 'Contact';
const translationPath = '';

const LineChart =({chartData,sliderValue}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [state,setState]=useState( { chartData: [],
    chartOptions: {
      grid: {
        show: false
    }
    }})

  useEffect(() => {
    setState({
      chartData:  [
        {
          name:t(`${translationPath}Account`),
          data: (chartData && chartData.length > 0 &&  chartData[0].green)||[]
        },
        {
          name:t(`${translationPath}fees`),
          data: (chartData && chartData.length > 0 &&  chartData[0].black)||[]
        },
        {
          name:t(`${translationPath}stocks`),
          data: (chartData && chartData.length > 0 &&  chartData[0].stocksReturn)||[]
        },
        {
          name:t(`${translationPath}termDeposit`),
          data: (chartData && chartData.length > 0 &&  chartData[0].termDepositReturns)||[]
        },
        {
          name:t(`${translationPath}gold`),
          data: (chartData && chartData.length > 0 &&  chartData[0].goldReturns)||[]
        },
      ],
      chartOptions: lineChartOptions,
    });
  }, [chartData, t]);

  return (
    <ReactApexChart
      options={state.chartOptions}
      series={state.chartData}
      type="area"
      width="100%"
      height="100%"
    />
  );
};

export default LineChart;
