import React, { useState, useCallback, useEffect } from 'react';
import { AYMBotResults } from '../../Components/AYMBotResults/AYMBotResults';
import {
  Box,
  Text,
  Grid,
  VStack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useDisclosure,
  NumberInputStepper,
  NumberInputField,
  NumberInput,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import LineChart from './../Charts/LineChart';
// import AYM from '../../assets/images/images/AYM.png';
import { Model } from './../Model/HomeModel';
import { GetPricing } from '../../services/Pricing';
import { App } from '../Swiper/SwiperCard.jsx';
const parentTranslationPath = 'Pricing';
const translationPath = '';

export const Pricing = ({ PricingRef }) => {
  let lang = JSON.parse(localStorage.getItem('localization'));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sliderValue, setSliderValue] = useState(500);
  const [chartData, setChartData] = useState([]);
  const [fees, setFees] = useState(199);
  const [plan, setPlan] = useState('Free');
  const getBalance = useCallback(async () => {
    const response = await GetPricing(sliderValue);
    if (response && response.data.status === 'success') {
      setFees(response.data.result.fee);
      setPlan(response.data.result.plan);
      setChartData([response.data.result]);
    } else {
      setPlan('Free');
      chartData([]);
      setFees(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValue]);

  const format = val => `$` + val;

  useEffect(() => {
    const timeoutId = setTimeout(() => getBalance(), 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getBalance]);

  const handleOpen = () => {
    if (lang.currentLanguage === 'en') {
      window.open(
        'https://blog.aymbot.com/en/tiered-pricing-explained/',
        '_blank'
      );
    } else {
      window.open(
        'https://blog.aymbot.com/ar/%d8%b4%d8%b1%d8%a7%d8%a6%d8%ad-%d8%a5%d8%b4%d8%aa%d8%b1%d8%a7%d9%83%d8%a7%d8%aa-aymbot/',
        '_blank'
      );
    }
  };
  const { t } = useTranslation(parentTranslationPath);
  return (
    <div ref={PricingRef} id="Pricing">
      <Model isOpen={isOpen} onOpen={onOpen} onClose={onClose} sixe="xl" />
      <Grid h="auto">
        <VStack bgColor="#E5E5E5" w="100%">
          <Text
            mt="4"
            color="#4FD1C5"
            fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
            fontWeight="bold"
          >
            {t(`${translationPath}result`)}
          </Text>
          <Text
            textAlign="center"
            fontSize={{ base: 'sm', lg: 'md' }}
            color="#757575"
            w={{ base: '60%', lg: '50%' }}
          >
            {t(`${translationPath}title`)}
          </Text>
          <Text
            my="5"
            display="none"
            textAlign="center"
            fontSize={{ base: 'sm', lg: 'md' }}
            color="#757575"
            w={{ base: '60%', lg: '50%' }}
          >
            {t(`${translationPath}also-see`)}
          </Text>
          <Box w={{ base: '80%', lg: '45%' }} mt="15">
            <Box display="flex" mt="5">
              <Box
                className="input-center"
                w="50%"
                display="block"
                justifyContent="center"
              >
                <Text
                  textAlign="center"
                  fontWeight="bold"
                  color="#757575"
                  mb="4"
                  fontSize="sm"
                  h={{ base: '50px', sm: 'auto' }}
                  whiteSpace={{ base: 'normal', sm: 'nowrap' }}
                >
                  {t(`${translationPath}Initial-capital`)}
                </Text>
                <Box display="flex" justifyContent="center">
                  <NumberInput
                    color="#757575"
                    bgColor="#ffffff"
                    borderColor="4FD1C5"
                    borderRadius="8px"
                    _placeholder={{ color: '#151C2F' }}
                    w={{ base: '80%', sm: '60%', md: '50%' }}
                    min={100}
                    max={1000000}
                    value={format(sliderValue)}
                    onChange={event => {
                      setSliderValue(event);
                    }}
                  >
                    <NumberInputField />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </Box>
              </Box>

              <Box
                className="input-center"
                w="50%"
                display="block"
                justifyContent="center"
              >
                <Text
                  textAlign="center"
                  fontWeight="bold"
                  color="#757575"
                  mb="4"
                  fontSize="sm"
                  h={{ base: '50px', sm: 'auto' }}
                  whiteSpace={{ base: 'normal', sm: 'nowrap' }}
                >
                  {t(`${translationPath}first-fee`)}
                </Text>
                <Box display="flex" justifyContent="center">
                  <NumberInput
                    color="#757575"
                    bgColor="#ffffff"
                    borderColor="4FD1C5"
                    borderRadius="8px"
                    _placeholder={{ color: '#151C2F' }}
                    w={{ base: '80%', sm: '60%', md: '50%' }}
                    isReadOnly={true}
                    value={format(Math.floor(fees))}
                  >
                    <NumberInputField />
                    <NumberInputStepper></NumberInputStepper>
                  </NumberInput>
                </Box>
              </Box>
            </Box>
            <Box width="80%" m="auto">
              <Box pt="4" m="auto">
                <Text textAlign="center" color="#757575">
                  {' '}
                  {lang.currentLanguage === 'en'
                    ? t(`${translationPath}${plan}`) +
                      t(`${translationPath}Plan`)
                    : t(`${translationPath}Plan`) +
                      t(`${translationPath}${plan}`)}
                </Text>
              </Box>
              <Slider
                my="8"
                flex="1"
                min={100}
                max={100000}
                focusThumbOnChange={false}
                value={sliderValue}
                onChange={event => {
                  setSliderValue(event);
                }}
              >
                <SliderTrack bg="#757575">
                  <SliderFilledTrack bg="#4FD1C5" />
                </SliderTrack>

                <SliderThumb fontSize="sm" boxSize="15px" />
              </Slider>
            </Box>
          </Box>

          <Box w="90%" h={{ base: '450px' }} my="6" position="relative">
            <LineChart chartData={chartData} sliderValue={sliderValue} />
          </Box>
        </VStack>
        <Box bgColor="#E5E5E5">
          <Box overflow="wrap" m="auto" w="60%">
            <Text
              fontSize={{ base: 'sm', lg: 'md' }}
              color="#757575"
              width="100%"
              my="8"
             display
              textAlign="center"
            >
              {t(`${translationPath}p-2`)}
              <span onClick={handleOpen} style={{color:"#4FD1C5" , margin:"0 2px" , cursor: "pointer"}} >{t(`${translationPath}P-3`)}</span>
               <span> {t(`${translationPath}P-4`)}</span>
            </Text>
           
          </Box>
        </Box>

        <Box bgGradient="linear(to-r,#192238, #000000)">
          <Box m="auto">
            {' '}
            <Text
              textAlign="center"
              mt="6"
              mb="12"
              color="white"
              fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              fontWeight="bold"
            >
              {t(`${translationPath}PRICING-PLANS`)}
            </Text>
          </Box>

          <Box width="100%" h="650px" m="auto">
            <App />
          </Box>
        </Box>
      </Grid>
      <AYMBotResults />
    </div>
  );
};
