import React from 'react';
import {
  Box,
  Button,
  Text,
  Grid,
  GridItem,
  SimpleGrid,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const parentTranslationPath = 'FAQ';
const translationPath = '';

export const ContactUs = ({ ContactUsRef }) => {
  const history = useHistory();
  const { t } = useTranslation(parentTranslationPath);

  const handleContactUs = () => {
    history.push(`/ContactUs`);
  };

  return (
    <div ref={ContactUsRef} id='ContactUs'>
      <Grid
     
        h={{ base: 'auto' }}
        bgColor="#E5E5E5"
        templateColumns={{ base: 'repeat(1, 2fr)' }}
      >
        <GridItem my="10">
          <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }}>
            <Box  w="100%" textAlign="center" my="0">
              <Text
                color="#4FD1C5"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                fontWeight="bold"
              >
                {t(`${translationPath}contact-us`)}
              </Text>

              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                color="#757575"
                py="4"
                w={{ base: '70%', md: '50%', lg: '40%' }}
                m="auto"
              >
                {t(`${translationPath}P-1`)}
              </Text>
              <Text
                fontSize={{ base: 'sm', md: 'md' }}
                color="#757575"
                w={{ base: '70%', md: '50%', lg: '40%' }}
                m="auto"
              >
                {t(`${translationPath}P-2`)}
              </Text>
            </Box>
            <Box w="100%" mt="6" display="flex" justifyContent="center">
              <Button
                _focus={{
                  boxShadow: 'none',
                }}
                _active={{
                  bg: '#151C2F',
                  transform: 'scale(0.98)',
                }}
                position="static"
                whiteSpace="normal"
                _hover="none"
                fontSize="15px"
                bg="#27314C"
                color="white"
                size="md"
                w="180px"
                onClick={handleContactUs}
              >
                {t(`${translationPath}contact-btn`)}
              </Button>
            </Box>
          </SimpleGrid>
        </GridItem>
      </Grid>
    </div>
  );
};
