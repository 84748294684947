import React from 'react';
import {
  Box,
  Button,
  Text,
  Stack,
  SimpleGrid,
  GridItem,
  Grid,
  useDisclosure,
} from '@chakra-ui/react';
// import step3 from '../../assets/images/images/step3.png';
import step1 from '../../assets/images/images/step-1.png';
import step2 from '../../assets/images/images/step-2.png';
import step3 from '../../assets/images/images/step-3.png';

import { useTranslation } from 'react-i18next';
import { Model } from './../Model/HomeModel';
const parentTranslationPath = 'GetStarted';
const translationPath = '';

export const GetStarted = ({ GetStartedRef }) => {
  const { t } = useTranslation(parentTranslationPath);
  const { isOpen, onOpen, onClose } = useDisclosure();
  let token =localStorage.getItem('token') 
  
  const handleOpen = () => {
    if(!token || token ==='null'){
      onOpen();

    }else{
      window.location.href = '/admin/profile';
    }
    
  };
  return (
    <div ref={GetStartedRef} id='Getstarted'>
      <Model isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Box m="auto" text-align="center" display="flex" justifyContent="center">
        <Text
          w={{ base: '80%', sm: '50%' }}
          mt="8"
          fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
          color="white"
          display="flex"
          textAlign="center"
          justifyContent="center"
          fontWeight="bold"
        >
          {t(`${translationPath}get-started`)}
        </Text>
      </Box>
      <Grid
      bgGradient ={'linear(to-r,#192238, #000000)'}
        templateColumns={{ xl: 'repeat(3, 1fr)', sm: 'repeat(1, 1fr)' }}
        templateRows={{ md: '1fr auto', lg: '1fr auto' }}
        gap={6}
        mt="8"
      >
      <GridItem margin="auto">
      <SimpleGrid spacing={10} my="26px">
        <Stack spacing={6} alignItems="center">
          <Box>
            <Box w="100%" textAlign="center">
              <Text
                fontSize="lg"
                color="#4FD1C5"
                textAlign="center"
                w={{ sm: '60%', xl: '100%' }}
                m="auto"
                fontWeight="bold"
                
              >
              {t(`${translationPath}Sign-up`)}
              </Text>
            </Box>
            <Box my='20px' className="getStarted" w="100%" h="auto">
              <img
            
                src={step1}
                alt="Dan Abramov"
              />
            </Box>
          </Box>
        </Stack>
        <Stack spacing={6} alignItems="center">
          <Box w="90%" minH='100px' textAlign="center">
            <Text
              fontSize="md"
              color="#abaaaa"
              w={{ sm: '60%', xl: '80%' }}
              m="auto"
            >
              {t(`${translationPath}step-1`)}
            </Text>
          </Box>
        </Stack>
      </SimpleGrid>
    </GridItem>
        <GridItem margin="auto">
          <SimpleGrid spacing={10} my="26px">
            <Stack spacing={6} alignItems="center">
              <Box>
                <Box w="100%" textAlign="center">
                  <Text
                    fontSize="lg"
                    color="#4FD1C5"
                    textAlign="center"
                    w={{ sm: '60%', xl: '100%' }}
                    m="auto"
                    
                    fontWeight="bold"
                  >
                  {t(`${translationPath}Connect`)}
                  </Text>
                </Box>
                <Box  my='20px' className="getStarted" w="100%" h="450px">
              <img
                src={step2}
                alt="Dan Abramov"
              />
            </Box>
              </Box>
            </Stack>
            <Stack spacing={6} alignItems="center">
              <Box w="90%" minH='100px' textAlign="center">
                <Text
                  fontSize="md"
                  color="#abaaaa"
                  w={{ sm: '60%', xl: '80%' }}
                  m="auto"
                >
                  {t(`${translationPath}step-2`)}
                </Text>
              </Box>
            </Stack>
          </SimpleGrid>
        </GridItem>
        <GridItem margin="auto">
        <SimpleGrid spacing={10} my="26px">
          <Stack spacing={6} alignItems="center">
            <Box>
              <Box w="100%" textAlign="center">
                <Text
                  fontSize="lg"
                  color="#4FD1C5"
                  textAlign="center"
                  w={{ sm: '60%', xl: '100%' }}
                  m="auto"
                  fontWeight="bold"
                  
                >
                {t(`${translationPath}Track`)}
                </Text>
              </Box>
              <Box my='20px' className="getStarted" w="100%" h="450px">
              <img
                src={step3}
                alt="Dan Abramov"
              />
            </Box>
            </Box>
          </Stack>
          <Stack spacing={6} alignItems="center">
            <Box w="90%" minH='100px' textAlign="center">
              <Text
                fontSize="md"
                color="#abaaaa"
                w={{ sm: '60%', xl: '80%' }}
                m="auto"
              >
                {t(`${translationPath}step-3`)}
              </Text>
            </Box>
          </Stack>
        </SimpleGrid>
      </GridItem>
      </Grid>

      <Box
        m="auto"
        pb="4"
        text-align="center"
        display="flex"
        justifyContent="center"
      >
        <Button
          _focus={{
            boxShadow: 'none',
          }}
          _active={{
            bg: '#151C2F',
            transform: 'scale(0.98)',
          }}
          position="static"
          whiteSpace="normal"
          _hover="none"
          m="15px"
          fontSize="15px"
          bg="#4FD1C5"
          color="white"
          size="lg"
          w="180px"
          onClick={handleOpen}
        >
          {t(`${translationPath}start-now`)}
        </Button>
      </Box>
    </div>
  );
};
