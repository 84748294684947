import React from 'react';
import { Modal, ModalContent } from '@chakra-ui/react';

import Login from '../../Layout/HomeLogin/Login';
export const Welcome = ({ onOpen, onClose, isOpen }) => {

  return (
    <div className="" >
      {' '}
      <Modal
      class="modaltest"
        borderRadius={'20px'}
        size='auto'
        isOpen={isOpen}
        onClose={onClose}
        bgColor="transparent"
      >
        <ModalContent 

          width={{base: "350px",  md:"600px", lg: "950px"}}>
          <Login   onClose={onClose} />
        </ModalContent>
      </Modal>
    </div>
  );
};
export default Welcome;
