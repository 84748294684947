import React from 'react';
import {
  Grid,
  Text,
  GridItem,
  SimpleGrid,
  Box,
  Image,
  Flex,
  Center,
  Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@chakra-ui/media-query';
// import Automation from './../../assets/images/images/Automation.png';
// import Bleeding from './../../assets/images/images/Bleeding.png';
// import Emotion from './../../assets/images/images/Emotion.png';
// import Inhuman from './../../assets/images/images/Inhuman.png';
// import Intelligent from './../../assets/images/images/Intelligent.png';
// import Relentless from './../../assets/images/images/Relentless.png';
// import Reliable from './../../assets/images/images/Reliable.png';
// import safe from './../../assets/images/images/Safe.png';
// import btc from './../../assets/images/images/btc.png';
// import btcflower from './../../assets/images/images/btcflower.png';
// import stop from './../../assets/images/images/stop.png';
// import dashboard from './../../assets/images/images/dashboard.png';

import AYMTrack from './../../assets/images/whyaym/AYMTrack.png';
import BleedingEdge from './../../assets/images/whyaym/BleedingEdge.png';
import DataDriven from './../../assets/images/whyaym/DataDriven.png';
import FullyAutomated from './../../assets/images/whyaym/FullyAutomated.png';
import Intelligentn from './../../assets/images/whyaym/Intelligentn.png';
import GeneratePassiveIncome from './../../assets/images/whyaym/GeneratePassiveIncome.png';
import InhumanReturns from './../../assets/images/whyaym/InhumanReturns.png';
// import Intelligent from './../../assets/images/whyaym/Intelligent.png';
import InvestingMadeSimple from './../../assets/images/whyaym/InvestingMadeSimple.png';
// import imggif from './../../assets/images/whyaym/imggif.gif';
import Profit from './../../assets/images/whyaym/Profit.gif';

import Reliablen from './../../assets/images/whyaym/Reliablen.png';
import SafeFlexible from './../../assets/images/whyaym/SafeFlexible.png';
import LimitedDownside from './../../assets/images/whyaym/LimitedDownside.png';
import ProfitinbothBearishBullishMarkets from './../../assets/images/whyaym/ProfitinbothBearishBullishMarkets.png';
import { isMobile } from 'react-device-detect';

const parentTranslationPath = 'WhyAYM';
const translationPath = '';

export const WhyAYMBot = ({ WhyAYMBotRef }) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
  const [isSmallScreenFold] = useMediaQuery('(min-width: 332px)'&&'(max-width: 400px)');
  // const [isSmallScreenFold] = useMediaQuery('(min-width: 332px)');



  const { t } = useTranslation(parentTranslationPath);
  const images = [
    {
      name: 'Intelligent',
      img: Intelligentn,
      title: 'Intelligent-title',
    },
    {
      name: 'Bleeding-Edge',
      img: BleedingEdge,
      title: 'Bleeding-Edge-title',
    },
    {
      name: 'Fully-Automated',
      img: FullyAutomated,
      title: 'Fully-Automated-title',
    },
    {
      name: 'Safe-Flexible',
      img: SafeFlexible,
      title: 'Safe-Flexible=title',
    },
    {
      name: 'Relentless',
      img: ProfitinbothBearishBullishMarkets,
      title: 'Relentless-title',
    },

    {
      name: 'Data-Driven',
      img: DataDriven,
      title: 'Data-Driven-title',
    },
    {
      name: 'Reliable',
      img: Reliablen,
      title: 'Reliable-title',
    },
    {
      name: 'Inhuman-Returns',
      img: InhumanReturns,
      title: 'Inhuman-Returns-title',
    },
    {
      name: 'Investing-made-simple',
      img: InvestingMadeSimple,
      title: 'Investing-made-simple-title',
    },
    {
      name: 'Generate-passive-income',
      img: GeneratePassiveIncome,
      title: 'Generate-passive-income-title',
    },
    {
      name: 'Limite-Downside',
      img: LimitedDownside,
      title: 'Limite-Downside-title',
    },
    {
      name: 'AYMTrack',
      img: AYMTrack,
      title: 'AYMTrack-title',
    },
  ];

  return (
    <div ref={WhyAYMBotRef} id="WhyAYMBot">
      <Box mt="8" mb={8} bgColor="#E5E5E5" justifyItems="center">
        <Center>
          <Box
            display="flex"
            justifyItems="center"
            alignItems="center"
            alignSelf="center"
          >
            <Text color="#4FD1C5" fontSize="40px" mt="8" fontWeight="bold">
              {' '}
              {t(`${translationPath}why-aym`)}
            </Text>
          </Box>
        </Center>

        <SimpleGrid
          display="flex"
          mt="8"
          bgColor="#E5E5E5"
          justifyItems="center"
        >
          <div className="css-o93abw">
            <Box className="css-o93abw">
              <Box width="100%" m="auto">
                {isSmallScreen ||isSmallScreenFold ? (
                  <Grid
                    bgColor="#E5E5E5"
                    justifyItems="center"
                    templateRows="repeat(2, 1fr)"
                    templateColumns="repeat(2, 1fr)"
                    gap={2}
                    mt="8"
                  >
                    {images.map((item, i) => (
                      <GridItem key={i}>
                        <SimpleGrid minChildWidth="120px">
                          <Box width="80%" m="auto">
                            <Flex
                              display="block"
                              textAlign={{ base: 'center' }}
                            >
                              <Box display="flex" justifyContent="center">
                                {' '}
                                <Image
                                  objectFit="contain"
                                  src={item.img}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  h="80px"
                                  // width="30%"
                                  width={['100%', '50%']}
                                />
                              </Box>

                              <Text
                                fontSize="md"
                                color="#4FD1C5"
                                mt="2"
                                display="flex"
                                justifyContent="center"
                                fontWeight="bold"
                              >
                                {t(`${translationPath}${item.name}`)}
                              </Text>
                            </Flex>
                            <Box
                              display="flex"
                              justifyContent="center"
                              textAlign="center"
                              width="100%"
                              color="#757575"
                              mb="10"
                              fontSize="18px"
                            >
                              <div
                                style={{ width: '90%', display: 'inline-flex' }}
                              >
                                <Text
                                  fontSize="sm"
                                  m={{ sm: 'auto' }}
                                  textAlign={{ base: 'center' }}
                                  w={{ sm: '60%', md: '70%', lg: '100%' }}
                                >
                                  {t(`${translationPath}${item.title}`)}
                                </Text>
                              </div>
                            </Box>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    ))}
                  </Grid>
                ) : (
                  <Grid
                    bgColor="#E5E5E5"
                    justifyItems="center"
                    templateRows="repeat(2, 1fr)"
                    templateColumns="repeat(3, 1fr)"
                    gap={2}
                    mt="8"
                  >
                    {images.map((item, i) => (
                      <GridItem key={i}>
                        <SimpleGrid minChildWidth="120px">
                          <Box width="80%" m="auto">
                            <Flex
                              display="block"
                              textAlign={{ base: 'center' }}
                            >
                              <Box display="flex" justifyContent="center">
                                {' '}
                                <Image
                                  objectFit="contain"
                                  src={item.img}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  h="80px"
                                  // width="30%"
                                  width={['100%', '50%']}
                                />
                              </Box>

                              <Text
                                fontSize="md"
                                color="#4FD1C5"
                                mt="2"
                                display="flex"
                                justifyContent="center"
                                fontWeight="bold"
                              >
                                {t(`${translationPath}${item.name}`)}
                              </Text>
                            </Flex>
                            <Box
                              display="flex"
                              justifyContent="center"
                              textAlign="center"
                              width="100%"
                              color="#757575"
                              mb="10"
                              fontSize="18px"
                            >
                              <div
                                style={{ width: '90%', display: 'inline-flex' }}
                              >
                                <Text
                                  fontSize="sm"
                                  m={{ sm: 'auto' }}
                                  textAlign={{ base: 'center' }}
                                  w={{ sm: '60%', md: '70%', lg: '100%' }}
                                >
                                  {t(`${translationPath}${item.title}`)}
                                </Text>
                              </div>
                            </Box>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    ))}
                  </Grid>
                )}

               
              </Box>
              {isMobile?
              ''
              :
              <Center height="vertical" p={1}>
              <Divider
                  orientation="vertical"
                  borderColor="#5e5f61"
                  size="5"
                />
              </Center>

              }
                
              <Box
                justifyContent="center"
                w={{ base: '50%' }}
                className="css-r30ywd"
              >
                <Image
                  objectFit="contain"
                  src={Profit}
                  alt={'alt'}
                  backgroundSize="auto"
                  h="auto"
                  width="100%"
                />
                <div className="css-1p0rkue">
                  <Text
                    color="#4FD1C5"
                    fontSize="md"
                    ml="2"
                    mr="2"
                    textAlign="center"
                    alignItems="center"
                    alignSelf="center"
                    fontWeight="bold"
                    className="css-1p0rkue"
                  >
                    {t(`${translationPath}why-aym-new`)}
                 
              {/* {t(`${translationPath}${why-aym-new}`)} */}

                  </Text>
                </div>

                <div className="css-1vzihco">
                  <Text
                    color="#757575"
                    fontSize="14px"
                    pl="2"
                    pr="2"
                    mt="2"
                    // ml={{base:"1%"}}
                    // w={{base: '80%'}}
                    className="css-1vzihco"
                    textAlign="center"
                    alignItems="center"
                    alignSelf="center"
                    mb='5'
                  >
                    {t(`${translationPath}why-aym-defen`)}
                    
                  </Text>
                </div>
              </Box>
            </Box>
          </div>
        </SimpleGrid>
      </Box>
    </div>
  );
};
