import React, { useState, useCallback, useEffect } from 'react';
import {
  Flex,
  Heading,
  Input,
  Select,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Grid,
  Text,
  Checkbox,
  SimpleGrid,
  GridItem,
  FormControl,
  FormHelperText,
  useToast,
  InputRightElement,
  Image,
  useDisclosure,
  Center,
} from '@chakra-ui/react';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';
import { useTranslation } from 'react-i18next';
import Country from '../../assets/Json/countries.json';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import LoginMob from '../../assets/images/images/loginMob.png';
import { CloseIcon, EmailIcon } from '@chakra-ui/icons';
import { signUp } from '../../services/signUp';
import { logIn } from '../../services/logIn';
import { forgotPassword } from '../../services/forgotPassword';
import { ResetPassword } from '../../services/resetPassword';
import Joi from 'joi-browser';
import { languageChange } from '../../helper/TranslateMethod.Helper';
import GA from 'react-ga';
import ReactGA from 'react-ga4';
import ReCAPTCHA from 'react-google-recaptcha';
import OTPImg from '../../assets/images/images/OTP.png';
import { auth } from '../../firebase.config';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { FaShieldAlt,  } from 'react-icons/fa';
// import './styledVerify.css';

import OtpInput from 'otp-input-react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const parentTranslationPath = 'Welcome';
const translationPath = '';
const Login = ({ onClose }) => {
  const { t } = useTranslation(parentTranslationPath);
  const [showPassword, setShowPassword] = useState(false);
  const [showSign, setShowSign] = useState('two');
  const handleShowClick = () => setShowPassword(!showPassword);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    reset: {
      username: '',
      password: '',
      confirmPassword: '',
      vCode: '',
    },
    error: {},
  });
  let lang = JSON.parse(localStorage.getItem('localization')) || 'en';
  const isLarger = window.matchMedia('(max-width: 62em)');
  const [value, setValue] = useState(false);
  const toast = useToast();
  const [loadingCode, setLoadingCode] = useState(false);
  const [errorReposneOTP, seterrorReposneOTP] = useState('');

  const [counter, setCounter] = useState(60);
  const [counterStart, setCounterStart] = useState(false);
  const {
    isOpen: isOpenOTP,
    onOpen: onOpenOTP,
    onClose: onCloseOTP,
  } = useDisclosure();

  const [error, setError] = useState({});
  const [forgot, setForgot] = useState({
    email: {
      email: '',
    },
    error: {},
  });
  const [state, setState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    mobile_number: '',
    password: '',
    confirmPassword: '',
    userName: '',
    extra: { country: '' },
  });
  const [logInState, setLogInState] = useState({
    login: { username: '', password: '' },
    error: {},
  });
  const logInschema = Joi.object({
    username: Joi.string().required().email().label('Username'),
    password: Joi.string().min(8).required().label('Password'),
  });

  const [valueButton, setValueButton] = useState('false');

  function onChange(value) {
    setValueButton(!valueButton);
  }

  const resetschema = Joi.object({
    password: Joi.string().min(8).required().label('Password'),
    confirmPassword: Joi.string()
      .min(8)
      .required()
      .regex(new RegExp(`^${resetPassword.reset.password}$`))
      .label('Confirm Password'),
    vCode: Joi.number().required().label('Code'),
    username: Joi.string().allow(''),
  });

  const forgotschema = Joi.object({
    email: Joi.string().required().email().label('Email'),
  });

  const schema = Joi.object({
    email: Joi.string().required().email().label('Email'),
    firstName: Joi.string().required().label('FirstName'),
    lastName: Joi.string().required().label('LastName'),
    mobile_number: Joi.number().required().label('Mobile Number'),
    password: Joi.string().min(8).required().label('Password'),
    confirmPassword: Joi.string()
      .min(8)
      .required()
      .regex(new RegExp(`^${state.password}$`))
      .label('Confirm Password'),
    userName: Joi.string().allow(''),
    extra: Joi.object().keys({
      country: Joi.string().required().label('Nationality'),
    }),
  });

  const validate = () => {
    const result = Joi.validate(state, schema, {
      abortEarly: false,
      allowUnknown: false,
    });
    if (!result.error) return null;
    const errors = {};

    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  const validateLogin = () => {
    const result = Joi.validate(logInState.login, logInschema, {
      abortEarly: false,
      allowUnknown: false,
    });
    if (!result.error) return null;
    const errors = {};

    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const validateForgot = (state, schema) => {
    const result = Joi.validate(state, schema, {
      abortEarly: false,
      allowUnknown: false,
    });
    if (!result.error) return null;
    const errors = {};

    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleSignUp = useCallback(
    async e => {
      e.preventDefault();
      const errors = validate();

      setError(errors || {});
      if (errors) {
        toast({
          title: t(`${translationPath}fix-error`),
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      } else {
        // if (
        //   user &&
        //   (errorReposneOTP === 'user already registered' ||
        //     errorReposneOTP === 'Something wrong')
        // ) {
        //   setLoading(true);
        //   const response = await signUp(state);
        //   if (response.data.status === 'success') {
        //     GA.event({
        //       category: 'User',
        //       action: 'Create AYMBot Account-UA',
        //     });
        //     ReactGA.event({
        //       category: 'User',
        //       action: 'Create AYMBot Account',
        //     });
        //     toast({
        //       title: t(`${translationPath}signUp-success`),
        //       position: 'top-right',
        //       isClosable: true,
        //       status: 'success',
        //     });
        //     onClose();
        //     onCloseOTP();
        //   } else {
        //     console.log('HHHHHHHH 3333333333');

        //     toast({
        //       title: t(
        //         `${translationPath}${
        //           response && response.data.result.errorReposne
        //         }`
        //       ),
        //       position: 'top-right',
        //       isClosable: true,
        //       status: 'error',
        //     });
        //   }
        //   setLoading(false);
        // } else {
        //   onOpenOTP();
        // }

        setLoading(true);
        const response = await signUp(state);
        if (response.data.status === 'success') {
          GA.event({
            category: 'User',
            action: 'Create AYMBot Account-UA',
          });
          ReactGA.event({
            category: 'User',
            action: 'Create AYMBot Account',
          });
          toast({
            title: t(`${translationPath}signUp-success`),
            position: 'top-right',
            isClosable: true,
            status: 'success',
          });
          onClose();
        } else {
          toast({
            title: t(
              `${translationPath}${
                response && response.data.result.errorReposne
              }`
            ),
            position: 'top-right',
            isClosable: true,
            status: 'error',
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, validate]
  );

  const handleLogIn = useCallback(
    async e => {
      e.preventDefault();
      const errors = validateLogin();
      setLogInState(prev => ({
        ...prev,
        error: errors,
      }));
      if (errors)
        toast({
          title: t(`${translationPath}fix-error`),
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      else {
        setLoading(true);
        const response = await logIn(logInState.login);
        if (response && response.data && response.data.accessToken !== null) {
          localStorage.setItem('token', response.data.accessToken);

          GA.event({
            category: 'User',
            action: 'Access AYMBot Account-UA',
          });
          ReactGA.event({
            category: 'User',
            action: 'Access AYMBot Account',
          });
          toast({
            title: t(`${translationPath}login-successfully`),
            position: 'top-right',
            isClosable: true,
            status: 'success',
          });
          window.location.href = '/admin/profile';
        } else {
          toast({
            title: t(
              `${translationPath}${response && response.data.failedLoginReason}`
            ),
            position: 'top-right',
            isClosable: true,
            status: 'error',
          });
        }
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logInState]
  );
  const handlelanguage = () => {
    if (lang.currentLanguage === 'ar') {
      languageChange('en');
    } else {
      languageChange('ar');
    }
  };

  const handleForgot = useCallback(
    async e => {
      e.preventDefault();
      const errors = validateForgot(forgot.email, forgotschema);
      setForgot(prev => ({
        ...prev,
        error: errors,
      }));
      if (errors)
        toast({
          title: t(`${translationPath}fix-error`),
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      else {
        setLoading(true);
        const response = await forgotPassword(forgot.email);
        if (response && response.data && response.data.status === 'success') {
          setNewPassword(true);
          GA.event({
            category: 'User',
            action: 'Send forgot password email-UA',
          });
          ReactGA.event({
            category: 'User',
            action: 'Send forgot password email ',
          });
          toast({
            title: t(`${translationPath}reset-email`),
            position: 'top-right',
            isClosable: true,
            status: 'success',
          });
        } else {
          toast({
            title: response.data.result.errorReposne,
            position: 'top-right',
            isClosable: true,
            status: 'error',
          });
        }
        setLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forgot.email]
  );

  const handleReset = useCallback(
    async e => {
      e.preventDefault();
      const errors = validateForgot(resetPassword.reset, resetschema);
      setResetPassword(prev => ({
        ...prev,
        error: errors,
      }));
      if (errors)
        toast({
          title: t(`${translationPath}fix-error`),
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      else {
        setLoading(true);
        e.preventDefault();
        const response = await ResetPassword(resetPassword.reset);
        if (response && response.data && response.data.status === 'success') {
          setShowSign('one');
          GA.event({
            category: 'User',
            action: 'Reset password-UA',
          });
          ReactGA.event({
            category: 'User',
            action: 'Reset tpassword',
          });
          setLoading(false);
          setNewPassword(false);
          toast({
            title: t(`${translationPath}Confirm-reset`),
            position: 'top-right',
            isClosable: true,
            status: 'success',
          });
        } else {
          toast({
            title: t(
              `${translationPath}${
                response && response.data && response.data.result.errorReposne
              }`
            ),
            position: 'top-right',
            isClosable: true,
            status: 'error',
          });
        }
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetPassword.reset]
  );

  const [otp, setOtp] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [showOTPError, setShowOTPError] = useState(false);
  const [otpTorun, setOTPToRun] = useState(false);
  useEffect(() => {
    if (counterStart === true) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, counterStart]);

 
  function onCaptchVerify() {
    ////////////////////////////////////////////
    console.log('onCaptchVerify ????????????');
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: response => {
            console.log(response, 'prepared phone auth process');
            if (counterStart === false) {
              // onSignupOtp();
              console.log('first',counterStart )

            } else {
              setCounterStart(true);
              setCounter(60);
            }
          },
          'expired-callback': () => {
            console.log('onCaptchVerify expired-callback ????????????');
          },
        },
        auth
      );
    }
    //////////////////////////////////////////////////////////////
  }

  const onSignupOtp = async () => {
    // setLoadingCode(true);
    onCaptchVerify();
    setCounterStart(true);

    console.log('state.mobile_number', state?.mobile_number);
    


    const appVerifier = window.recaptchaVerifier;

    const formatPh = '+' + state?.mobile_number;

    console.log('auth.auth', appVerifier);
    console.log('auth.auth', auth);

      // simulateSlowNetworkRequest().then(() => {
    await signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult;
        setShowOTP(true);
        setShowOTPError(true);
        console.log('0000000000000', window.confirmationResult);

        setOTPToRun(true);

        console.log(
          'OTP sended successfully!',
          window.confirmationResult,
          auth
        );

      })
      .catch(error => {
        setLoadingCode(true);
        setOTPToRun(true);

        console.log('first', showOTPError);

        console.log('2222222222', formatPh);
        console.log('33333333333', appVerifier);
        console.log('4444444444444', window.confirmationResult);
        console.log('55555555555', auth);
        console.log('66666666666', error);
        // console.log('66666666666', confirmationResult);



        console.log(
          'BBBBBBBB',
          !auth && !formatPh
        );
        // setInterval(greet, 1000)
        setTimeout(() => {
          if ( ( !auth && !formatPh) ) {
            console.log('eeeeeeeeeeeeeeeeeeeeeerrrr', error.message);
            if (
              error.message ===
              'Firebase: TOO_SHORT (auth/invalid-phone-number).'
            ) {
              toast({
                title: t(`${translationPath}TOO_SHORTMobilenumber`),
                position: 'top-right',
                isClosable: true,
                status: 'error',
              });
            } else if (
              error.message ===
              'Firebase: Exceeded quota. (auth/quota-exceeded).'
            ) {
              toast({
                title: t(`${translationPath}TOO_manyclicksbefore`),
                position: 'top-right',
                isClosable: true,
                status: 'error',
              });
            } else if (
              error.message === 'reCAPTCHA client element has been removed: 0'
            ) {
              // window.location.reload(false);
              console.log(
                'error.message reCAPTCHA reCAPTCHA reCAPTCHA',
                error.message
              );
            } else if (
              error.message === 'Firebase: Error (auth/too-many-requests).'
            ) {
              toast({
                title: t(`${translationPath}TOO_manyclicksbeforenewerror`),
                position: 'top-right',
                isClosable: true,
                status: 'error',
              });
            }
          }
        }, 1500);
        // grecaptcha.reset(window.recaptchaWidgetId);
        window.recaptchaVerifier.recaptcha.reset()
        window.recaptchaVerifier.clear()

      });
    setLoading(false);
 

    tryToFindWhite()
  };


  const tryToFindWhite = async() =>{

    if (
      otpTorun === false &&
      showOTP === false &&
      !window.confirmationResult &&
      otp === ''
    ) {
      const response = await signUp(state);
      if (response.data.status === 'success') {
        GA.event({
          category: 'User',
          action: 'Create AYMBot Account-UA',
        });
        ReactGA.event({
          category: 'User',
          action: 'Create AYMBot Account',
        });
        toast({
          title: t(`${translationPath}signUp-success`),
          position: 'top-right',
          isClosable: true,
          status: 'success',
        });
        onClose();
        onCloseOTP();
      }
    }

  }

  const handleOTPResend = () => {
    setShowOTP(false);
    onSignupOtp();
    setCounter(60);
  };

  function onOTPVerify() {
    // setCounter(60)
    // setCounterStart(true)
    setLoading(true);

    window.confirmationResult
      .confirm(otp)
      .then(async res => {
        console.log('onOTPVerify onOTPVerify onOTPVerify ');

        console.log(res);
        setUser(res.user);

        if (res.user) {
          setLoading(true);
          const response = await signUp(state);
          if (response.data.status === 'success') {
            GA.event({
              category: 'User',
              action: 'Create AYMBot Account-UA',
            });
            ReactGA.event({
              category: 'User',
              action: 'Create AYMBot Account',
            });
            toast({
              title: t(`${translationPath}signUp-success`),
              position: 'top-right',
              isClosable: true,
              status: 'success',
            });
            onClose();
            onCloseOTP();
          } else {
            console.log('HHHHHHHH 1111');
            console.log(
              'HHHHHHHH response.data.result.errorReposne',
              response.data.result.errorReposne
            );
            seterrorReposneOTP(response.data.result.errorReposne);

            toast({
              title: t(
                `${translationPath}${
                  response && response.data.result.errorReposne
                }`
              ),
              position: 'top-right',
              isClosable: true,
              status: 'error',
            });
          }
          setLoading(false);
        } else {
          console.log('HHHHHHHH 2222');
          toast({
            title: t(`${translationPath}wrong code`),
            position: 'top-right',
            isClosable: true,
            status: 'success',
          });
        }

        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        console.log(err.message);

        if (err.message === 'Network Error') {
          console.log(err.message);
        } else if (err) {
          toast({
            title: t(`${translationPath}Invalid Verification`),
            position: 'top-right',
            isClosable: true,
            status: 'error',
          });
        } else {
          console.log(err);
          console.log(err.message);
        }

        setLoading(false);
      });
  }

  return (
    <Grid
      borderRadius="7px"
      templateColumns={{ base: '1fr', lg: '1.6fr 1fr' }}
      templateRows={{ md: '1fr auto', lg: '1fr' }}
      bgColor="white"
    >
     <Modal isOpen={isOpenOTP} onClose={onCloseOTP} mx={'20px'} mt={'10%'}>
        <ModalOverlay />
        <ModalContent
          w={isMobile ? '95%' : '90%'}
          mt={isMobile ? '25%' : '6.5%'}
        >
          <ModalCloseButton />
          <ModalBody>
            <section className="bg-emerald-500 modalOTP flex items-center justify-center h-screen">
              <Box>
                <Image
                  width={'90%'}
                  objectFit="contain"
                  src={OTPImg}
                  alt={'alt'}
                  backgroundSize="cover"
                  size="200px"
                  m="auto"
                  pt="5"
                  // width="80%"
                />
              </Box>
              <div>
                {/* <Toaster toastOptions={{ duration: 4000 }} /> */}

                {user ? (
                  <Box my={5}>
                    <h2 className="text-center text-white font-medium text-2xl">
                      {t(`${translationPath}LoginSuccess`)}
                    </h2>
                  </Box>
                ) : (
                  <div
                    className="w-80 flex flex-col gap-4 rounded-lg p-4"
                    style={{ textAlign: '-webkit-center' }}
                  >
                    {showOTP ? (
                      <>
                        <Box mb={5}>
                          <FaShieldAlt size={30} />
                          <Text htmlFor="otp">
                            {t(`${translationPath}EnteryourOTP`)}
                          </Text>
                        </Box>

                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          autoFocus
                          inputStyle="inputStyle"
                          separator={<span></span>}
                          style={{
                            color: '#000',
                            placeContent: 'end',
                            marginTop: '5px',
                            marginBottom: '5px',
                          }}
                        ></OtpInput>

                        <button onClick={onOTPVerify} className="button" my={2}>
                          <span>{t(`${translationPath}VerifyOTP`)}</span>
                        </button>

                        <Box flex={'column'} my={2}>
                          {counter > 0 && counter < 60 ? (
                            lang.currentLanguage === 'en' ? (
                              <Text color="fff" style={{}}>
                                You can request a new code in {counter} seconds
                              </Text>
                            ) : (
                              <Text color="fff" style={{}}>
                                يمكنك طلب رمز تحقق جديد بعد {counter} ثانية
                              </Text>
                            )
                          ) : (
                            <button
                              onClick={handleOTPResend}
                              className="button"
                            >
                              <span> {t(`${translationPath}Sendcode`)}</span>
                            </button>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box>
                          <Center>
                            <Box>
                              <Text>
                                {t(`${translationPath}wehavesend`)}{' '}
                                <Text
                                  style={{
                                    color: '#4bd1c6',
                                    fontSize: isMobile ? '' : '20px',
                                  }}
                                >
                                  {state?.mobile_number}
                                </Text>
                              </Text>
                            </Box>
                          </Center>
                        </Box>
                        <Center>
                          <Box>
                            {counter > 0 && counter < 60 ? (
                              lang.currentLanguage === 'en' ? (
                                <Text color="fff" style={{}}>
                                  You can request a new code in {counter}{' '}
                                  seconds
                                </Text>
                              ) : (
                                <Text color="fff" style={{}}>
                                  يمكنك طلب رمز تحقق جديد بعد {counter} ثانية
                                </Text>
                              )
                            ) : (
                              <button onClick={onSignupOtp} className="button">
                                <span> {t(`${translationPath}Sendcode`)}</span>
                              </button>
                            )}
                          </Box>
                        </Center>
                      </>
                    )}
                  </div>
                )}
              </div>
            </section>
          </ModalBody>
        </ModalContent>
      </Modal>
      <GridItem display={{ base: 'none', lg: 'flex' }} borderRadius="7px">
        <SimpleGrid
          width="100%"
          display={{ sm: 'block', md: 'block', lg: 'block', xl: 'flex' }}
        >
          {lang.currentLanguage === 'en' ? (
            <iframe
              width="100%"
              height="100%"
              title="video"
              frameBorder="0"
              allow="accelerometer"
              src="https://www.youtube.com/embed/k-jk3S4mOKo"
            ></iframe>
          ) : (
            <iframe
              width="100%"
              height="100%"
              title="video"
              frameBorder="0"
              allow="accelerometer"
              src="https://www.youtube.com/embed/7MI5PUS77hQ"
            ></iframe>
          )}
        </SimpleGrid>
      </GridItem>

      <GridItem backgroundColor={{ base: 'white' }}>
        <SimpleGrid h="auto" my="8" display="contents">
          <Box
            w={'100%'}
            m="auto"
            mt="4"
            display="flex"
            justifyContent="space-around"
          >
            <Heading color="teal.400">{t(`${translationPath}Hello`)}</Heading>
            <Button
              bg="white"
              color="black"
              onClick={handlelanguage}
              variant="ghost"
            >
              {t(`${translationPath}ln`)}
            </Button>
            <Button
              bg="white"
              color="black"
              leftIcon={<CloseIcon />}
              onClick={onClose}
              variant="ghost"
            ></Button>
          </Box>

          {(() => {
            switch (showSign) {
              case 'one':
                return (
                  <Flex
                    backgroundImage={isLarger.matches ? LoginMob : null}
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    height="600px"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Stack
                      flexDir="column"
                      mb="2"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        width="100%"
                        m="auto"
                        display={{ lg: 'none', base: 'block' }}
                      >
                        {lang.currentLanguage === 'en' ? (
                          <iframe
                            width="100%"
                            height="100%"
                            title="video"
                            frameBorder="0"
                            allow="accelerometer"
                            src="https://www.youtube.com/embed/k-jk3S4mOKo"
                          ></iframe>
                        ) : (
                          <iframe
                            width="100%"
                            height="100%"
                            title="video"
                            frameBorder="0"
                            allow="accelerometer"
                            src="https://www.youtube.com/embed/7MI5PUS77hQ"
                          ></iframe>
                        )}
                      </Box>
                      <Box m="auto">
                        <form>
                          <Stack
                            spacing={4}
                            p="1rem"
                            mt="4"
                            backgroundColor="whiteAlpha.900"
                            boxShadow="md"
                            borderRadius="7px"
                          >
                            <Box justifyContent="center" display="flex">
                              <Text color="black">
                                {' '}
                                {t(`${translationPath}Access`)}{' '}
                              </Text>{' '}
                              <Text mx="1" color="teal.500" fontSize="lg">
                                {' '}
                                {t(`${translationPath}AYMBot`)}{' '}
                              </Text>
                              <Text color="black">
                                {t(`${translationPath}account`)}{' '}
                              </Text>
                            </Box>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                )}

                                <Input
                                  // autoComplete="on"
                                  value={logInState.login.username}
                                  color="black"
                                  borderColor="black"
                                  variant="flushed"
                                  _placeholder={{ color: 'black' }}
                                  type="username"
                                  placeholder={t(`${translationPath}email`)}
                                  onChange={event => {
                                    setLogInState(prev => ({
                                      ...prev,
                                      login: {
                                        ...prev.login,
                                        username: event.target.value,
                                      },
                                    }));
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem"></InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem"></InputLeftElement>
                                )}
                              </InputGroup>
                              {logInState.error &&
                                logInState.error.username && (
                                  <FormHelperText color="red">
                                    {t(`${translationPath}email-error`)}
                                  </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                )}

                                <Input
                                  // autoComplete="on"
                                  value={logInState.login.password}
                                  color="black"
                                  borderColor="black"
                                  variant="flushed"
                                  type={showPassword ? 'text' : 'password'}
                                  placeholder={t(`${translationPath}password`)}
                                  _placeholder={{ color: 'black' }}
                                  onChange={event => {
                                    setLogInState(prev => ({
                                      ...prev,
                                      login: {
                                        ...prev.login,
                                        password: event.target.value,
                                      },
                                    }));
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      color="#A0AEC0"
                                      h="1.75rem"
                                      size="sm"
                                      onClick={handleShowClick}
                                    >
                                      {showPassword
                                        ? t(`${translationPath}Hide`)
                                        : t(`${translationPath}Show`)}
                                    </Button>
                                  </InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem">
                                    <Button
                                      color="#A0AEC0"
                                      h="1.75rem"
                                      size="sm"
                                      onClick={handleShowClick}
                                    >
                                      {showPassword
                                        ? t(`${translationPath}Hide`)
                                        : t(`${translationPath}Show`)}
                                    </Button>
                                  </InputLeftElement>
                                )}
                              </InputGroup>
                              {logInState.error &&
                                logInState.error.password && (
                                  <FormHelperText color="red">
                                    {t(`${translationPath}password-error`)}
                                  </FormHelperText>
                                )}
                              <Box textAlign="right">
                                <Button
                                  fontSize="sm"
                                  colorScheme="teal"
                                  variant="link"
                                  onClick={() => {
                                    setShowSign('three');
                                    setNewPassword(false);
                                    setResetPassword(prev => ({
                                      ...prev,
                                      reset: {
                                        ...prev.reset,
                                        vCode: '',
                                        password: '',
                                        confirmPassword: '',
                                      },
                                    }));
                                  }}
                                >
                                  {t(`${translationPath}forgot`)}
                                </Button>
                              </Box>
                            </FormControl>
                            <Button
                              isLoading={loading}
                              borderRadius="12px"
                              variant="solid"
                              colorScheme="teal"
                              width="full"
                              type="submit"
                              onClick={handleLogIn}
                            >
                              {t(`${translationPath}login`)}
                            </Button>
                            <Box color="teal.500">
                              {t(`${translationPath}new`)}
                              <Button
                                borderColor="none"
                                color="teal.500"
                                variant="link"
                                mx="2"
                                onClick={() => setShowSign('two')}
                              >
                                {t(`${translationPath}Sign-Up`)}
                              </Button>
                            </Box>
                          </Stack>
                        </form>
                      </Box>
                    </Stack>
                  </Flex>
                );
              case 'two':
                return (
                  <Flex
                    backgroundImage={isLarger.matches ? LoginMob : null}
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    height="auto"
                    flexDirection="column"
                    backgroundColor={'white'}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Stack
                      flexDir="column"
                      mb="2"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box m="auto">
                        <form>
                          <Stack
                            spacing={4}
                            p="1rem"
                            mt="4"
                            backgroundColor="whiteAlpha.900"
                            boxShadow="md"
                            borderRadius="7px"
                          >
                            <Box color="teal.500">
                              {t(`${translationPath}have`)}
                              <Button
                                borderColor="none"
                                color="teal.500"
                                variant="link"
                                onClick={() => setShowSign('one')}
                              >
                                {t(`${translationPath}to-login`)}
                              </Button>
                            </Box>
                            <Box
                              width="100%"
                              m="auto"
                              display={{ lg: 'none', base: 'block' }}
                            >
                              {lang.currentLanguage === 'en' ? (
                                <iframe
                                  width="100%"
                                  height="100%"
                                  title="video"
                                  frameBorder="0"
                                  allow="accelerometer"
                                  src="https://www.youtube.com/embed/k-jk3S4mOKo"
                                ></iframe>
                              ) : (
                                <iframe
                                  width="100%"
                                  height="100%"
                                  title="video"
                                  frameBorder="0"
                                  allow="accelerometer"
                                  src="https://www.youtube.com/embed/7MI5PUS77hQ"
                                ></iframe>
                              )}
                            </Box>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<EmailIcon color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<EmailIcon color="#A0AEC0" />}
                                  />
                                )}
                                <Input
                                  // autoComplete="on"
                                  color="black"
                                  value={state.email}
                                  borderColor="black"
                                  variant="flushed"
                                  _placeholder={{ color: 'black' }}
                                  type="email"
                                  placeholder={t(`${translationPath}email`)}
                                  onChange={event => {
                                    setState({
                                      ...state,
                                      email: event.target.value,
                                      userName: event.target.value,
                                    });
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem"></InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem"></InputLeftElement>
                                )}
                              </InputGroup>
                              {error.email && (
                                <FormHelperText color="red">
                                  {t(`${translationPath}email-error`)}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                )}
                                <Input
                                  value={state.firstName}
                                  color="black"
                                  borderColor="black"
                                  variant="flushed"
                                  _placeholder={{ color: 'black' }}
                                  type="name"
                                  placeholder={t(`${translationPath}f-name`)}
                                  onChange={event => {
                                    setState({
                                      ...state,
                                      firstName: event.target.value,
                                    });
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem"></InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem"></InputLeftElement>
                                )}
                              </InputGroup>
                              {error.firstName && (
                                <FormHelperText color="red">
                                  {t(`${translationPath}f-name-error`)}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                )}
                                <Input
                                  color="black"
                                  borderColor="black"
                                  value={state.lastName}
                                  variant="flushed"
                                  _placeholder={{ color: 'black' }}
                                  type="name"
                                  placeholder={t(`${translationPath}l-name`)}
                                  onChange={event => {
                                    setState({
                                      ...state,
                                      lastName: event.target.value,
                                    });
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem"></InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem"></InputLeftElement>
                                )}
                              </InputGroup>
                              {error.lastName && (
                                <FormHelperText color="red">
                                  {t(`${translationPath}l-name-error`)}
                                </FormHelperText>
                              )}
                            </FormControl>

                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                )}
                                <Input
                                  color="black"
                                  borderColor="black"
                                  value={state.password}
                                  variant="flushed"
                                  type={showPassword ? 'text' : 'password'}
                                  placeholder={t(`${translationPath}password`)}
                                  _placeholder={{ color: 'black' }}
                                  onChange={event => {
                                    setState({
                                      ...state,
                                      password: event.target.value,
                                    });
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      position="static"
                                      color="#A0AEC0"
                                      h="1.75rem"
                                      size="sm"
                                      onClick={handleShowClick}
                                    >
                                      {showPassword
                                        ? t(`${translationPath}Hide`)
                                        : t(`${translationPath}Show`)}
                                    </Button>
                                  </InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem">
                                    <Button
                                      position="static"
                                      color="#A0AEC0"
                                      h="1.75rem"
                                      size="sm"
                                      onClick={handleShowClick}
                                    >
                                      {showPassword
                                        ? t(`${translationPath}Hide`)
                                        : t(`${translationPath}Show`)}
                                    </Button>
                                  </InputLeftElement>
                                )}
                              </InputGroup>
                              {error.password && (
                                <FormHelperText color="red">
                                  {t(`${translationPath}sign-password-error`)}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                )}
                                <Input
                                  // autoComplete="on"
                                  color="black"
                                  borderColor="black"
                                  variant="flushed"
                                  value={state.confirmPassword}
                                  type={showPassword ? 'text' : 'password'}
                                  placeholder={t(
                                    `${translationPath}c-Password`
                                  )}
                                  _placeholder={{ color: 'black' }}
                                  onChange={event => {
                                    setState({
                                      ...state,
                                      confirmPassword: event.target.value,
                                    });
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem"></InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem"></InputLeftElement>
                                )}
                              </InputGroup>
                              {error.confirmPassword && (
                                <FormHelperText color="red">
                                  {t(`${translationPath}c-Password-message`)}
                                </FormHelperText>
                              )}
                            </FormControl>

                            <FormControl id="country">
                              <InputGroup>
                                <Select
                                  borderColor="black"
                                  color="black"
                                  placeholder={t(`${translationPath}country`)}
                                  backgroundColor="white"
                                  background="white"
                                  value={state.extra.country}
                                  variant="flushed"
                                  onChange={event => {
                                    setState({
                                      ...state,
                                      extra: { country: event.target.value },
                                    });
                                  }}
                                >
                                  {Country.map((item, index) => (
                                    <option value={item} key={index}>
                                      {item}
                                    </option>
                                  ))}
                                </Select>
                              </InputGroup>
                              {error.extra && (
                                <FormHelperText color="red">
                                  {t(`${translationPath}country-error`)}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <FormControl>
                              <InputGroup>
                                <PhoneInput
                                  inputProps={{
                                    name: 'phone',
                                  }}
                                  specialLabel={t(
                                    `${translationPath}phone-number`
                                  )}
                                  containerStyle={{ variant: 'flushed' }}
                                  excludeCountries={['il']}
                                  inputStyle={{
                                    color: 'black',
                                    variant: 'flushed',
                                  }}
                                  dropdownStyle={{ color: 'black' }}
                                  variant="outlined"
                                  country={'us'}
                                  value={state.mobile_number}
                                  onChange={value => {
                                    setState({
                                      ...state,
                                      mobile_number: value,
                                    });
                                  }}
                                />
                              </InputGroup>
                              {error.mobile_number && (
                                <FormHelperText color="red">
                                  {t(`${translationPath}number-error`)}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <FormControl>
                              {/* <Box
                          display={'flex'}
                          className="horizantalGoogleloginH"
                        >
                          <Button
                            className="gog-btn"
                            // color={'red'}
                            onClick={() => loginG()}
                          >
                            <FcGoogle style={{ color: '', fontSize: '25px' }} />
                            <Text className="googlenewbutton">
                              Login with Google
                            </Text>
                          </Button>
                        </Box> */}
                              <Checkbox
                                borderColor="gray"
                                color="black"
                                value="naruto"
                                onChange={event => {
                                  setValue(!value);
                                }}
                              >
                                <Text
                                  color="#151C2F"
                                  fontSize="sm"
                                  textAlign="center"
                                  display="flex"
                                >
                                  <Text mx="1" color="#151C2F">
                                    {' '}
                                    {t(`${translationPath}agree`)}
                                  </Text>

                                  <Link
                                    href="http://blog.aymbot.com/en/aymbot-service-subscription-agreement-terms-and-conditions/"
                                    target="_blank"
                                    color="teal.500"
                                    to="/home"
                                  >
                                    {t(`${translationPath}terms`)}
                                  </Link>
                                </Text>
                              </Checkbox>
                              {value === true ? (
                                <ReCAPTCHA
                                  sitekey={
                                    '6LfkpXAlAAAAAKMV67tXy3oj2dqY7ykzLy-Z9273'
                                  }
                                  onChange={onChange}
                                />
                              ) : null}
                            </FormControl>
                            <Button
                              isDisabled={valueButton}
                              isLoading={loading}
                              borderRadius="12px"
                              variant="solid"
                              colorScheme="teal"
                              width="full"
                              type="submit"
                              onClick={handleSignUp}
                            >
                              {t(`${translationPath}Register`)}
                            </Button>
                            <Box color="teal.500">
                              {t(`${translationPath}have`)}
                              <Button
                                borderColor="none"
                                color="teal.500"
                                variant="link"
                                onClick={() => setShowSign('one')}
                              >
                                {t(`${translationPath}to-login`)}
                              </Button>
                            </Box>
                          </Stack>
                        </form>
                      </Box>
                    </Stack>
                  </Flex>
                );
              case 'three':
                return !newPassword ? (
                  <Flex
                    backgroundImage={isLarger.matches ? LoginMob : null}
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                    backgroundSize="cover"
                    height="600px"
                    flexDirection="column"
                    backgroundColor={'white'}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Stack
                      flexDir="column"
                      mb="2"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box m="auto">
                        <form>
                          <Stack
                            spacing={4}
                            p="1rem"
                            backgroundColor="whiteAlpha.900"
                            boxShadow="md"
                            mt="4"
                            borderRadius="7px"
                          >
                            <Box justifyContent="center" display="flex">
                              <Text m="2px" color="teal.500" fontSize="lg">
                                {t(`${translationPath}Reset`)}
                              </Text>{' '}
                            </Box>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                )}
                                <Input
                                  // autoComplete="on"
                                  value={forgot.email.email}
                                  color="black"
                                  borderColor="black"
                                  variant="flushed"
                                  _placeholder={{ color: 'black' }}
                                  type="email"
                                  placeholder={t(`${translationPath}email`)}
                                  onChange={event => {
                                    setResetPassword(prev => ({
                                      ...prev,
                                      reset: {
                                        ...prev.reset,
                                        username: event.target.value,
                                      },
                                    }));
                                    setForgot(prev => ({
                                      ...prev,
                                      email: { email: event.target.value },
                                    }));
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem"></InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem"></InputLeftElement>
                                )}
                              </InputGroup>
                              {forgot.error && forgot.error.email && (
                                <FormHelperText color="red">
                                  {t(`${translationPath}email-error`)}
                                </FormHelperText>
                              )}
                            </FormControl>

                            <Button
                              isLoading={loading}
                              borderRadius={0}
                              variant="solid"
                              colorScheme="teal"
                              width="full"
                              type="submit"
                              onClick={handleForgot}
                            >
                              {t(`${translationPath}Reset`)}
                            </Button>
                            <Box color="teal.500">
                              <Button
                                borderColor="none"
                                color="teal.500"
                                variant="link"
                                onClick={() => setShowSign('one')}
                              >
                                {t(`${translationPath}back-Login`)}
                              </Button>
                            </Box>
                          </Stack>
                        </form>
                      </Box>
                    </Stack>
                  </Flex>
                ) : (
                  <Flex
                    height="600px"
                    flexDirection="column"
                    backgroundColor={'white'}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Stack
                      flexDir="column"
                      mb="2"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box m="auto">
                        <form>
                          <Stack
                            spacing={4}
                            p="1rem"
                            backgroundColor="whiteAlpha.900"
                            boxShadow="md"
                            mt="4"
                            borderRadius="7px"
                          >
                            <Box justifyContent="center" display="flex">
                              <Text m="2px" color="teal.500" fontSize="lg">
                                {t(`${translationPath}new-Password`)}
                              </Text>{' '}
                            </Box>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaUserAlt color="#A0AEC0" />}
                                  />
                                )}
                                <Input
                                  // autoComplete="on"
                                  value={resetPassword.reset.vCode}
                                  color="black"
                                  borderColor="black"
                                  variant="flushed"
                                  _placeholder={{ color: 'black' }}
                                  type="number"
                                  placeholder={t(
                                    `${translationPath}verification`
                                  )}
                                  onChange={event => {
                                    setResetPassword(prev => ({
                                      ...prev,
                                      reset: {
                                        ...prev.reset,
                                        vCode: event.target.value,
                                      },
                                    }));
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem"></InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem"></InputLeftElement>
                                )}
                              </InputGroup>

                              {resetPassword.error &&
                                resetPassword.error.vCode && (
                                  <FormHelperText color="red">
                                    {t(`${translationPath}v-code-error`)}
                                  </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                )}
                                <Input
                                  value={resetPassword.reset.password}
                                  color="black"
                                  borderColor="black"
                                  variant="flushed"
                                  _placeholder={{ color: 'black' }}
                                  type={showPassword ? 'text' : 'password'}
                                  placeholder={t(
                                    `${translationPath}n-password`
                                  )}
                                  onChange={event => {
                                    setResetPassword(prev => ({
                                      ...prev,
                                      reset: {
                                        ...prev.reset,
                                        password: event.target.value,
                                      },
                                    }));
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem">
                                    <Button
                                      color="#A0AEC0"
                                      h="1.75rem"
                                      size="sm"
                                      onClick={handleShowClick}
                                    >
                                      {showPassword
                                        ? t(`${translationPath}Hide`)
                                        : t(`${translationPath}Show`)}
                                    </Button>
                                  </InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem">
                                    <Button
                                      color="#A0AEC0"
                                      h="1.75rem"
                                      size="sm"
                                      onClick={handleShowClick}
                                    >
                                      {showPassword
                                        ? t(`${translationPath}Hide`)
                                        : t(`${translationPath}Show`)}
                                    </Button>
                                  </InputLeftElement>
                                )}
                              </InputGroup>
                              {resetPassword.error &&
                                resetPassword.error.password && (
                                  <FormHelperText color="red">
                                    {t(`${translationPath}sign-password-error`)}
                                  </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl>
                              <InputGroup>
                                {lang.currentLanguage === 'en' ? (
                                  <InputLeftElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                ) : (
                                  <InputRightElement
                                    pointerEvents="none"
                                    color="#A0AEC0"
                                    children={<CFaLock color="#A0AEC0" />}
                                  />
                                )}
                                <Input
                                  // autoComplete="on"
                                  value={resetPassword.reset.confirmPassword}
                                  color="black"
                                  borderColor="black"
                                  variant="flushed"
                                  _placeholder={{ color: 'black' }}
                                  type={showPassword ? 'text' : 'password'}
                                  placeholder={t(
                                    `${translationPath}c-password`
                                  )}
                                  onChange={event => {
                                    setResetPassword(prev => ({
                                      ...prev,
                                      reset: {
                                        ...prev.reset,
                                        confirmPassword: event.target.value,
                                      },
                                    }));
                                  }}
                                />
                                {lang.currentLanguage === 'en' ? (
                                  <InputRightElement width="4.5rem"></InputRightElement>
                                ) : (
                                  <InputLeftElement width="4.5rem"></InputLeftElement>
                                )}
                              </InputGroup>
                              {resetPassword.error &&
                                resetPassword.error.confirmPassword && (
                                  <FormHelperText color="red">
                                    {t(`${translationPath}c-Password-message`)}
                                  </FormHelperText>
                                )}
                            </FormControl>

                            <Button
                              isLoading={loading}
                              borderRadius={0}
                              variant="solid"
                              colorScheme="teal"
                              width="full"
                              type="submit"
                              onClick={handleReset}
                            >
                              {t(`${translationPath}Submit`)}
                            </Button>
                            <Box color="teal.500">
                              <Button
                                borderColor="none"
                                color="teal.500"
                                variant="link"
                                onClick={() => setShowSign('one')}
                              >
                                {t(`${translationPath}back-Login`)}
                              </Button>
                            </Box>
                          </Stack>
                        </form>
                      </Box>
                    </Stack>
                  </Flex>
                );
              case 'four':
                return <Box></Box>;
              default:
                return null;
            }
          })()}
        </SimpleGrid>
      </GridItem>
    </Grid>
  );
};

export default Login;
