export const barChartData = [
  {
    name: "Sales",
    data: [330, 250, 110, 300, 490, 350, 270, 130, 425,],
  },
];

export const barChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    style: {
      backgroundColor: "red",
      fontSize: "12px",
      fontFamily: undefined,
    },
    onDatasetHover: {
      style: {
        backgroundColor: "red",
        fontSize: "12px",
        fontFamily: undefined,
      },
    },
    theme: "dark",
  },
  xaxis: {
    categories: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    show: false,
    labels: {
      show: false,
      style: {
        colors: "#fff",
        fontSize: "12px",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    color: "#fff",
    labels: {
      show: true,
      style: {
        colors: "#fff",
        fontSize: "14px",
      },
    },
  },
  grid: {
    show: false,
  },
  fill: {
    colors: "#fff",
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      columnWidth: "12px",
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
          },
        },
      },
    },
  ],
};



export const lineChartOptions = {
  options: { scales: {
    y: {
        suggestedMin: 50,
        suggestedMax: 100
    }
}},
  chart: {
    toolbar: {
      show: false,
    },
  
    type: 'line',
    zoom: {
      enabled: false
    }
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: 'category',
    categories: ['Month 1','2','3','4','5','6','7','8','9','10','11','12','Year 2','3','4','  5'],
    tickAmount: undefined,
    tickPlacement: 'between',
    min: undefined,
    max: undefined,
    range: undefined,
    floating: false,
    decimalsInFloat: undefined,
    overwriteCategories: undefined,
    position: 'bottom',
    labels: {
        show: true,
        rotate:-35,
        rotateAlways: true,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 150,
        style: {
          colors: "#757575",
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 200,
            cssClass: 'apexcharts-xaxis-label',
        },
        offsetX: 0,
        offsetY: 20,
        format: undefined,
        formatter: undefined,
        datetimeUTC: true,
        datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
        },
    },
    axisBorder: {
        show: false,
        color: '#78909C',
        height: 100,
        width: '100%',
        offsetX: 0,
        offsetY: 0
    },
    axisTicks: {
        show: false,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0
    },
   
    title: {
        text:'',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
        },
    },
    crosshairs: {
        show: true,
        width: 1,
        position: 'back',
        opacity: 0.9,        
        stroke: {
            color: '#b6b6b6',
            width: 0,
            dashArray: 0,
        },
        fill: {
            type: 'solid',
            color: '#B1B9C4',
            gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.1,
            },
        },
        dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 1,
            opacity: 0.4,
        },
    },
    tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: 0,
          fontFamily: 0,
        },
    },
},

  yaxis: {
    forceNiceScale:false,
    labels: {
      offsetX: -15,
      offsetY: 0,
      align: 'right',
      minWidth: 0,
          maxWidth: 160,
      formatter: function (y) {
        if(typeof y !== "undefined") {
          return  y.toFixed(0);
        }
        return y;
      },
      style: {
        colors: "#757575",
        fontSize: "14px",
      },
    },
  },
  legend: {
    floating: false,
    show: true,
    itemMargin: {
      horizontal: 10,
      vertical:10
  },
  markers: {
    width: 12,
    height: 12,
    strokeWidth: 2,
    strokeColor: '#fff',
    fillColors: undefined,
    radius: 12,
    customHTML: undefined,
    onClick: undefined,
    offsetX: 2,
    offsetY: 0
},
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "horizontal",
      shadeIntensity: 0.1,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: false,
      opacityFrom: 0,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#4FD1C5", "#2D3748","#6B4F9A","#ED64A6","#FFD700"],
  },
  colors: ["#4FD1C5", "#2D3748","#6B4F9A","#ED64A6","#FFD700"],
};
