import {
  Box,
  Button,
  Text,
  Heading,
  Stack,
  SimpleGrid,
  GridItem,
  Grid,
  useDisclosure,
  FormControl,
  FormHelperText,
  useToast,
  Input,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useCallback, useState } from 'react';
// import Login from '../../Layout/lo'
import GA from 'react-ga';
import ReactGA from 'react-ga4';
import Joi from 'joi-browser';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import roya from '../../assets/images/images/Roya.png';
import eh from '../../assets/images/images/eh.png';
import an from '../../assets/images/images/an.png';
import market from '../../assets/images/images/Market.png';
import times from '../../assets/images/images/times.png';
import fanpin5 from '../../assets/images/images/panfan11.png';
import fanpin6 from '../../assets/images/images/panfan12.png';

import newbinanceimage from '../../assets/images/images/newbinanceimage.png';
import VidHome from '../../assets/images/images/VidHome.mp4';
import pdfFile from '../../assets/images/images/RGBAYMBot®TermSheetv122.08.2023.pdf';

import { ContactUs } from '../../services/ContactUs/ContactUs';
import { useHistory } from 'react-router-dom';
import { Welcome } from './../../ReusableComponent/WelcomeVideo/Welcome';
import lottie from 'lottie-web';
import { isMobile } from 'react-device-detect';
const parentTranslationPath = 'Home';
const translationPath = '';



export const Home = ({ HomeRef }) => {
  let lang = JSON.parse(localStorage.getItem('localization'));
  const { t } = useTranslation(parentTranslationPath);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const container = useRef();
  let token = localStorage.getItem('token');
  const handleOpen = () => {
    if (!token || token === 'null') {
      onOpen();
    } else {
      // onOpen();

      window.location.href = '/admin/profile';
    }
  };

  // const StoryModeOpen = () => {
  //    window.open("/StoryMode", "_blank", "toolbar=0,location=0,menubar=0");

  //   window.open('/DownloadReport', '_blank');
  // };

  const history = useHistory();
  useEffect(() => {
    if (!token || token === 'null') onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoPlay: true,
      animationData: require('../../assets/images/images/lf30new.json'),
    });
  }, []);

  // const handleContactUs = () => {
  //   history.push(`/ContactUs`);
  // };

  // const handleFaq = () => {
  //   history.push(`/FAQ`);
  // };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", paddingTop:'2px' }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", paddingTop:'2px' }}
        onClick={onClick}
      />
    );
  }

  
  var settings = {
    dots: false,
    slidesToShow: 5,
  };
  var settings2 = {
    dots: false,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      
    ],

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  const download1test = (filename, url) => {
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    link.rel = 'noopener';

    link.click();
    link.remove();
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  const downloadtest2 = async (filename, url) => {
    const chunks = [];
    const res = await fetch(url);
    const reader = res.body.getReader();
    const readChunk = ({ done, value: chunk }) => {
      if (done) {
        const blob = new Blob(chunks, {
          type: 'application/octet-stream; charset=utf-8',
        });
        download1test(filename, URL.createObjectURL(blob));
        return;
      }
      chunks.push(chunk);
      reader.read().then(readChunk);
    };
    reader.read().then(readChunk);
  };

  const lorm =
    'Lorem ipsum dolor sitamet, conse ctetuer adipiscing elit, sed diam ';
  const images = [
    { img: market, title: lorm },
    { img: an, title: lorm },
    { img: roya, title: lorm },
    { img: times, title: lorm },
    { img: eh, title: lorm },
    { img: fanpin5, title: lorm },
    { img: fanpin6, title: lorm },
  ];

  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [state, setState] = useState({
    message: {
      name: '',
      email: '',
      subject: 'heroshot',
      phone_number: '',
      message: 'make a call',
    },
    error: {},
  });
  const schema = Joi.object({
    name: Joi.string().required().label('Name'),
    email: Joi.string().required().email().label('email'),
    phone_number: Joi.number().required().label('Mobile Number'),
    subject: Joi.string().required().label('subject'),
    message: Joi.string().required().label('Message'),
  });

  const validate = () => {
    const result = Joi.validate(state.message, schema, {
      abortEarly: false,
      allowUnknown: false,
    });
    if (!result.error) return null;
    const errors = {};

    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const handleContactUsSS = useCallback(
    async e => {
      e.preventDefault();
      const errors = validate();
      setState(prev => ({
        ...prev,
        error: errors,
      }));
      if (errors && errors) {
        console.log('errors errors', errors);
        toast({
          title: t(`${translationPath}fix-error`),
          position: 'top-right',
          isClosable: true,
          status: 'error',
        });
      } else {
        setLoading(true);
        const response = await ContactUs(state.message);
        if (response && response.data && response.data.accessToken !== null) {
          GA.event({
            category: 'User',
            action: 'Contact us-UA',
          });
          ReactGA.event({
            category: 'User',
            action: 'Contact us',
          });
          toast({
            title: t(`${translationPath}sent-successfully`),
            position: 'top-right',
            isClosable: true,
            status: 'success',
          });
          setState(prev => ({
            ...prev,
            message: {
              ...prev.message,
              name: '',
              email: '',
              subject: 'heroshot',
              phone_number: '',
              message: 'make a call',
            },
          }));
          onClose();
        } else {
          toast({
            title: t(`${translationPath}somthing-wrong`),
            position: 'top-right',
            isClosable: true,
            status: 'error',
          });
        }
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    <div ref={HomeRef}>
      <Welcome isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Grid
        h={{ base: '98vh+150px', lg: '98vh' }}
        templateColumns={{ md: '1fr', lg: '1fr' }}
        templateRows={{ md: '1fr auto', lg: '1fr' }}
      >
        <GridItem margin="auto">
          <SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }}>
            <Stack alignItems="center">
              <video
                autoPlay
                loop
                muted
                style={{
                  position: 'absolute',
                  width: '100%',
                  left: '50%',
                  top: '50%',
                  height: isMobile ? '180%' : '110%',
                  objectFit: 'cover',
                  transform: 'translate(-50%,-48%)',
                  zIndex: '-1',
                }}
              >
                <source src={VidHome} type="video/mp4" />
              </video>
              <Box w="100%" textAlign="-webkit-center" mb="10" mt={'-10%'}>
                <Box m="auto" w={{ base: '100%' }} textAlign="-webkit-center">
                  <Box
                    justifyContent={{
                      base: 'center',
                      lg: lang.currentLanguage === 'en' ? 'center' : 'center',
                    }}
                    w="100%"
                    m="auto"
                    display={{ base: 'block' }}
                  >
                    <Text
                      m="2px"
                      color="#4FD1C5"
                      fontSize={{ base: '3xl', lg: '6xl', xl: '7xl' }}
                      fontWeight="bold"
                    >
                      {t(`${translationPath}AYMBot®`)}
                    </Text>
                    <Box
                      width={{ base: '280px', sm: '280px' }}
                      m={{ base: 'auto', lg: '0' }}
                      style={{ justifyContent: 'center', textAlign: 'center' }}
                    >
                      <div
                        style={{
                          justifyContent: 'center',
                          textAlign: '-webkit-center',
                          // text-align: -webkit-center
                        }}
                      >
                        <img
                          alt="Dan Abramov"
                          src={newbinanceimage}
                          className={
                            isMobile
                              ? 'newImagetestheroshotMobile'
                              : 'newImagetestheroshot'
                          }
                        />
                      </div>
                      {/* </Box> */}
                    </Box>
                    {isMobile ? (
                      <Text m="2px" color="white" fontWeight={'bold'}>
                        {t(`${translationPath}newTextTitle`)}
                      </Text>
                    ) : (
                      <Text
                        m="2px"
                        whiteSpace="nowrap"
                        fontWeight={'bold'}
                        color="white"
                        fontSize={{ base: 'lg', sm: 'l', lg: '3xl', xl: '4xl' }}
                      >
                        {t(`${translationPath}newTextTitle`)}
                      </Text>
                    )}
                  </Box>
                </Box>
                {isMobile ? (
                  <Box
                    m="auto"
                    w={{ base: '70%' }}
                    textAlign={{ base: 'center', lg: 'center' }}
                  >
                    <Text
                      py="5"
                      textAlign={{ base: 'center', lg: 'center' }}
                      // fontSize="xl"
                      color="white"
                      w="100%"
                      m={{ base: 'auto', lg: '0' }}
                    >
                      {t(`${translationPath}newTextTextMobile`)}
                    </Text>
                  </Box>
                ) : (
                  <Box
                    m="auto"
                    w={{ base: '60%' }}
                    textAlign={{ base: 'center', lg: 'center' }}
                  >
                    <Text
                      py="5"
                      textAlign={{ base: 'center', lg: 'center' }}
                      fontSize="xl"
                      color="white"
                      w="100%"
                      m={{ base: 'auto', lg: '0' }}
                    >
                      {t(`${translationPath}newTextText`)}
                    </Text>
                  </Box>
                )}

                <Box
                  m="auto"
                  w={{ base: '100%' }}
                  textAlign={{ base: 'center', lg: 'left' }}
                >
                  <Box
                    w="80%"
                    textAlign={{
                      base: 'center',
                      lg: lang.currentLanguage === 'en' ? 'center' : 'center',
                    }}
                    m="auto"
                  >
                    <Button
                      _focus={{
                        boxShadow: 'none',
                      }}
                      _active={{
                        bg: '#151C2F',
                        transform: 'scale(0.98)',
                      }}
                      position="static"
                      whiteSpace="normal"
                      _hover="none"
                      mt={{ base: '2', lg: '10' }}
                      mb={{ base: '2', lg: '4' }}
                      mr={lang.currentLanguage === 'en' ? '2' : 0}
                      ml={lang.currentLanguage === 'en' ? 0 : '2'}
                      fontSize={{ base: '12px', lg: '15px' }}
                      bg="#757575"
                      color="white"
                      size="md"
                      w="200px"
                      minH={isMobile ? '30px' : '50px'}
                      h={{ lg: 'auto' }}
                      onClick={handleOpen}
                    >
                      {t(`${translationPath}Started`)}
                    </Button>
                    <Button
                      _focus={{
                        boxShadow: 'none',
                      }}
                      _active={{
                        bg: '#151C2F',
                        transform: 'scale(0.98)',
                      }}
                      position="static"
                      whiteSpace="normal"
                      _hover="none"
                      mt={{ base: '2', lg: '10' }}
                      mb={{ base: '2', lg: '4' }}
                      fontSize={{ base: '12px', lg: '15px' }}
                      bg="white"
                      color="#4FD1C5"
                      size="md"
                      w="200px"
                      minH={isMobile ? '30px' : '50px'}
                      h={{ lg: 'auto' }}
                      onClick={() =>
                        downloadtest2('AYMBot® Term Sheet.pdf', pdfFile)
                      }
                    >
                      {t(`${translationPath}DownloadPdf`)}
                    </Button>{' '}
                  </Box>
                  <Box
                    w="90%"
                    textAlign={{
                      base: 'center',
                      lg: lang.currentLanguage === 'en' ? 'center' : 'center',
                    }}
                    mt="5%"
                    mx={'5%'}
                  >
                    <Grid
                      templateColumns={
                        isMobile ? 'repeat(1, 1fr)' : 'repeat(4, 1fr)'
                      }
                      gap={5}
                      style={{ justifyItems: 'center' }}
                    >
                      <GridItem w="70%" h={isMobile ? '6' : '10'}>
                        <FormControl mx={{ base: '0' }}>
                          <Input
                            // bgColor="#ffffff"
                            variant="flushed"
                            _placeholder={{ color: '#ffffff' }}
                            borderColor="transparent"
                            color="#ffffff"
                            style={{
                              borderBottomWidth: '3px',
                              borderBottomColor: '#fff',
                            }}
                            placeholder={t(`${translationPath}name`)}
                            id="Name"
                            type="Text"
                            value={state.message.name}
                            onChange={event => {
                              setState(prev => ({
                                ...prev,
                                message: {
                                  ...prev.message,
                                  name: event.target.value,
                                },
                              }));
                            }}
                          />
                          {state.error && state.error.name && (
                            <FormHelperText color="red">
                              {state.error.name}{' '}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </GridItem>
                      <GridItem w="70%" h={isMobile ? '6' : '10'}>
                        <FormControl mx={{ base: '0' }}>
                          <Input
                            // bgColor="#ffffff"
                            variant="flushed"
                            _placeholder={{ color: '#ffffff' }}
                            borderColor="transparent"
                            color="#ffffff"
                            style={{
                              borderBottomWidth: '3px',
                              borderBottomColor: '#fff',
                            }}
                            placeholder={t(`${translationPath}email`)}
                            id="Name"
                            type="Text"
                            value={state.message.email}
                            onChange={event => {
                              setState(prev => ({
                                ...prev,
                                message: {
                                  ...prev.message,
                                  email: event.target.value,
                                },
                              }));
                            }}
                          />
                          {state.error && state.error.email && (
                            <FormHelperText color="red">
                              {state.error.email}{' '}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </GridItem>
                      <GridItem w="70%" h={isMobile ? '6' : '10'}>
                        <FormControl mx={{ base: '0' }}>
                          <Input
                            // bgColor="#ffffff"
                            variant="flushed"
                            _placeholder={{ color: '#ffffff' }}
                            borderColor="transparent"
                            color="#ffffff"
                            style={{
                              borderBottomWidth: '3px',
                              borderBottomColor: '#fff',
                            }}
                            placeholder={t(`${translationPath}phone_number`)}
                            id="Name"
                            type="Text"
                            value={state.message.phone_number}
                            onChange={event => {
                              setState(prev => ({
                                ...prev,
                                message: {
                                  ...prev.message,
                                  phone_number: event.target.value,
                                },
                              }));
                            }}
                          />
                          {state.error && state.error.phone_number && (
                            <FormHelperText color="red">
                              {state.error.phone_number}{' '}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </GridItem>
                      <GridItem
                        w="100%"
                        mt={isMobile ? 8 : 2}
                        mb={isMobile ? 6 : 2}
                      >
                        <Button
                          _focus={{
                            boxShadow: 'none',
                          }}
                          _active={{
                            bg: '#151C2F',
                            transform: 'scale(0.98)',
                          }}
                          position="static"
                          whiteSpace="normal"
                          _hover="none"
                          mr={lang.currentLanguage === 'en' ? '2' : 0}
                          ml={lang.currentLanguage === 'en' ? 0 : '2'}
                          fontSize={{ base: '12px', lg: '14px' }}
                          bg="#4FD1C5"
                          color="white"
                          size="md"
                          w="200px"
                          minH={isMobile ? '30px' : '50px'}
                          h={{ lg: 'auto' }}
                          onClick={handleContactUsSS}
                        >
                          {t(`${translationPath}makecall`)}
                        </Button>
                      </GridItem>
                      {/* <GridItem w='100%' h='10' bg='blue.500' /> */}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Stack>
          </SimpleGrid>
        </GridItem>
      </Grid>

      {isMobile ? (
        <Box bgColor={'#e5e5e5'} w={'100%'}>
          <Box w="98%" textAlign="center" margin="auto">
            <Box mt="10">
              <Heading
                pt="10px"
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                color="#4FD1C5"
              >
                {t(`${translationPath}Humble`)}
              </Heading>
            </Box>
          </Box>

          <Box className="container">
            <Slider {...settings2}>
              <div>
                <Box
                  pt="5"
                  pb="30"
                  className="Humble-2"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://www.entrepreneursherald.com/blog/top-5-personalities-disrupting-the-finance-industry-in-2022?categoryId=109',
                        '_blank'
                      );
                    }}
                    src={images[4].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="15"
                    color="#757575"
                  >
                    {t(`${translationPath}EH`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  pb="30"
                  className="Humble-2"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://fintechnews.ae/10739/fintechjordan/out-of-jordan-an-autonomous-algorithmic-bot-emerges-with-bitcoin-squarely-in-its-aym/',
                        '_blank'
                      );
                    }}
                    src={images[3].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}fintechnews`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  pb="30"
                  className="HumbleAN"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://www.arabnews.com/node/2076951/business-economy',
                        '_blank'
                      );
                    }}
                    src={images[1].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}an`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  pb="30"
                  className="Humble"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://en.royanews.tv/news/32214/2021-10-24',
                        '_blank'
                      );
                    }}
                    src={images[2].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="15"
                    color="#757575"
                  >
                    {t(`${translationPath}roya`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  className="Humble-2"
                  pb="30"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    // onClick={() => {
                    //   window.open(
                    //     'https://www.marketwatch.com/press-release/out-of-jordan-an-autonomous-algorithmic-bot-emerges-with-bitcoin-squarely-in-its-aym-2021-10-25',
                    //     '_blank'
                    //   );
                    // }}
                    src={images[0].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}market-watch`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  className="Humble-2"
                  pb="30"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://intlbm.com/Awardlist-2022/#tab-158755',
                        '_blank'
                      );
                    }}
                    src={images[5].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}panfan11`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  className="Humble-2"
                  pb="30"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://panfinance.net/winners/aymbot/',
                        '_blank'
                      );
                    }}
                    src={images[6].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}panfan12`)}
                  </Text>
                </Box>
              </div>
            </Slider>
          </Box>
        </Box>
      ) : (
        <Grid bgColor="#E5E5E5">
          <Box w="100%" textAlign="center" margin="auto">
            <Box mt="5">
              <Heading
                fontWeight="bold"
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                color="#4FD1C5"
              >
                {t(`${translationPath}Humble`)}
              </Heading>
            </Box>
          </Box>

          <Box className={isMobile ? 'containerMobile' : 'container'}>
            <Slider {...settings}>
              <div>
                <Box
                  pt="5"
                  pb="30"
                  className="Humble-2"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://www.entrepreneursherald.com/blog/top-5-personalities-disrupting-the-finance-industry-in-2022?categoryId=109',
                        '_blank'
                      );
                    }}
                    src={images[4].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="15"
                    color="#757575"
                  >
                    {t(`${translationPath}EH`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  pb="30"
                  className="Humble-2"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://fintechnews.ae/10739/fintechjordan/out-of-jordan-an-autonomous-algorithmic-bot-emerges-with-bitcoin-squarely-in-its-aym/',
                        '_blank'
                      );
                    }}
                    src={images[3].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}fintechnews`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  pb="30"
                  className="HumbleAN"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://www.arabnews.com/node/2076951/business-economy',
                        '_blank'
                      );
                    }}
                    src={images[1].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}an`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  pb="30"
                  className="Humble"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://en.royanews.tv/news/32214/2021-10-24',
                        '_blank'
                      );
                    }}
                    src={images[2].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="15"
                    color="#757575"
                  >
                    {t(`${translationPath}roya`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  className="Humble-2"
                  pb="30"
                  textAlign="center"
                  m="auto"
                >
                  <img
                   
                    src={images[0].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}market-watch`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  className="Humble-2"
                  pb="30"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://intlbm.com/Awardlist-2022/#tab-158755',
                        '_blank'
                      );
                    }}
                    src={images[5].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}panfan11`)}
                  </Text>
                </Box>
              </div>
              <div>
                <Box
                  pt="5"
                  className="Humble-2"
                  pb="30"
                  textAlign="center"
                  m="auto"
                >
                  <img
                    onClick={() => {
                      window.open(
                        'https://panfinance.net/winners/aymbot/',
                        '_blank'
                      );
                    }}
                    src={images[6].img}
                    alt={'alt'}
                  />
                  <Text
                    noOfLines={2}
                    w="80%"
                    fontStyle="italic"
                    fontSize="sm"
                    m="auto"
                    text-align="center"
                    pt="5"
                    color="#757575"
                  >
                    {t(`${translationPath}panfan12`)}
                  </Text>
                </Box>
              </div>
            </Slider>
          </Box>
        </Grid>
      )}
    </div>
  );
};
