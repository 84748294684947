import React from 'react';
import { Box, Button, Text, Grid, Flex, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AffiliateImg from '../../assets/images/images/AffiliateImg.png';

const parentTranslationPath = 'Affiliate';
const translationPath = '';

export const Affiliate = ({ AffiliateRef }) => {
  const { t } = useTranslation(parentTranslationPath);
  let lang = JSON.parse(localStorage.getItem('localization'));
  return (
    <div className="Affiliate" ref={AffiliateRef} id="Referrals">
      <Grid
        h={{ base: 'auto', xl: '500px' }}
        bgGradient={'linear(to-r, #192238, #000000'}
     
      >
        <Flex alignItems="center" display="flex" justifyContent="right">
          <Box
            height="100%"
            display={{ base: 'none', lg: 'flex' }}
            w={{ base: '100%', lg: '50%' }}
          >
            <Grid
              backgroundImage={{ base: 'none', lg: AffiliateImg }}
              backgroundPosition="initial"
              backgroundRepeat="no-repeat"
              opacity="0.8"
              backgroundSize={{ base: '100% 100%' }}
              width="100%"
              height="100%"
            ></Grid>
          </Box>

          <Box w={{ base: '100%', lg: '50%' }} my="10">
            <Stack spacing={6} m="auto" alignItems="center">
              <Text
                color="white"
                fontWeight="bold"
                textAlign={{ base: 'center' }}
                fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
              >
                {t(`${translationPath}Affiliate`)}
              </Text>
              <Text
                className={lang.currentLanguage === 'en' ?'':"rtl"}
                color="#757575"
                w="60%"
                textAlign={{ base: 'center' }}
                fontSize="md"
                my="10"
              >
                {t(`${translationPath}p-1`)}
              </Text>
              <Text
                className={lang.currentLanguage === 'en' ?'':"rtl"}
                color="#757575"
                w="60%"
                textAlign={{ base: 'center' }}
                fontSize="md"
                my="10"
              >
                {t(`${translationPath}p-2`)}
              </Text>
              <Box m="auto">
                <Button
                  isDisabled={true}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  _active={{
                    bg: '#151C2F',
                    transform: 'scale(0.98)',
                  }}
                  position="static"
                  whiteSpace="normal"
                  _hover="none"
                  my="10px"
                  fontSize="16px"
                  bg="#4FD1C5"
                  color="white"
                  size="lg"
                  w="180px"
                >
                  {t(`${translationPath}coming-soon`)}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Flex>
      </Grid>
      <Grid h={{ base: 'auto' }} bgColor="#E5E5E5">
        <Box width="60%" m="auto" my="10">
          <Text
           className={lang.currentLanguage === 'en' ?'':"rtl"}
            my="5"
            color="#4FD1C5"
            w="100%"
            display="flex"
            justifyContent="center"
            fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
            fontWeight="bold"
          >
            {t(`${translationPath}disclaimer`)}
          </Text>
          <Text
           className={lang.currentLanguage === 'en' ?'':"rtl"}
            color="#757575"
            fontWeight="bold"
            w={{ base: '80%', lg: '60%' }}
            textAlign="center"
            fontSize={{ base: 'lg ', md: 'xl', xl: '3xl' }}
            m="auto"
          >
            {t(`${translationPath}disclaimer-2`)}
          </Text>
        

          <Text
           className={lang.currentLanguage === 'en' ?'':"rtl"}
            color="#757575"
            w={{ base: '80%', lg: '60%' }}
            textAlign="center"
            fontSize="md"
            py="5"
            m="auto"
          >
            {t(`${translationPath}disclaimer-3`)}
          </Text>
        </Box>
      </Grid>
    </div>
  );
};
