import React, { useState, useEffect, useRef, createRef } from 'react';
import {
  Box,
  Text,
  Grid,
  Image,
  Center,
  Flex,
  Divider,
  GridItem,
  useMediaQuery,
  SimpleGrid,
} from '@chakra-ui/react';
import moment from 'moment';
import qrcodefinal from '../../assets/img/images/qrcodefinal.png';
import homeImg from '../../assets/img/images/AYM.png';
import { useLocation } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart';
import { useTranslation } from 'react-i18next';

import BLM from '../../assets/img/images/BLM.png';
import BM from '../../assets/img/images/BM.png';
import GM from '../../assets/img/images/GM.png';
import GRM from '../../assets/img/images/GRM.png';
import OM from '../../assets/img/images/OM.png';
import PM from '../../assets/img/images/PM.png';
import YM from '../../assets/img/images/YM.png';
import locationicon1 from '../../assets/img/images/locationicon1.png';
import emailicon from '../../assets/img/images/emailicon.png';
import greenicon from '../../assets/img/images/greenicon.png';
import orangeicon from '../../assets/img/images/orangeicon.png';
import phoneicon from '../../assets/img/images/phoneicon.png';
import purpicon from '../../assets/img/images/purpicon.png';
import siteicon from '../../assets/img/images/siteicon.png';
import yellowicon from '../../assets/img/images/yellowicon.png';
import bull from '../../assets/img/images/bull.png';
import berr from '../../assets/img/images/berr.png';
import bullberr from '../../assets/img/images/bullberr.png';
import WebFont from 'webfontloader';
import { isMobile } from 'react-device-detect';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const parentTranslationPath = 'Home';
const translationPath = '';
export const DownloadReport = ({ DownloadReportRef, props }) => {
  let lang = JSON.parse(localStorage.getItem('localization'));
  const { t } = useTranslation(parentTranslationPath);
  const [isMaxWidthlarge] = useMediaQuery('(max-width: 1919px)');
  const location = useLocation();
  const refScreen = createRef(null);
  const refScreenAR = createRef(null);
  const chartRef = useRef(null);
  const [newpdf, setnewpdf] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingAR, setLoadingAR] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const najitest = params.get('data');
    const buff = Buffer.from(najitest, 'base64');
    const str = JSON.parse(buff);
    setnewpdf(str);
  }, []);
  useEffect(() => {
    if (newpdf) {
      if (newpdf.language === 'en') {
        imagePdf();
      } else if (newpdf.language === 'ar') {
        imagePdfAR();
      }
    }
  }, [newpdf]);
  const imagePdf = async () => {
    setLoading(false);
    const filenames = `AYMBot®'s Performance Report ${moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('MMMM')} ${moment().startOf('year').format('YYYY')}`;
    let pdf;
    html2canvas(refScreen.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      pdf = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [canvas.width, canvas.height],
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save(filenames);
    });
    setLoading(true);
  };

  const imagePdfAR = async () => {
    setLoadingAR(false);

    const filenames = `AYMBot®'s Performance Report ${moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('MMMM')} ${moment().startOf('year').format('YYYY')}`;

    let pdf;
    html2canvas(refScreenAR.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      pdf = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [canvas.width, canvas.height],
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
      pdf.save(filenames);
    });
    setLoadingAR(true);
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          'Droid Sans',
          'Chilanka',
          'Open Sans',
          'Alkalami',
          'Arvo',
          'Poppins',
          'PT Sans',
          'Lato',
        ],
      },
    });
  }, []);

  return (
    <Flex flexDirection="column">
      <Grid
        minH={'1000'}
        width={{ base: '100%' }}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        style={{
          position: 'absolute',
          zIndex: 999999999999999,
          justifyContent: 'center',
          background: 'linear-gradient(to right , #192137 ,#000000)',
        }}
      >
        <Center>
          {!isMobile ? (
            <Box>
              <Text
                style={{
                  fontFamily: 'lato',
                  fontSize: '40px',
                  textAlign: 'center',
                }}
              >
                Downloading...
              </Text>
              {newpdf.language === 'en' ? (
                <Text>
                  Faulty API keys will result in incomplete data, please reach
                  out to the AYM Support Team so that we may address this right
                  away
                </Text>
              ) : (
                <Box display={'flex'} style={{ direction: 'rtl' }}>
                  <Text style={{ fontFamily: 'lato', textAlign: 'center' }}>
                    {' '}
                    يرجى العلم بان أي خلل في وجهات الاتصال{' '}
                  </Text>{' '}
                  <Text style={{ fontFamily: 'lato', textAlign: 'center' }}>
                    {' '}
                    ( API ){' '}
                  </Text>
                  <Text style={{ fontFamily: 'lato', textAlign: 'center' }}>
                    {' '}
                    سيؤدي الى استعادة بيانات ناقصة، يرجى المسارعة على الاتصال
                    بفريق الدعم من اجل معالجة الأمر باسرع وقت{' '}
                  </Text>
                </Box>
              )}
            </Box>
          ) : (
            <Box>
              <Text
                style={{
                  fontFamily: 'lato',
                  fontSize: '40px',
                  textAlign: 'center',
                }}
              >
                Downloading...
              </Text>
              {newpdf.language === 'en' ? (
                <Text style={{ textAlign: 'center' }} mx={10}>
                  Faulty API keys will result in incomplete data, please reach
                  out to the AYM Support Team so that we may address this right
                  away
                </Text>
              ) : (
                <Box style={{ direction: 'rtl' }} mx={5}>
                  <Text style={{ fontFamily: 'lato', textAlign: 'center' }}>
                    {' '}
                    يرجى العلم بان أي خلل في وجهات الاتصال ( API )
                  </Text>{' '}
                  <Text style={{ fontFamily: 'lato', textAlign: 'center' }}>
                    {' '}
                    سيؤدي الى استعادة بيانات ناقصة، يرجى المسارعة على الاتصال
                    بفريق الدعم من اجل معالجة الأمر باسرع وقت{' '}
                  </Text>
                </Box>
              )}
            </Box>
          )}
        </Center>
      </Grid>

      {newpdf.language === 'en' ? (
        <div
          className="downLTR downloadhedin"
          // className="downLTR "

          id="legend"
          style={{ width: '1920px', height: '965px' }}
        >
          {/* <div className="downLTR " id="legend"> */}

          {/* <div className="downLTR " id="legend"> */}
          <div ref={refScreen}>
            <div id={'pdf'}>
              <Grid>
                <Box bg={'linear-gradient(to right , #192137 ,#000000)'}>
                  <Box
                    backgroundImage={
                      newpdf?.plan === 'Free'
                        ? BM
                        : newpdf?.plan === 'Basic'
                        ? OM
                        : newpdf?.plan === 'Standard'
                        ? GM
                        : newpdf?.plan === 'Pro'
                        ? PM
                        : newpdf?.plan === 'Gold'
                        ? YM
                        : newpdf?.plan === 'Platinum'
                        ? GRM
                        : newpdf?.plan === 'Black'
                        ? BLM
                        : '#070910'
                    }
                    backgroundPosition="left"
                    backgroundRepeat="no-repeat"
                    backgroundSize={isMaxWidthlarge ? 'cover' : 'cover'}
                  >
                    <Grid
                      zIndex={100}
                      ml={'10%'}
                      w={'90%'}
                      alignSelf="center"
                      templateColumns="repeat(6, 1fr)"
                    >
                      <GridItem rowSpan={2} colSpan={1} mr={10}>
                        <Image
                          objectFit="contain"
                          src={homeImg}
                          alt={'alt'}
                          backgroundSize="cover"
                          h="140px"
                          m="auto"
                          pt="5"
                        />
                        <Center
                          justifyContent={'space-evenly'}
                          mt="2"
                          w={'220px'}
                          styles={{
                            flexDirection:
                              newpdf.language === 'en' ? '' : 'row-reverse',
                          }}
                          className={newpdf.language === 'en' ? '' : 'rowrevv'}
                        >
                          <Text
                            color="white"
                            fontSize={{
                              base: 'sm',
                              md: '16px',
                              xl: '18px',
                              xxl: '20px',
                            }}
                          >
                            Trade Start Date
                          </Text>
                          <Text
                            color="#4FD1C5"
                            fontSize={{ base: 'sm', md: '16px', xl: '16px' }}
                          >
                            {newpdf && newpdf?.exDate
                              ? moment(newpdf && newpdf?.exDate)
                                  .subtract(1, 'years')
                                  .format('DD/MM/YYYY')
                              : '-'}
                          </Text>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '18px',
                                  xl: '20px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                }}
                              >
                                Opening Balance
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // fontFamily: "Lato",
                                }}
                                fontweight={'bold'}
                                my={2}
                              >
                                {newpdf && newpdf?.openingBalance
                                  ? newpdf?.openingBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '18px',
                                  xl: '20px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                }}
                              >
                                Initial Balance
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                }}
                                fontweight={'bold'}
                                my={2}
                              >
                                {newpdf && newpdf?.initBalance
                                  ? newpdf?.initBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>

                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '18px',
                                  xl: '20px',
                                }}
                              >
                                Base Balance
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // // fontFamily: "Lato",
                                }}
                                my={2}
                              >
                                {newpdf && newpdf?.baseBalance
                                  ? newpdf?.baseBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                            // ml={{ base: "1%" }}
                          >
                            <Center>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                // fontWeight="bold"
                                // fontSize={{ base: 'sm' }}
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                              >
                                {/* {t(`${translationPath}MonthlyReturn`)} */}
                                Monthly Return $US
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                // fontWeight="bold"
                                // fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // // fontFamily: "Lato",
                                }}
                                my={2}
                              >
                                {newpdf && newpdf?.monthlyReturn
                                  ? newpdf?.monthlyReturn + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                              >
                                {/* {t(`${translationPath}ClosingBalance`)} */}
                                Closing Balance
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // // fontFamily: "Lato",
                                }}
                                my={2}
                              >
                                {newpdf && newpdf?.closingBalance
                                  ? newpdf?.closingBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                              >
                                {/* {t(`${translationPath}TotalTradesExeuted`)} */}
                                Total Trades Exeuted
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // // fontFamily: "Lato",
                                }}
                                my={2}
                              >
                                {newpdf && newpdf?.totalTradesExecuted
                                  ? newpdf?.totalTradesExecuted
                                  : 0 + ' '}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                      </GridItem>

                      <GridItem rowSpan={2} colSpan={3} mt={5}>
                        <Center display="flex" style={{ base: 'center' }}>
                          <Text
                            // // style={{ fontFamily: "Lato" }}ge
                            fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                            color="white"
                            fontWeight="bold"
                            justifyContent={'center'}
                            // my={6}
                          >
                            {/* {t(`${translationPath}Asked`)}  */}
                            {newpdf && newpdf.firstName
                              ? newpdf.firstName
                              : ' '}
                          </Text>
                          <Text
                            // // style={{ fontFamily: "Lato" }}ge
                            // color="filver"
                            // color="rgb(172 72 231)"
                            color="#4FD1C5"
                            fontWeight="bold"
                            fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                            // justifyContent={'center'}
                            // my={6}
                            ml={{ base: '1%' }}
                          >
                            {/* {t(`${translationPath}Asked`)}  */}
                            {newpdf && newpdf.lastName ? newpdf.lastName : ' '}
                          </Text>
                        </Center>

                        <Center
                          display="flex"
                          textAlign={{
                            base: 'center',
                            md: newpdf.language === 'en' ? 'left' : 'right',
                          }}
                          className={newpdf.language === 'en' ? '' : 'rowrevv'}
                        >
                          <Text
                            // // style={{ fontFamily: "Lato" }}ge
                            // width={{ base: "55%" }}
                            fontSize={{ base: 'l', md: 'xl', xl: 'xl' }}
                            color="white"
                            // fontWeight="bold"
                            justifyContent={'center'}
                            // letterSpacing={2}
                            // alignSelf={"center"}
                            // my={6}   below the results of Month-Year
                            className={
                              newpdf.language === 'en' ? '' : 'padscan'
                            }
                          >
                            {/* {t(`${translationPath}Asked`)}  */}
                            {/* {t(`${translationPath}Theseareyourperformance`)} */}
                            These are your performance highlights for
                          </Text>
                          <Text
                            color="#52d3cb"
                            fontSize={{ base: 'l', md: 'xl', xl: '2xl' }}
                            ml={2}
                          >
                            {' '}
                            {newpdf?.date}
                          </Text>
                        </Center>

                        <Center
                          display="flex"
                          textAlign={{
                            base: 'center',
                            md: newpdf.language === 'en' ? 'left' : 'right',
                          }}
                        ></Center>
                        <Center>
                          <Divider orientation="horizontal" h={5} w={'80%'} />
                        </Center>
                        <SimpleGrid columns={2} spacing={10} mt={10}>
                          {/* <Box bg="tomato" height="80px"> */}
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {/* {t(
                                    `${translationPath}MonthlyPercentageReturn`
                                  )} */}
                                Monthly Percentage Return
                              </Text>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* 60% */}
                                {newpdf && newpdf?.monthlyPercentageReturn
                                  ? newpdf?.monthlyPercentageReturn + ' %'
                                  : 0 + ' %'}
                              </Text>
                            </Box>
                          </Center>

                          {/* </Box> */}
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {/* {t(`${translationPath}AllTimeROI`)} */}
                                All-Time ROI
                              </Text>
                              <Text
                                // // // style={{ fontFamily: "Lato" }}gegege
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* 88% */}
                                {newpdf && newpdf?.alltimeROI
                                  ? newpdf?.alltimeROI + ' %'
                                  : 0 + ' %'}
                              </Text>
                            </Box>
                          </Center>
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                // // // style={{ fontFamily: "Lato" }}gegege
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {/* {t(`${translationPath}TotalDeposits`)} */}
                                Total Deposits
                              </Text>
                              <Text
                                // // // style={{ fontFamily: "Lato" }}gegege
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* {location?.state?.data?.totalDeposit + " $US"} */}

                                {newpdf && newpdf?.totalDeposits
                                  ? newpdf?.totalDeposits + ' $US'
                                  : 0 + ' US'}
                              </Text>
                            </Box>
                          </Center>
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                // // // style={{ fontFamily: "Lato" }}gegege
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {/* {t(`${translationPath}TotalWithdrawals`)} */}
                                Total Withdrawals
                              </Text>
                              <Text
                                // // style={{ fontFamily: "Lato" }}gege
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* {location?.state?.data?.totalWithdrawal + " $US"} */}
                                {newpdf && newpdf.totalWithdrawals
                                  ? newpdf.totalWithdrawals + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Box>
                          </Center>
                        </SimpleGrid>
                        <SimpleGrid columns={3} spacing={10} spacingY="20px">
                          <GridItem>
                            <Box className="fuckpiecharttest">
                              <Text fontSize={'40px'} color="#FFFFFF">
                                %
                              </Text>
                            </Box>
                            <Box>
                              <PieChart
                                ref={chartRef}
                                data={[
                                  {
                                    title: 'USDT',
                                    // value: balance.PercentageOfUSDT,
                                    value: newpdf?.percentageOfUSDT,

                                    color: '#FF8061',
                                  },
                                  {
                                    title: 'Long',
                                    // value: balance.percentageLongOfTotalAccount,
                                    value: newpdf?.percentageLongOfTotalAccount,
                                    color: '#F9ED78',
                                  },
                                  {
                                    title: 'Short',
                                    // value: balance.percentageShortOfTotalAccount,
                                    value:
                                      newpdf?.percentageShortOfTotalAccount,

                                    color: '#4A3786',
                                  },
                                ]}
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            </Box>

                            <Center>
                              <Box display="flex">
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box ml={1} mr={2} mb={1}> */}

                                  <Image
                                    objectFit="contain"
                                    src={orangeicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    mt={'12px'}
                                  />

                                  {/* </Box> */}
                                  <Text
                                    style={{ fontSize: '14px' }}
                                    // fontsize={'12px'}
                                    color="#fff"
                                    // ClassName="fontinchartdounload"
                                  >
                                    {/* {t(`${translationPath}USDT`)} */}
                                    USDT
                                  </Text>
                                  <Text
                                    // // style={{ fontFamily: "Lato" }}gege
                                    style={{ fontSize: '14px' }}
                                    ml={2}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {' '}
                                    {newpdf?.percentageOfUSDT}
                                  </Text>
                                </Box>
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box ml={1} mr={2} mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={purpicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  {/* </Box> */}

                                  <Text
                                    // // style={{ fontFamily: "Lato" }}gege
                                    style={{ fontSize: '14px' }}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {' '}
                                    {/* {t(`${translationPath}Short`)} */}
                                    Short
                                  </Text>
                                  <Text
                                    // // style={{ fontFamily: "Lato" }}gege
                                    style={{ fontSize: '14px' }}
                                    ml={2}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {newpdf?.percentageShortOfTotalAccount}
                                  </Text>
                                </Box>

                                {/* </Center> */}
                              </Box>
                            </Center>
                            <Center>
                              <Box display="flex" ml={1} mr={2} mt={3}>
                                {/* <Box  mb={1}> */}
                                <Image
                                  objectFit="contain"
                                  src={yellowicon}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  // h="20"
                                  w={'10px'}
                                  m="auto"
                                  mr={'5px'}
                                  ml={'5px'}
                                  mt={'12px'}
                                />
                                {/* </Box> */}
                                <Text
                                  // // style={{ fontFamily: "Lato" }}gege
                                  style={{ fontSize: '14px' }}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                                  {' '}
                                  {/* {t(`${translationPath}Long2`)} */}
                                  Long
                                </Text>
                                <Text
                                  // // style={{ fontFamily: "Lato" }}gege
                                  style={{ fontSize: '14px' }}
                                  ml={2}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                                  {newpdf?.percentageLongOfTotalAccount}
                                </Text>
                              </Box>
                            </Center>
                          </GridItem>
                          <GridItem>
                            <Box>
                              <Box className="fuckpiecharttest">
                                <Text fontSize={'40px'} color="#FFFFFF">
                                  %
                                </Text>
                              </Box>
                              <PieChart
                                ref={chartRef}
                                data={[
                                  {
                                    title: 'percentageOfCanceledOrders',
                                    value: newpdf?.percentageOfCanceledOrders,

                                    color: '#675196',
                                  },
                                  {
                                    title: 'percentageOfFilledTrades',
                                    value: newpdf?.percentageOfFilledTrades,
                                    color: '#74BEC9',
                                  },
                                  // { title: "Three", value: 20, color: "#675196" },
                                ]}
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            </Box>

                            <Center>
                              <Box display="flex">
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={greenicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{ fontSize: '14px' }}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {/* {t(`${translationPath}Canceled2`)} */}
                                    Canceled
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{ fontSize: '14px' }}
                                    ml={2}
                                    color="#fff"
                                  >
                                    {' '}
                                    {newpdf?.percentageOfFilledTrades}
                                  </Text>
                                </Box>
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={purpicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />

                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{ fontSize: '14px' }}
                                    color="#fff"
                                  >
                                    {' '}
                                    {/* {t(`${translationPath}Filled`)} */}
                                    Filled
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{ fontSize: '14px' }}
                                    ml={2}
                                    color="#fff"
                                  >
                                    {newpdf?.percentageOfCanceledOrders}
                                  </Text>
                                </Box>
                              </Box>
                            </Center>
                          </GridItem>
                          <GridItem>
                            <Box className="fuckpiecharttest">
                              <Text fontSize={'40px'} color="#FFFFFF">
                                %
                              </Text>
                            </Box>
                            <Box>
                              {/* <Text>%</Text> */}
                              <PieChart
                                ref={chartRef}
                                data={[
                                  {
                                    title: 'TP',
                                    // value: balance.percentageLongOfTotalAccount,
                                    value: newpdf?.tradeSuccessRate,

                                    color: '#F9ED78',
                                  },
                                  {
                                    title: 'SL',
                                    // value: balance.percentageShortOfTotalAccount,
                                    value: newpdf?.tradeFailureRate,

                                    color: '#FF8061',
                                  },
                                ]}
                                labelPosition={70}
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            </Box>

                            <Center>
                              <Box display="flex">
                                {newpdf.language === 'en' ? (
                                  <Box display="flex" ml={1} mr={2}>
                                    {/* <Box  mb={1}> */}
                                    <Image
                                      objectFit="contain"
                                      src={orangeicon}
                                      alt={'alt'}
                                      backgroundSize="cover"
                                      // h="20"
                                      w={'10px'}
                                      m="auto"
                                      mr={'4px'}
                                      ml={'4px'}
                                      mt={'12px'}
                                    />
                                    <Text
                                      style={{ fontSize: '14px' }}
                                      color="#fff"
                                    >
                                      {/* {t(`${translationPath}SL`)} */}SL
                                    </Text>
                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      style={{ fontSize: '14px' }}
                                      ml={2}
                                      color="#fff"
                                    >
                                      {' '}
                                      {newpdf?.tradeFailureRate}
                                    </Text>
                                  </Box>
                                ) : (
                                  <Box display="flex" ml={1}>
                                    {/* <Box  mb={1}> */}
                                    <Image
                                      objectFit="contain"
                                      src={orangeicon}
                                      alt={'alt'}
                                      backgroundSize="cover"
                                      // h="20"
                                      w={'10px'}
                                      m="auto"
                                      mt={'12px'}
                                    />
                                    <Text
                                      // style={{ fontSize:'12px',padding:'3px'}}
                                      style={{ fontSize: '14px' }}
                                      color="#fff"
                                      // fontSize={'14px'}
                                    >
                                      SL
                                    </Text>
                                    <Text
                                      // style={{ fontFamily: "Lato" }}ge
                                      // ml={2}
                                      color="#fff"
                                      // fontSize={'11px'}
                                      style={{ fontSize: '14px' }}
                                    >
                                      {' '}
                                      {newpdf?.tradeFailureRate}
                                    </Text>
                                  </Box>
                                )}

                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={yellowicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    color="#fff"
                                    style={{ fontSize: '14px' }}
                                  >
                                    {' '}
                                    {/* {t(`${translationPath}TP2`)} */}TP
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    ml={2}
                                    color="#fff"
                                    style={{ fontSize: '14px' }}
                                  >
                                    {newpdf?.tradeSuccessRate}
                                  </Text>
                                </Box>
                                {/* </Center> */}
                              </Box>
                            </Center>
                          </GridItem>
                        </SimpleGrid>
                        <Box mt={20}></Box>

                        <GridItem>
                          <Box ml={8}>
                            <Box
                              // w={"40%"}
                              display={'flex'}
                              zIndex={99999}
                              position="absolute"
                              // bgcolor="red"
                            >
                              <Center
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignSelf={'center'}
                                alignContent={'center'}
                                alignItems={'center'}
                              >
                                <Text></Text>
                                <Text
                                  className="SpotWalletprogress"
                                  color={'#fff'}
                                  fontSize={'16px'}
                                >
                                  {/* {t(`${translationPath}Spotwallet`)} */}
                                  {/* المحفظة الفورية */}
                                  Spot wallet
                                </Text>
                                <Text
                                  color={'#fff'}
                                  className="SpotWalletprogress"
                                  fontSize={'16px'}
                                  // mb={8}
                                >
                                  {' '}
                                  {newpdf?.fundsInSpot && newpdf?.fundsInSpot
                                    ? newpdf?.fundsInSpot + '%'
                                    : 0 + '%'}
                                </Text>
                              </Center>

                              <Center
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignSelf={'center'}
                                alignContent={'center'}
                                alignItems={'center'}
                                // w="40%"
                                h="10"
                              >
                                <Text // style={{ fontFamily: "Lato" }}ge
                                ></Text>
                                <Text
                                  // style={{ fontFamily: "Lato" }}ge
                                  color={'#fff'}
                                  // ml={"2"}
                                  className="SpotWalletprogress"
                                  fontSize={'16px'}
                                  // mb={10}
                                >
                                  {/* {t(`${translationPath}DerivativesWallet`)} */}
                                  Derivatives wallet
                                </Text>
                                <Text
                                  // style={{ fontFamily: "Lato" }}ge
                                  color={'#fff'}
                                  // mr={"1%"}
                                  // mb={10}
                                  className="SpotWalletprogress"
                                  fontSize={'16px'}
                                >
                                  {newpdf?.fundsinDerivatives &&
                                  newpdf?.fundsinDerivatives
                                    ? newpdf?.fundsinDerivatives + '%'
                                    : 0 + '%'}
                                </Text>
                              </Center>
                            </Box>
                            {newpdf?.fundsInSpot === 100 ? (
                              <div id="progressbarSpot" style={{}}>
                                <div className="progressbarinsideSpot"></div>
                              </div>
                            ) : newpdf?.fundsinDerivatives === 100 ? (
                              <div id="progressbarDeriv" style={{}}>
                                <div className="progressbarinsideDeriv"></div>
                              </div>
                            ) : (
                              <div id="progressbar" style={{ height: '35px' }}>
                                <div
                                  className="progressbarinside"
                                  style={{
                                    width: newpdf?.fundsInSpot * 8.3,
                                    height: '35px',
                                  }}
                                ></div>
                              </div>
                            )}
                          </Box>
                        </GridItem>

                        <Box
                          // w={"100%"}
                          mt={{ base: '5px' }}
                          display="grid"
                          justifyContent={'center'}
                        >
                          {/* <Center display="flex" mt={{ base: "5%" }}> */}

                          <Text
                            // style={{ fontFamily: "Lato" }}ge
                            // ml={{ base: "15%" }}
                            mt={5}
                            mb={5}
                            color="#4FD1C5"
                            w={{ base: '100%' }}
                            // fontWeight="bold"
                            fontSize={'10px'}
                            // justifyContent={'center'}

                            // my={2}
                            // ml={{ base: '5%' }}
                          >
                            {/* {t(`${translationPath}wesd`)} */}
                            This report is generated algorithmically for your
                            reference only.
                          </Text>
                          {/* </Center> */}
                        </Box>
                      </GridItem>

                      <GridItem rowSpan={2} colSpan={2} ml={10}>
                        <Center pt={{ base: '5%' }}>
                          {/* <Text pt={{ base: "5%" }} // style={{ fontFamily: "Lato" }}ge>
                
                {t(`${translationPath}ComingSoon`)} 

              </Text> */}
                        </Center>

                        <Center
                          w="100%"
                          textAlign={{
                            base: 'center',
                            lg: newpdf.language === 'en' ? 'left' : 'right',
                          }}
                          m="auto"
                          className={newpdf.language === 'en' ? '' : 'rowrevv'}
                        ></Center>

                        <Image
                          objectFit="contain"
                          src={qrcodefinal}
                          alt={'alt'}
                          backgroundSize="cover"
                          h="120"
                          m="auto"
                          // pt="5"
                          // width="100%"
                          pt={{ base: '5%' }}
                        />
                        <Box
                          display="flex"
                          mt="5"
                          justifyContent="center"
                          className={newpdf.language === 'en' ? '' : 'rowrevv'}
                        ></Box>

                        <Center mt={2}>
                          <Box w={'50%'}>
                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              color="#fff"
                              // fontWeight="bold"
                              fontSize={{ base: 'sm', md: 'md', xl: 'xl' }}
                            >
                              {' '}
                              {/* {t(`${translationPath}PrevailingBias`)} */}
                              Prevailing Bias
                            </Text>
                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              color="#4FD1C5"
                              ml={isMobile ? '20px' : '40px'}
                              fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                            >
                              {newpdf?.bias}
                            </Text>
                          </Box>

                          <Box>
                            <Text
                              // style={{ fontFamily: "Lato" }}ge
                              fontSize={{ base: 'sm', md: 'md', xl: 'xl' }}
                              color="#fff"
                            >
                              {/* {t(`${translationPath}TradeMode`)} */}
                              Trading Mode
                            </Text>
                            <Text
                              color="#4FD1C5"
                              fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                              ml={isMobile ? '10px' : 30}
                            >
                              {newpdf?.mode}
                            </Text>
                          </Box>
                        </Center>

                        {newpdf?.bias === 'Bearish' ? (
                          <Box>
                            <Image
                              objectFit="contain"
                              src={berr}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h={100}
                              w={350}
                              m="auto"
                              // mt={"2px"}
                            />
                          </Box>
                        ) : newpdf?.bias === 'Bullish' ? (
                          <Box>
                            <Image
                              objectFit="contain"
                              src={bull}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h={100}
                              w={350}
                              m="auto"
                              // mt={"2px"}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <Image
                              objectFit="contain"
                              src={bullberr}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h={100}
                              w={350}
                              m="auto"
                              // mt={"2px"}
                            />
                          </Box>
                        )}

                        {/* mode?.settings?.bias === 'Bearish' */}
                        {/*  */}

                        <SimpleGrid columns={2} spacing={10} mt={5}>
                          <Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              {/* <PhoneIcon w={4} h={4} color="#4FD1C5" mt={1} /> */}
                              {/* <Icon as={MdPhone} color="#4FD1C5" mt={"2px"} /> */}
                              <Image
                                objectFit="contain"
                                src={phoneicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                w={5}
                                m="auto"
                                // mt={"2px"}
                                pt={'10px'}
                              />
                              <Text
                                color="#fff"
                                ml={{ base: '1%' }}
                                // mb={{ base: "5px" }}
                                w={{ base: '100%' }}
                                // fontWeight="bold"
                                fontSize={'14px'}
                                pt={'7.5px'}
                              >
                                +962 79571 8888
                              </Text>
                            </Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              {/* <Icon as={MdEmail} color="#4FD1C5" mt={"2px"} /> */}
                              <Image
                                objectFit="contain"
                                src={emailicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                w={4}
                                m="auto"
                                // mt={"2px"}
                                pt={'10px'}
                              />

                              <Text
                                // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                ml={{ base: '3%' }}
                                mb={{ base: '5px' }}
                                w={{ base: '100%' }}
                                // fontWeight="bold"
                                fontSize={'14px'}
                                // justifyContent={'center'}

                                // my={2}
                                // ml={{ base: '5%' }}
                              >
                                support@aymbot.com
                              </Text>
                            </Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              <Image
                                objectFit="contain"
                                src={siteicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                w={4}
                                m="auto"
                                pt={'10px'}
                              />

                              <Text
                                ml={{ base: '3%' }}
                                color="#fff"
                                w={{ base: '100%' }}
                                fontSize={'14px'}
                              >
                                www.aymbot.com
                              </Text>
                            </Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              {/* <Icon as={MdHttp} color="#4FD1C5" mt={"4px"} /> */}

                              <Image
                                objectFit="contain"
                                src={locationicon1}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                w={4}
                                m="auto"
                                // mt={"2px"}
                                pt={'12px'}
                              />
                              <Text
                                // style={{ fontFamily: "Lato" }}ge
                                ml={{ base: '3%' }}
                                color="#fff"
                                w={{ base: '100%' }}
                                // fontWeight="bold"
                                fontSize={'12px'}
                                pt={'7.5px'}
                                // justifyContent={'center'}

                                // my={2}
                                // ml={{ base: '5%' }}
                              >
                                14 Al-Baradah St.,Amman,Jordan
                              </Text>
                            </Box>
                          </Box>

                          <Box
                            style={
                              {
                                // direction:
                                //   lang.currentLanguage === 'en' ? '' : 'rtl',
                                // marginRight:
                                //   lang.currentLanguage === 'en' ? '' : '10px',
                              }
                            }
                          >
                            <Box display="flex" mt={{ base: '2%' }}>
                              {/* <PhoneIcon w={4} h={4} color="#4FD1C5" mt={1} /> */}
                              {/* <Icon as={MdPhone} color="#4FD1C5" mt={"2px"} /> */}

                              <Text
                                color="#fff"
                                fontSize={isMobile ? '14px' : '15px'}
                                pt={'7.5px'}
                              >
                                {/* {t(`${translationPath}AccountManager`)} */}
                                Account Manager
                              </Text>
                              <Text
                                //  fontSize={"14px"}
                                pt={'7.5px'}
                                px={'5px'}
                                // fontSize={'14px'}
                                fontSize={isMobile ? '13.2px' : '14px'}
                                color="#4FD1C5"
                                // ml={'2'}
                              >
                                {newpdf && newpdf?.accountManager}
                              </Text>
                            </Box>

                            <Box display={'flex'} mt={{ base: '2%' }}>
                              <Text
                                // className={"sizeresponse"}
                                style={{
                                  fontFamily: 'Lato',
                                  fontSize: '15px',
                                }}
                                color={'#fff'}
                              >
                                Manual Intervention{' '}
                                {/* {t(`${translationPath}ManualIntervention`)} */}
                              </Text>
                              {newpdf?.manualIntervention === 'Detected' ? (
                                <Text
                                  // className={"sizeresponse"}
                                  // style={{ fontFamily: "Lato" }}
                                  px={'10px'}
                                  color={'#C50C43'}
                                  // ml={'2'}
                                  style={{
                                    fontFamily: 'Lato',
                                    // marginRight: lang.currentLanguage === "en" ? 0 : "10px",
                                  }}
                                >
                                  {' '}
                                  {/* {t(`${translationPath}Detected`)} */}
                                  Detected
                                </Text>
                              ) : (
                                <Text
                                  // className={"sizeresponse"}
                                  // style={{ fontFamily: "Lato" }}
                                  color={'#6AD475'}
                                  px={'10px'}
                                  // ml={'2'}
                                  style={{
                                    fontFamily: 'Lato',
                                    // marginRight: lang.currentLanguage === "en" ? 0 : "10px",
                                  }}
                                >
                                  {' '}
                                  {/* {t(`${translationPath}UnDetected`)} */}
                                  UnDetected
                                </Text>
                              )}
                            </Box>

                            {newpdf?.enableShorting === true ? (
                              <Box display={'flex'} mt={{ base: '2%' }}>
                                <Box display="flex">
                                  <Text
                                    style={{ fontFamily: 'Lato' }}
                                    color={'#fff'}
                                  >
                                    {/* {t(
                                      `${translationPath}ShortingFunctionality`
                                    )} */}
                                    Shorting Functionality
                                  </Text>

                                  <Text
                                    style={{
                                      fontFamily: 'Lato',
                                    }}
                                    px={'10px'}
                                    // style={{ fontFamily: "Lato" }}
                                    color={'#6AD475'}
                                    ml={'2'}
                                  >
                                    {' '}
                                    {/* {t(`${translationPath}Active`)} */}
                                    Active
                                  </Text>
                                </Box>
                              </Box>
                            ) : (
                              <Box display={'flex'} mt={{ base: '2%' }}>
                                <Box display="flex">
                                  <Text
                                    style={{ fontFamily: 'Lato' }}
                                    color={'#fff'}
                                  >
                                    {/* {t(
                                      `${translationPath}ShortingFunctionality`
                                    )} */}{' '}
                                    Shorting Functionality
                                  </Text>

                                  <Text
                                    style={{
                                      fontFamily: 'Lato',
                                    }}
                                    px={'10px'}
                                    color={'#C50C43'}
                                    ml={'2'}
                                  >
                                    {' '}
                                    {/* {t(`${translationPath}Inactive`)}  */}
                                    Inactive
                                  </Text>
                                </Box>
                              </Box>
                            )}

                            <Box justifyContent={'center'} mt={{ base: '2%' }}>
                              {newpdf?.plan === 'Free' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={
                                    {
                                      // direction:
                                      //   lang.currentLanguage === 'en'
                                      //     ? 'ltr'
                                      //     : 'rtl',
                                    }
                                  }
                                >
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{' '} */}
                                    Tier
                                  </Text>
                                  <Text color="#32c1e9" mx={'10px'}>
                                    Free{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Basic' ? (
                                <Box display={'flex'} align="center" style={{}}>
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{' '} */}
                                    Tier
                                  </Text>
                                  <Text color="#F7941D" mx={'10px'}>
                                    Basic{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Standard' ? (
                                <Box display={'flex'} align="center" style={{}}>
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{' '} */}
                                    Tier
                                  </Text>
                                  <Text color="#74B43D" mx={'10px'}>
                                    Standard{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Pro' ? (
                                <Box display={'flex'} align="center" style={{}}>
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{' '} */}
                                    Tier
                                  </Text>
                                  <Text color="#9940CD" mx={'10px'}>
                                    Pro{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Gold' ? (
                                <Box display={'flex'} align="center" style={{}}>
                                  <Text color="">
                                    {' '}
                                    {/* {t(`${translationPath}membershipTier`)}{' '} */}
                                    Tier
                                  </Text>
                                  <Text color="#D7B562" mx={'10px'}>
                                    Gold{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Platinum' ? (
                                <Box display={'flex'} align="center" style={{}}>
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{' '} */}
                                    Tier
                                  </Text>
                                  <Text color="#8D9EAA" mx={'10px'}>
                                    Platinum{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Black' ? (
                                <Box display={'flex'} align="center" style={{}}>
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{' '} */}
                                    Tier
                                  </Text>
                                  <Text color="#000" mx={'10px'}>
                                    Black{' '}
                                  </Text>
                                </Box>
                              ) : (
                                <Text color={'#4fd1c5'} mx={'10px'}></Text>
                              )}
                            </Box>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <div
          class="downloadhedin downLTR"
          // class="downLTR"

          id="legend"
          style={{ width: '1920px', height: '965px' }}
        >
          <div ref={refScreenAR}>
            <div>
              <Grid>
                <Box bg={'linear-gradient(to right , #192137 ,#000000)'}>
                  <Box
                    backgroundImage={
                      newpdf?.plan === 'Free'
                        ? BM
                        : newpdf?.plan === 'Basic'
                        ? OM
                        : newpdf?.plan === 'Standard'
                        ? GM
                        : newpdf?.plan === 'Pro'
                        ? PM
                        : newpdf?.plan === 'Gold'
                        ? YM
                        : newpdf?.plan === 'Platinum'
                        ? GRM
                        : newpdf?.plan === 'Black'
                        ? BLM
                        : '#070910'
                    }
                    // zIndex={-100}
                    backgroundPosition="left"
                    backgroundRepeat="no-repeat"
                    backgroundSize={isMaxWidthlarge ? 'cover' : 'cover'}
                  >
                    <Grid
                      zIndex={100}
                      ml={'10%'}
                      w={'90%'}
                      // alignItems='center'
                      // alignContent='center'
                      alignSelf="center"
                      // templateRows="repeat(2, 1fr)"
                      templateColumns="repeat(6, 1fr)"
                      // gap={2}
                    >
                      <GridItem rowSpan={2} colSpan={1} mr={10}>
                        <Image
                          objectFit="contain"
                          src={homeImg}
                          alt={'alt'}
                          backgroundSize="cover"
                          h="140px"
                          m="auto"
                          pt="5"
                          // width="100%"
                        />

                        <Center
                          justifyContent={'space-evenly'}
                          mt="2"
                          w={'220px'}
                          styles={{
                            flexDirection:
                              newpdf.language === 'en' ? '' : 'row-reverse',
                          }}
                          className={newpdf.language === 'en' ? '' : 'rowrevv'}
                        >
                          <Text
                            style={{ fontFamily: 'Lato' }}
                            color="white"
                            fontSize={{
                              base: 'sm',
                              md: '16px',
                              xl: '18px',
                              xxl: '20px',
                            }}
                            // mt="1"
                          >
                            تاريخ إبتداء التداول
                          </Text>
                          <Text
                            // style={{ fontFamily: "Lato" }}ge
                            color="#4FD1C5"
                            // fontWeight="bold"
                            fontSize={{ base: 'sm', md: '16px', xl: '16px' }}
                            // ml={1}
                            // mt="1.5"
                          >
                            {newpdf && newpdf?.exDate
                              ? moment(newpdf && newpdf?.exDate)
                                  .subtract(1, 'years')
                                  .format('DD/MM/YYYY')
                              : '-'}
                          </Text>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '18px',
                                  xl: '20px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  fontFamily: 'Lato',
                                }}
                              >
                                {/* {t(`${translationPath}InitialBalance`)} */}
                                الرصيد الافتتاحي
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  // fontFamily: "Lato",
                                }}
                                fontweight={'bold'}
                                my={2}
                              >
                                {newpdf && newpdf?.openingBalance
                                  ? newpdf?.openingBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '18px',
                                  xl: '20px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  fontFamily: 'Lato',
                                }}
                              >
                                الرصيد الإبتدائي
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  fontFamily: 'Lato',
                                }}
                                fontweight={'bold'}
                                my={2}
                              >
                                {newpdf && newpdf?.initBalance
                                  ? newpdf?.initBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                // // style={{ fontFamily: "Lato" }}ge
                                color="#fff"
                                // fontWeight="bold"
                                fontSize={{
                                  base: 'sm',
                                  md: '18px',
                                  xl: '20px',
                                  fontFamily: 'Lato',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                الرصيد الفعلي
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  fontFamily: 'Lato',
                                }}
                                my={2}
                              >
                                {newpdf && newpdf?.baseBalance
                                  ? newpdf?.baseBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                            // ml={{ base: "1%" }}
                          >
                            <Center>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                // fontWeight="bold"
                                // fontSize={{ base: 'sm' }}
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                              >
                                العائد الشهري
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                // fontWeight="bold"
                                // fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  fontFamily: 'Lato',
                                }}
                                my={2}
                              >
                                {newpdf && newpdf?.monthlyReturn
                                  ? newpdf?.monthlyReturn + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                              >
                                الرصيد الحالي
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  fontFamily: 'Lato',
                                }}
                                my={2}
                              >
                                {newpdf && newpdf?.closingBalance
                                  ? newpdf?.closingBalance + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                        <Center w={{ base: '100%', xl: '100%' }}>
                          <Box
                            h="auto"
                            w={{ base: '100%', lg: '100%' }}
                            textAlign={{
                              base: 'center',
                              lg: newpdf.language === 'en' ? 'left' : 'right',
                            }}
                            // my={2}
                            m={{ base: '10%' }}
                          >
                            <Center>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                              >
                                عدد الصفقات الاجمالي
                              </Text>
                            </Center>

                            <Center>
                              <Text
                                color="#4FD1C5"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  fontFamily: 'Lato',
                                }}
                                my={2}
                              >
                                {newpdf && newpdf?.totalTradesExecuted
                                  ? newpdf?.totalTradesExecuted
                                  : 0 + ' '}
                              </Text>
                            </Center>
                          </Box>
                        </Center>
                      </GridItem>

                      <GridItem rowSpan={2} colSpan={3} mt={5}>
                        <Center display="flex" style={{ base: 'center' }}>
                          <Text
                            style={{ fontFamily: 'Lato' }}
                            fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                            color="white"
                            fontWeight="bold"
                            justifyContent={'center'}
                            // my={6}
                          >
                            {/* {t(`${translationPath}Asked`)}  */}
                            {newpdf && newpdf.firstName
                              ? newpdf.firstName
                              : ' '}
                          </Text>
                          <Text
                            style={{ fontFamily: 'Lato' }}
                            // color="filver"
                            // color="rgb(172 72 231)"
                            color="#4FD1C5"
                            fontWeight="bold"
                            fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
                            // justifyContent={'center'}
                            // my={6}
                            ml={{ base: '1%' }}
                          >
                            {/* {t(`${translationPath}Asked`)}  */}
                            {newpdf && newpdf.lastName ? newpdf.lastName : ' '}
                          </Text>
                        </Center>

                        <Center
                          display="flex"
                          textAlign={{
                            base: 'center',
                            md: newpdf.language === 'en' ? 'left' : 'right',
                          }}
                          style={{ direction: 'rtl' }}
                        >
                          <Text
                            style={{ fontFamily: 'Lato', paddingLeft: '10px' }}
                            // width={{ base: "55%" }}
                            fontSize={{ base: 'l', md: 'xl', xl: 'xl' }}
                            color="white"
                            // fontWeight="bold"
                            justifyContent={'center'}
                            // letterSpacing={2}
                            // alignSelf={"center"}
                            // my={6}   below the results of Month-Year
                            className={
                              newpdf.language === 'en' ? '' : 'padscan'
                            }
                          >
                            {/* {t(`${translationPath}Asked`)}  */}
                            {/* <!-- {t(`${translationPath}Theseareyourperformance`)} --> */}
                            ابرز مقاييس أداء شهر
                          </Text>
                          <Text
                            color="#52d3cb"
                            fontSize={{ base: 'l', md: 'xl', xl: '2xl' }}
                            ml={2}
                          >
                            {' '}
                            {newpdf?.date}
                          </Text>
                        </Center>
                        <Center
                          display="flex"
                          textAlign={{
                            base: 'center',
                            md: newpdf.language === 'en' ? 'left' : 'right',
                          }}
                        ></Center>
                        <Center>
                          <Divider orientation="horizontal" h={5} w={'80%'} />
                        </Center>
                        <SimpleGrid columns={2} spacing={10} mt={10}>
                          {/* <Box bg="tomato" height="80px"> */}
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                نسبة الأرباح المحققة الشهريه
                              </Text>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* 60% */}
                                {newpdf && newpdf?.monthlyPercentageReturn
                                  ? newpdf?.monthlyPercentageReturn + ' %'
                                  : 0 + ' %'}
                              </Text>
                            </Box>
                          </Center>

                          {/* </Box> */}
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {/* <!-- {t(`${translationPath}AllTimeROI`)} --> */}
                                إجمالي نسبة عائد الإستثمار
                              </Text>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* 88% */}
                                {newpdf && newpdf?.alltimeROI
                                  ? newpdf?.alltimeROI + ' %'
                                  : 0 + ' %'}
                              </Text>
                            </Box>
                          </Center>
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {/* <!-- {t(`${translationPath}TotalDeposits`)} --> */}
                                مجموع الإيداعات
                              </Text>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* {location?.state?.data?.totalDeposit + " $US"} */}

                                {newpdf && newpdf?.totalDeposits
                                  ? newpdf?.totalDeposits + ' $US'
                                  : 0 + ' US'}
                              </Text>
                            </Box>
                          </Center>
                          <Center h="60px" color="white">
                            <Box>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                // align="center"

                                fontSize={{
                                  base: 'sm',
                                  md: '20px',
                                  xl: '22px',
                                }}
                                // ml={{ base: "1%" }}
                              >
                                {/* <!-- {t(`${translationPath}TotalWithdrawals`)} --> */}
                                مجموع السحوبات
                              </Text>
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#5FD5CA"
                                align="center"
                                fontSize={{
                                  base: 'sm',
                                  md: '16px',
                                  xl: '18px',
                                }}
                                ml={{ base: '1%' }}
                              >
                                {/* {location?.state?.data?.totalWithdrawal + " $US"} */}
                                {newpdf && newpdf.totalWithdrawals
                                  ? newpdf.totalWithdrawals + ' $US'
                                  : 0 + ' $US'}
                              </Text>
                            </Box>
                          </Center>
                        </SimpleGrid>
                        <SimpleGrid columns={3} spacing={10} spacingY="20px">
                          <GridItem>
                            <Box className="fuckpiecharttest">
                              <Text fontSize={'40px'} color="#FFFFFF">
                                %
                              </Text>
                            </Box>
                            <Box>
                              <PieChart
                                ref={chartRef}
                                data={[
                                  {
                                    title: 'USDT',
                                    // value: balance.PercentageOfUSDT,
                                    value: newpdf?.percentageOfUSDT,

                                    color: '#FF8061',
                                  },
                                  {
                                    title: 'Long',
                                    // value: balance.percentageLongOfTotalAccount,
                                    value: newpdf?.percentageLongOfTotalAccount,
                                    color: '#F9ED78',
                                  },
                                  {
                                    title: 'Short',
                                    // value: balance.percentageShortOfTotalAccount,
                                    value:
                                      newpdf?.percentageShortOfTotalAccount,

                                    color: '#4A3786',
                                  },
                                ]}
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            </Box>

                            <Center>
                              <Box display="flex">
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box ml={1} mr={2} mb={1}> */}

                                  <Image
                                    objectFit="contain"
                                    src={orangeicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    mt={'12px'}
                                  />

                                  {/* </Box> */}
                                  <Text
                                    style={{ fontSize: '14px' }}
                                    // fontsize={'12px'}
                                    color="#fff"
                                    // ClassName="fontinchartdounload"
                                  >
                                    {t(`${translationPath}USDT`)}
                                  </Text>
                                  <Text
                                    // style={{  }}
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                    ml={2}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {' '}
                                    {newpdf?.percentageOfUSDT}
                                  </Text>
                                </Box>
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box ml={1} mr={2} mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={purpicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  {/* </Box> */}

                                  <Text
                                    // // style={{ fontFamily: "Lato" }}gege
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {' '}
                                    {/* <!-- {t(`${translationPath}Short`)} --> */}
                                    شورت
                                  </Text>
                                  <Text
                                    // // style={{ fontFamily: "Lato" }}gege
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                    ml={2}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {newpdf?.percentageLongOfTotalAccount}
                                  </Text>
                                </Box>

                                {/* </Center> */}
                              </Box>
                            </Center>
                            <Center>
                              <Box display="flex" ml={1} mr={2} mt={3}>
                                {/* <Box  mb={1}> */}
                                <Image
                                  objectFit="contain"
                                  src={yellowicon}
                                  alt={'alt'}
                                  backgroundSize="cover"
                                  // h="20"
                                  w={'10px'}
                                  m="auto"
                                  mr={'5px'}
                                  ml={'5px'}
                                  mt={'12px'}
                                />
                                {/* </Box> */}
                                <Text
                                  // // style={{ fontFamily: "Lato" }}gege
                                  style={{
                                    fontSize: '14px',
                                    fontFamily: 'Lato',
                                  }}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                                  {' '}
                                  {/* <!-- {t(`${translationPath}Long2`)} --> */}
                                  BTC
                                </Text>
                                <Text
                                  // // style={{ fontFamily: "Lato" }}gege
                                  style={{
                                    fontSize: '14px',
                                    fontFamily: 'Lato',
                                  }}
                                  ml={2}
                                  color="#fff"
                                  fontsize={'12px'}
                                >
                                  {newpdf?.percentageShortOfTotalAccount}
                                </Text>
                              </Box>
                            </Center>
                          </GridItem>
                          <GridItem>
                            <Box>
                              <Box className="fuckpiecharttest">
                                <Text fontSize={'40px'} color="#FFFFFF">
                                  %
                                </Text>
                              </Box>
                              <PieChart
                                ref={chartRef}
                                data={[
                                  {
                                    title: 'percentageOfCanceledOrders',
                                    value: newpdf?.percentageOfCanceledOrders,

                                    color: '#675196',
                                  },
                                  {
                                    title: 'percentageOfFilledTrades',
                                    value: newpdf?.percentageOfFilledTrades,
                                    color: '#74BEC9',
                                  },
                                  // { title: "Three", value: 20, color: "#675196" },
                                ]}
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            </Box>

                            <Center>
                              <Box display="flex">
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={greenicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                    color="#fff"
                                    fontsize={'12px'}
                                  >
                                    {/* <!-- {t(`${translationPath}Canceled2`)} --> */}
                                    ملغاة
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                    ml={2}
                                    color="#fff"
                                  >
                                    {' '}
                                    {newpdf?.percentageOfFilledTrades}
                                  </Text>
                                </Box>
                                <Box display="flex" ml={1} mr={2}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={purpicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />

                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                    color="#fff"
                                  >
                                    {' '}
                                    منفذ
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                    ml={2}
                                    color="#fff"
                                  >
                                    {newpdf?.percentageOfCanceledOrders}
                                  </Text>
                                </Box>
                              </Box>
                            </Center>
                          </GridItem>
                          <GridItem>
                            <Box className="fuckpiecharttest">
                              <Text fontSize={'40px'} color="#FFFFFF">
                                %
                              </Text>
                            </Box>
                            <Box>
                              {/* <Text>%</Text> */}
                              <PieChart
                                ref={chartRef}
                                data={[
                                  {
                                    title: 'TP',
                                    // value: balance.percentageLongOfTotalAccount,
                                    value: newpdf?.tradeSuccessRate,

                                    color: '#F9ED78',
                                  },
                                  {
                                    title: 'SL',
                                    // value: balance.percentageShortOfTotalAccount,
                                    value: newpdf?.tradeFailureRate,

                                    color: '#FF8061',
                                  },
                                ]}
                                labelPosition={70}
                                lineWidth={12}
                                paddingAngle={15}
                                rounded={5}
                                radius={30}
                              />
                            </Box>

                            <Center>
                              <Box>
                                <Box display="flex" ml={1}>
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={orangeicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mt={'12px'}
                                  />
                                  <Text
                                    style={{
                                      fontSize: '14px',
                                      paddingLeft: '5px',
                                      paddingRight: '5px',
                                    }}
                                    color="#fff"
                                    // fontSize={'14px'}
                                  >
                                    وقف خسارة
                                  </Text>
                                  <Text
                                    color="#fff"
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                  >
                                    {' '}
                                    {newpdf?.tradeFailureRate}
                                  </Text>
                                </Box>

                                <Box
                                  display="flex"
                                  ml={1}
                                  mr={2}
                                  mt={3}
                                  justifyContent="center"
                                >
                                  {/* <Box  mb={1}> */}
                                  <Image
                                    objectFit="contain"
                                    src={yellowicon}
                                    alt={'alt'}
                                    backgroundSize="cover"
                                    // h="20"
                                    w={'10px'}
                                    m="auto"
                                    mr={'5px'}
                                    ml={'5px'}
                                    mt={'12px'}
                                  />
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    color="#fff"
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                  >
                                    {' '}
                                    اخذ مربح
                                  </Text>
                                  <Text
                                    // style={{ fontFamily: "Lato" }}ge
                                    ml={2}
                                    color="#fff"
                                    style={{
                                      fontSize: '14px',
                                      fontFamily: 'Lato',
                                    }}
                                  >
                                    {newpdf?.tradeSuccessRate}
                                  </Text>
                                </Box>
                                {/* </Center> */}
                              </Box>
                            </Center>
                          </GridItem>
                        </SimpleGrid>
                        <Box mt={20}></Box>

                        <GridItem>
                          <Box ml={8}>
                            <Box
                              // w={"40%"}
                              display={'flex'}
                              zIndex={99999}
                              position="absolute"
                              // bgcolor="red"
                            >
                              <Center
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignSelf={'center'}
                                alignContent={'center'}
                                alignItems={'center'}
                              >
                                <Text></Text>
                                <Text
                                  className="SpotWalletprogress"
                                  color={'#fff'}
                                  fontSize={'16px'}
                                >
                                  المحفظة الفورية
                                </Text>
                                <Text
                                  color={'#fff'}
                                  className="SpotWalletprogress"
                                  fontSize={'16px'}
                                  // mb={8}
                                >
                                  {' '}
                                  {newpdf?.fundsInSpot && newpdf?.fundsInSpot
                                    ? newpdf?.fundsInSpot + '%'
                                    : 0 + '%'}
                                </Text>
                              </Center>

                              <Center
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignSelf={'center'}
                                alignContent={'center'}
                                alignItems={'center'}
                                // w="40%"
                                h="10"
                              >
                                <Text // style={{ fontFamily: "Lato" }}ge
                                ></Text>
                                <Text
                                  color={'#fff'}
                                  // ml={"2"}
                                  className="SpotWalletprogress"
                                  fontSize={'16px'}
                                  // mb={10}
                                >
                                  محفظة العقود الآجلة
                                </Text>
                                <Text
                                  color={'#fff'}
                                  className="SpotWalletprogress"
                                  fontSize={'16px'}
                                >
                                  {newpdf?.fundsinDerivatives &&
                                  newpdf?.fundsinDerivatives
                                    ? newpdf?.fundsinDerivatives + '%'
                                    : 0 + '%'}
                                </Text>
                              </Center>
                            </Box>
                            {newpdf?.fundsInSpot === 100 ? (
                              <div id="progressbarSpot" style={{}}>
                                <div className="progressbarinsideSpot"></div>
                              </div>
                            ) : newpdf?.fundsinDerivatives === 100 ? (
                              <div id="progressbarDeriv" style={{}}>
                                <div className="progressbarinsideDeriv"></div>
                              </div>
                            ) : (
                              <div id="progressbar" style={{ height: '35px' }}>
                                <div
                                  className="progressbarinside"
                                  style={{
                                    width: newpdf?.fundsInSpot * 8.3,
                                    height: '35px',
                                  }}
                                ></div>
                              </div>
                            )}
                          </Box>
                        </GridItem>
                        <Box
                          w={'100%'}
                          mt={{ base: '5%' }}
                          display="grid"
                          justifyContent={'center'}
                        >
                          <Text
                            style={{ fontFamily: 'Lato' }}
                            // ml={{ base: "15%" }}
                            mt={5}
                            mb={5}
                            color="#4FD1C5"
                            w={{ base: '100%' }}
                            // fontWeight="bold"
                            fontSize={'10px'}
                          >
                            تم انشاء هذا التقرير تلقائياً لغايات تزويدك بنبذة
                            خاصة حول اداء حسابك
                          </Text>
                          {/* </Center> */}
                        </Box>
                      </GridItem>

                      <GridItem rowSpan={2} colSpan={2} ml={10}>
                        <Center pt={{ base: '5%' }}></Center>

                        <Center
                          w="100%"
                          textAlign={{
                            base: 'center',
                            lg: newpdf.language === 'en' ? 'left' : 'right',
                          }}
                          m="auto"
                          className={newpdf.language === 'en' ? '' : 'rowrevv'}
                        ></Center>

                        <Image
                          objectFit="contain"
                          src={qrcodefinal}
                          alt={'alt'}
                          backgroundSize="cover"
                          h="120"
                          m="auto"
                          // pt="5"
                          // width="100%"
                          pt={{ base: '5%' }}
                        />
                        <Box
                          display="flex"
                          mt="5"
                          justifyContent="center"
                          className={newpdf.language === 'en' ? '' : 'rowrevv'}
                        ></Box>

                        <Center mt={2}>
                          <Box w={'50%'}>
                            <Text
                              style={{ fontFamily: 'Lato' }}
                              color="#fff"
                              // fontWeight="bold"
                              fontSize={{ base: 'sm', md: 'md', xl: 'xl' }}
                            >
                              {' '}
                              إنحيازية التداول السائدة
                            </Text>
                            <Text
                              style={{ fontFamily: 'Lato' }}
                              color="#4FD1C5"
                              ml={'50px'}
                              fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                            >
                              {newpdf?.bias}
                            </Text>
                          </Box>

                          <Box>
                            <Text
                              style={{ fontFamily: 'Lato' }}
                              fontSize={{ base: 'sm', md: 'md', xl: 'xl' }}
                              color="#fff"
                            >
                              {' '}
                              وضعية التداول
                            </Text>
                            <Text
                              style={{ fontFamily: 'Lato' }}
                              color="#4FD1C5"
                              fontSize={{ base: 'sm', md: 'md', xl: 'l' }}
                              ml={15}
                            >
                              {' '}
                              {newpdf?.mode}
                            </Text>
                          </Box>
                        </Center>

                        {newpdf?.bias === 'Bearish' ? (
                          <Box>
                            <Image
                              objectFit="contain"
                              src={berr}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h={100}
                              w={350}
                              m="auto"
                              // mt={"2px"}
                            />
                          </Box>
                        ) : newpdf?.bias === 'Bullish' ? (
                          <Box>
                            <Image
                              objectFit="contain"
                              src={bull}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h={100}
                              w={350}
                              m="auto"
                              // mt={"2px"}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <Image
                              objectFit="contain"
                              src={bullberr}
                              alt={'alt'}
                              backgroundSize="cover"
                              // h={100}
                              w={350}
                              m="auto"
                              // mt={"2px"}
                            />
                          </Box>
                        )}

                        {/* mode?.settings?.bias === 'Bearish' */}
                        {/*  */}

                        <SimpleGrid columns={2} spacing={10} mt={5}>
                          <Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              <Image
                                objectFit="contain"
                                src={phoneicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                w={5}
                                m="auto"
                                // mt={"2px"}
                                pt={'10px'}
                              />
                              <Text
                                color="#fff"
                                ml={{ base: '1%' }}
                                // mb={{ base: "5px" }}
                                w={{ base: '100%' }}
                                // fontWeight="bold"
                                fontSize={'14px'}
                                pt={'7.5px'}
                              >
                                +962 79571 8888
                              </Text>
                            </Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              <Image
                                objectFit="contain"
                                src={emailicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                w={4}
                                m="auto"
                                // mt={"2px"}
                                pt={'10px'}
                              />

                              <Text
                                style={{ fontFamily: 'Lato' }}
                                color="#fff"
                                ml={{ base: '3%' }}
                                mb={{ base: '5px' }}
                                w={{ base: '100%' }}
                                // fontWeight="bold"
                                fontSize={'14px'}
                              >
                                support@aymbot.com
                              </Text>
                            </Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              <Image
                                objectFit="contain"
                                src={siteicon}
                                alt={'alt'}
                                backgroundSize="cover"
                                w={4}
                                m="auto"
                                pt={'10px'}
                              />

                              <Text
                                ml={{ base: '3%' }}
                                color="#fff"
                                w={{ base: '100%' }}
                                fontSize={'14px'}
                              >
                                www.aymbot.com
                              </Text>
                            </Box>
                            <Box display="flex" mt={{ base: '2%' }}>
                              <Image
                                objectFit="contain"
                                src={locationicon1}
                                alt={'alt'}
                                backgroundSize="cover"
                                // h="20"
                                w={4}
                                m="auto"
                                // mt={"2px"}
                                pt={'12px'}
                              />
                              <Text
                                style={{ fontFamily: 'Lato' }}
                                ml={{ base: '3%' }}
                                color="#fff"
                                w={{ base: '100%' }}
                                // fontWeight="bold"
                                fontSize={'12px'}
                                pt={'7.5px'}
                              >
                                14 Al-Baradah St.,Amman,Jordan
                              </Text>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              direction: 'rtl',
                              marginRight: '10px',
                            }}
                          >
                            <Box display="flex" mt={{ base: '2%' }}>
                              <Text
                                color="#fff"
                                // fontSize={'14px'}
                                fontSize={isMobile ? '13px' : '14px'}
                                fontWeight="bold"
                                // fontSize={"14px"}
                                pt={'7.5px'}
                              >
                                مسؤول الحساب
                              </Text>
                              <Text
                                fontSize={isMobile ? '13px' : '14px'}
                                pt={'7.5px'}
                                px={isMobile ? '5px' : '10px'}
                                color="#4FD1C5"
                                // ml={'2'}
                              >
                                {newpdf && newpdf.accountManager}
                              </Text>
                            </Box>

                            <Box display={'flex'} mt={{ base: '2%' }}>
                              <Text
                                // className={"sizeresponse"}
                                className={'taaymbot'}
                                fontSize={isMobile ? '12.5px' : ''}
                                style={{ fontFamily: 'Lato' }}
                                color={'#fff'}
                              >
                                {/* Manual Intervention:{" "} */}
                                {/* {t(`${translationPath}ManualIntervention`)} */}
                                تأثير على صفقات AYMBot®
                              </Text>
                              {newpdf?.manualIntervention === 'Detected' ? (
                                <Text
                                  // className={"sizeresponse"}
                                  // style={{ fontFamily: "Lato" }}
                                  fontSize={isMobile ? '13px' : ''}
                                  px={isMobile ? '3px' : '10px'}
                                  className={'taaymbot'}
                                  color={'#C50C43'}
                                  ml={'2'}
                                  style={{
                                    fontFamily: 'Lato',
                                    // marginRight: lang.currentLanguage === "en" ? 0 : "10px",
                                  }}
                                >
                                  {' '}
                                  {/* {t(`${translationPath}Detected`)} */}
                                  مرصود
                                </Text>
                              ) : (
                                <Text
                                  // className={"sizeresponse"}
                                  // style={{ fontFamily: "Lato" }}
                                  className={'taaymbot'}
                                  fontSize={isMobile ? '13px' : ''}
                                  color={'#6AD475'}
                                  px={isMobile ? '3px' : '10px'}
                                  ml={'2'}
                                  style={{
                                    fontFamily: 'Lato',
                                    // marginRight: lang.currentLanguage === "en" ? 0 : "10px",
                                  }}
                                >
                                  {' '}
                                  {/* {t(`${translationPath}UnDetected`)} */}
                                  لم يرصد
                                </Text>
                              )}
                            </Box>

                            {newpdf?.enableShorting === true ? (
                              <Box display={'flex'} mt={{ base: '2%' }}>
                                <Box display="flex">
                                  <Text
                                    style={{ fontFamily: 'Lato' }}
                                    color={'#fff'}
                                  >
                                    {/* {t(
                                        `${translationPath}ShortingFunctionality`
                                      )} */}
                                    خاصية الشورت
                                  </Text>

                                  <Text
                                    style={{
                                      fontFamily: 'Lato',
                                    }}
                                    px={'10px'}
                                    // style={{ fontFamily: "Lato" }}
                                    color={'#6AD475'}
                                    ml={'2'}
                                  >
                                    {' '}
                                    {/* {t(`${translationPath}Active`)} */}
                                    فعال
                                  </Text>
                                </Box>
                              </Box>
                            ) : (
                              <Box display={'flex'} mt={{ base: '2%' }}>
                                <Box display="flex">
                                  <Text
                                    style={{ fontFamily: 'Lato' }}
                                    color={'#fff'}
                                  >
                                    خاصية الشورت
                                  </Text>

                                  <Text
                                    style={{
                                      fontFamily: 'Lato',
                                    }}
                                    px={'10px'}
                                    color={'#C50C43'}
                                    ml={'2'}
                                  >
                                    {' '}
                                    {/* {t(`${translationPath}Inactive`)} */}
                                    غير فعال
                                  </Text>
                                </Box>
                              </Box>
                            )}

                            <Box justifyContent={'center'} mt={{ base: '2%' }}>
                              {newpdf?.plan === 'Free' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction: 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{" "} */}
                                    الشريحة
                                  </Text>
                                  <Text color="#32c1e9" mx={'10px'}>
                                    Free{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Basic' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction: 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{" "} */}
                                    الشريحة
                                  </Text>
                                  <Text color="#F7941D" mx={'10px'}>
                                    Basic{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Standard' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction: 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{" "} */}
                                    الشريحة
                                  </Text>
                                  <Text color="#74B43D" mx={'10px'}>
                                    Standard{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Pro' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction: 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{" "} */}
                                    الشريحة
                                  </Text>
                                  <Text color="#9940CD" mx={'10px'}>
                                    Pro{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Gold' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction: 'rtl',
                                  }}
                                >
                                  <Text color=""> الشريحة</Text>
                                  <Text color="#D7B562" mx={'10px'}>
                                    Gold{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Platinum' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction: 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{" "} */}
                                    الشريحة
                                  </Text>
                                  <Text color="#8D9EAA" mx={'10px'}>
                                    Platinum{' '}
                                  </Text>
                                </Box>
                              ) : newpdf?.plan === 'Black' ? (
                                <Box
                                  display={'flex'}
                                  align="center"
                                  style={{
                                    direction: 'rtl',
                                  }}
                                >
                                  <Text color="">
                                    {/* {t(`${translationPath}membershipTier`)}{" "} */}
                                    الشريحة
                                  </Text>
                                  <Text color="#000" mx={'10px'}>
                                    Black{' '}
                                  </Text>
                                </Box>
                              ) : (
                                <Text color={'#4fd1c5'} mx={'10px'}></Text>
                              )}
                            </Box>
                          </Box>
                        </SimpleGrid>
                      </GridItem>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </Flex>
  );
};
