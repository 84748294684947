import React from 'react';
import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Model from '../../Components/Model/HomeModel';
const parentTranslationPath = 'Pricing';
const translationPath = '';

export const PriceCard = ({
  start,
  planName,
  deposit,
  price,
  option1,
  option2,
  option3,
  option4,
  option5,
  color,
  comingSoon,
  pic,
  VIP,
  most,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(parentTranslationPath);
  let token = localStorage.getItem('token');
  const handleOpen = () => {
    if (!token || token === 'null') {
      onOpen();
    } else {
      window.location.href = '/admin/profile';
    }
  };
  return (
    <div>
      <Model isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <Box m="auto" borderRadius="22px" w="249px" h="470px" bgColor="white" >
        {most ? (
          <Box
            borderTopRadiusRadius="20px"
            h="30px"
            w="100%"
            bgColor="#4FD1C5"
            display="flex"
            borderTopRadius="20px"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="12px" color="white">
              {' '}
              {t(`${translationPath}${most}`)}
            </Text>
          </Box>
        ) : null}
        <Box
          borderTopRadius={most ? '0' : '20px'}
          h="50px"
          w="100%"
          bgImage={pic ? pic : null}
          bgColor={color}
          bgSize='cover'
          bgPosition='center'
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {' '}
          <Text color="white"> {t(`${translationPath}${planName}`)}</Text>
        </Box>
        <Box
          h="30px"
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {' '}
          <Text
            fontWeight="bold"
            display="flex"
            color="#27314C"
            fontSize="sm"
          >
            {' '}
            {t(`${translationPath}${start}`)}
          </Text>
        </Box>
        <Box
          h="40px"
          w="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {' '}
          <Text display="flex" fontWeight="bold" fontStyle='italic' color="#27314C" fontSize="xl">
            {' '}
            {'$' + t(`${translationPath}${deposit}`)}
          </Text>
        </Box>
        <Box
          w="fit-content"
          textAlign="center"
          padding="4px"
          h="25px"
          m="auto"
          bgColor="#4FD1C5"
          borderRadius="10px"
          color="white"
          fontWeight="bold"
          fontSize="12px"
        >
          {' '}
          {t(`${translationPath}${price}`)}{' '}
        </Box>
        <Box m="2">
          {' '}
          <Box
            minH="35px"
            h="auto"
            w="100%"
            mt="1"
            bgColor="#E8E8E8"
            borderTopLeftRadius="10px"
            borderTopRightRadius="10px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {' '}
            <Text
              className="card-text"
              my="1"
              w="90%"
              textAlign="center"
              fontWeight="bold"
              fontSize="13px"
              color="#676767"
            >
              {' '}
              {t(`${translationPath}${option1}`)}{' '}
            </Text>
          </Box>
          <Box
            minH="35px"
            mt="1"
            h="auto"
            w="100%"
            bgColor="#F2F2F2"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {' '}
            <Text
              className="card-text"
              w="80%"
              my="1"
              textAlign="center"
              fontSize="13px"
              color="#676767"
            >
              {' '}
              {t(`${translationPath}${option2}`)}{' '}
            </Text>
          </Box>
          <Box
            minH="35px"
            mt="1"
            h="auto"
            w="100%"
            bgColor="#E8E8E8"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {' '}
            <Text
              className="card-text"
              my="1"
              w="80%"
              textAlign="center"
              fontSize="13px"
              color="#676767"
            >
              {t(`${translationPath}${option3}`)}
            </Text>
          </Box>
          <Box
            minH="35px"
            mt="1"
            h="auto"
            w="100%"
            bgColor="#F2F2F2"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {' '}
            <Text
              className="card-text"
              display="flex"
              my="1"
              w="90%"
              justifyContent="center"
              fontSize="13px"
              color="#676767"
            >
              {' '}
              {t(`${translationPath}${option4}`)}{' '}
              <Text mx="1" fontStyle="italic" color="#4FD1C5">
                {' '}
                {comingSoon ?<span className="coming">{t(`${translationPath}${comingSoon}`)}</span>  : ''}
              </Text>
            </Text>
          </Box>
          <Box
            minH="35px"
            mt="1"
            h="auto"
            w="100%"
            bgColor="#E8E8E8"
            borderBottomLeftRadius="10px"
            borderBottomRightRadius="10px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {' '}
            <Text
              className="card-text"
              my="1"
              w="90%"
              textAlign="center"
              fontSize="13px"
              color="#676767"
            >
              {' '}
              {t(`${translationPath}${option5}`)}
            </Text>
          </Box>
          <Button
            my="2"
            borderRadius="10px"
            position="static"
            whiteSpace="normal"
            _hover="none"
            fontSize={{ base: '12px', lg: '15px' }}
            bg={'#151C2F'}
            color="white"
            size="md"
            w="100%"
            h="35px"
            _active={{
              bg: '#151C2F',
              transform: 'scale(0.98)',
              borderColor: 'none',
            }}
            _focus={{
              boxShadow: 'none',
            }}
            onClick={handleOpen}
          >
            {t(`${translationPath}Sign-Up`)}
          </Button>
          <Box m="auto">
            <Text
              textAlign="center"
              color={VIP === 'COMING SOON' ? '#4FD1C5' : 'black'}
              fontSize={VIP === 'COMING SOON' ? '12px' : '9px'}
            >
              {t(`${translationPath}${VIP}`)}
            </Text>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
